import { TextValuePair } from '../services/entities/common/key-value';
import { FileExporterOptions } from '../services/entities/file-exporter-options';
import { ExportOptions, ExportTitles } from './grid.constants';

export enum FileExporterType {
  backlog = 'backlog',
  actualsSales = 'grid',
  wins = 'grid',
  opportunities = 'grid',
  speculative = 'spec',
  speculativeTemplate = 'template',
  analytics = 'analytics',
  table = 'table',
}

export enum FileExporterStatus {
  IN_PROGRESS = 'in progress',
  DONE = 'done',
  ERROR = 'error',
}

export const FileExporterFileNames = {
  OpportunitySgWmu: 'Opportunities Export (SG - WMU Breakdowns).xlsx',
  OpportunityWmu: 'Opportunities Export (WMU Breakdown).xlsx',
  OpportunitySg: 'Opportunities Export (SG Breakdown).xlsx',
  Opportunity: 'Opportunities Export (Standard Export).xlsx',
  BacklogSgWmu: 'Backlog Export (SG - WMU Breakdowns).xlsx',
  BacklogWmu: 'Backlog Export (WMU Breakdown).xlsx',
  BacklogSg: 'Backlog Export (SG Breakdown).xlsx',
  Backlog: 'Backlog Export (Standard Export).xlsx',
  Analytics: 'Analytics Export (Standard Export).xlsx',
  SpeculativeTemplate: 'Upload Template.xlsx',
  SpeculativeSgWmu: 'Speculatives Export (SG - WMU Breakdowns).xlsx',
  SpeculativeWmu: 'Speculatives Export (WMU Breakdown).xlsx',
  SpeculativeSg: 'Speculatives Export (SG Breakdown).xlsx',
  Speculative: 'Speculatives Export (Standard Export).xlsx',
  Trend: 'Trend Export.xlsx',
  SpeculativePU: 'Speculatives Export (Standard Export with Profit Uplift)',
};

export const commonExportOptions: Array<FileExporterOptions> = [
  {
    title: '',
    options: [
      new TextValuePair({
        text: ExportOptions.Standard,
        value: {
          isSgBreakdown: false,
          isWmuBreakdown: false,
        },
      }),
    ],
  },
  {
    title: ExportTitles.Detailed,
    options: [
      new TextValuePair({
        text: ExportOptions.SG,
        value: {
          isSgBreakdown: true,
          isWmuBreakdown: false,
        },
      }),
      new TextValuePair({
        text: ExportOptions.WMU,
        value: {
          isSgBreakdown: false,
          isWmuBreakdown: true,
        },
      }),
      new TextValuePair({
        text: ExportOptions.SGWMU,
        value: {
          isSgBreakdown: true,
          isWmuBreakdown: true,
        },
      }),
    ],
  },
];

export const profitUpliftExportOptions = [
  {
    title: ExportTitles.ProfitUplift,
    options: [
      new TextValuePair({
        text: ExportOptions.ProfitUplift,
        value: {
          isSgBreakdown: false,
          isWmuBreakdown: false,
        },
      }),
    ],
  },
];
