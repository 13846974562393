import { assign } from 'lodash';

import { OptionsListConfig } from 'src/app/shared/components/base/options-list/entities/options-list-config';
import { TextValuePair } from 'src/app/shared/services/entities/common/key-value';

class FavoriteModalFiltersConfigSchema {
  metricListConfig: OptionsListConfig;
  periods: Array<TextValuePair>;
  groups1: Array<TextValuePair>;
  groups2: Array<TextValuePair>;
  groups3: Array<TextValuePair>;
  groups4: Array<TextValuePair>;
  datasets1: Array<TextValuePair>;
  datasets2: Array<TextValuePair>;
}

export class FavoriteModalFiltersConfig extends FavoriteModalFiltersConfigSchema {
  constructor(filterAttributes?: FavoriteModalFiltersConfigSchema) {
    super();
    assign(this, filterAttributes);
  }
}
