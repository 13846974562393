import { assign } from 'lodash';
import { ValueType } from 'src/app/shared/constants/common.constants';

class ProfitUpliftTableSchema {
  columns: Array<string>;
  profitLevers: Array<ProfitUpliftTableRecord>;
  total: number;
}

export class ProfitUpliftTable extends ProfitUpliftTableSchema {
  constructor(filterAttributes?: ProfitUpliftTableSchema) {
    super();
    assign(this, filterAttributes);
  }
}

class ProfitUpliftTableRecordSchema {
  title: string;
  value: number;
  valueType?: ValueType;
}

export class ProfitUpliftTableRecord extends ProfitUpliftTableRecordSchema {
  constructor(filterAttributes?: ProfitUpliftTableRecordSchema) {
    super();
    assign(this, filterAttributes);
  }
}
