import { TileDefinition } from '../../entities/tile-definition';
import { ProfitUpliftTileDefinition } from '../../entities/profituplift-tile-definition';
import { MetricType } from 'src/app/shared/constants/metrics.constants';
import {
  ProfitLeverDetailsResponse,
  ProfitUpSections,
} from '../entities/profituplift-tile-response';
import { ProjectionTileConstants } from '../../projection-tile/constants/projection-tile.constants';
import { ProfitUpProjTileDefinition } from '../../entities/profitup-proj-tile-definition';
import { ValueType } from 'src/app/shared/constants/common.constants';
import Dictionary from 'src/app/core/utils/dictionary.utils';

export const ProfitUpliftTileConstants: Record<string, TileDefinition> = {
  ProfitTileDefinition: new TileDefinition({
    title: {
      projection: 'Profit Uplift -',
      actuals: 'Profit Uplift -',
    },
    subtitle: '',
    subAttrFnc: () => {
      return '';
    },
    items: [],
  }),
  expandedDetailed: new TileDefinition({
    title: {
      projection: 'expandedDetailedTile',
      actuals: 'expandedDetailedTile',
    },
    subtitle: '',
    subAttrFnc: () => {
      return '';
    },
    items: [],
  }),
  summaryTile: new TileDefinition({
    title: {
      projection: 'summaryTile',
      actuals: 'summaryTile',
    },
    subtitle: '',
    subAttrFnc: (params: Dictionary<any>) => {
      if (params.getItem('metric') === 3) {
        return params.getItem('isPercentage') ? 'percentage' : 'values';
      }
      return '';
    },
    items: [
      {
        id: 'actuals',
        title: 'Actuals',
        typeFnc: (params: Dictionary<any>) => {
          return params.getItem('isPercentage')
            ? ValueType.Percentage
            : ValueType.Numeric;
        },
        isTotal: false,
        negativeStyleEnabled: true,
        positiveStyleEnabled: false,
        link: 'Actuals',
      },
      {
        id: 'forecast',
        title: 'Forecast',
        typeFnc: (params: Dictionary<any>) => {
          return params.getItem('isPercentage')
            ? ValueType.Percentage
            : ValueType.Numeric;
        },
        isTotal: false,
        negativeStyleEnabled: true,
        positiveStyleEnabled: false,
        link: 'Forecast',
      },
      {
        id: 'projExcludePUSpeculative',
        title: 'Projection excluding Profit Uplift Speculative',
        typeFnc: (params: Dictionary<any>) => {
          return params.getItem('isPercentage')
            ? ValueType.Percentage
            : ValueType.Numeric;
        },
        isTotal: false,
        negativeStyleEnabled: true,
        positiveStyleEnabled: false,
        link: 'Projection excluding Profit Uplift Speculative',
      },
      {
        id: 'puSpeculative',
        title: 'Profit Uplift Speculative',
        typeFnc: (params: Dictionary<any>) => {
          return params.getItem('isPercentage')
            ? ValueType.Percentage
            : ValueType.Numeric;
        },
        isTotal: false,
        negativeStyleEnabled: true,
        positiveStyleEnabled: false,
        link: 'Profit Uplift Speculative',
      },
      {
        id: 'projExcludePUBacklog',
        title: 'Projection excluding Profit Uplift Spec and Backlog',
        typeFnc: (params: Dictionary<any>) => {
          return params.getItem('isPercentage')
            ? ValueType.Percentage
            : ValueType.Numeric;
        },
        isTotal: false,
        negativeStyleEnabled: true,
        positiveStyleEnabled: false,
        link: 'Projection excluding Profit Uplift Spec and Backlog',
      },
      {
        id: 'puBacklog',
        title: 'Profit Uplift incl. in Backlog',
        typeFnc: (params: Dictionary<any>) => {
          return params.getItem('isPercentage')
            ? ValueType.Percentage
            : ValueType.Numeric;
        },
        isTotal: false,
        negativeStyleEnabled: true,
        positiveStyleEnabled: false,
        link: 'Profit Uplift incl. in Backlog',
      },
      {
        id: 'total',
        title: 'Total',
        typeFnc: (params: Dictionary<any>) => {
          return params.getItem('isPercentage')
            ? ValueType.Percentage
            : ValueType.Numeric;
        },
        isTotal: true,
        negativeStyleEnabled: true,
        positiveStyleEnabled: true,
      },
    ],
  }),
};

export const PLTileDropdownConstants: Record<
  string,
  ProfitUpliftTileDefinition
> = {
  Sales: new ProfitUpliftTileDefinition({
    title: 'Profit Uplift - Sales',
    type: MetricType.Sales,
  }),
  Revenue: new ProfitUpliftTileDefinition({
    title: 'Profit Uplift - Revenue',
    type: MetricType.Revenue,
  }),
  Cci: new ProfitUpliftTileDefinition({
    title: 'Profit Uplift - Delivered CCI',
    type: MetricType.Cci,
  }),
};

export const PLProjTileDropdownConstants: Record<
  string,
  ProfitUpProjTileDefinition
> = {
  Cci: new ProfitUpProjTileDefinition({
    title: ProjectionTileConstants.Cci.title.projection,
    type: MetricType.Cci,
    allowPercentage: true,
  }),
  Sales: new ProfitUpProjTileDefinition({
    title: ProjectionTileConstants.Sales.title.projection,
    type: MetricType.Sales,
  }),
  Revenue: new ProfitUpProjTileDefinition({
    title: ProjectionTileConstants.Revenue.title.projection,
    type: MetricType.Revenue,
  }),
};

export const TotalProfitUpliftTile: ProfitLeverDetailsResponse = {
  id: 'total',
  name: 'Total',
  value: 0,
};

export const ProfitTileErrorMessages = {
  Service504:
    "We couldn't retrieve the data in time. Try refreshing your browser to fix the issue.",
  ServiceDefault:
    'There was an issue during the process. Please, try again later.',
};

export const ProfitUpliftTileURL = {
  sales: '?type=salesprofituplift&isCloud=true',
  revenue: '?type=revenueprofituplift&isCloud=true',
  cci: '?type=cciprofituplift&isCloud=true',
};

export const SummaryProfitUpliftTileURL = {
  sales: '?type=summarypusales&isCloud=true',
  revenue: '?type=summarypurevenue&isCloud=true',
  cci: '?type=summarypucci&isCloud=true',
  cciPercentage:
    '?type=summarypu' + encodeURIComponent('cci%') + '&isCloud=true',
};

export const ExpandedDetailedProfitUpliftTileURL = {
  sales: '?type=expandedpusales&isCloud=true',
  revenue: '?type=expandedpurevenue&isCloud=true',
  cci: '?type=expandedpucci&isCloud=true',
  cciPercentage:
    '?type=expandedpu' + encodeURIComponent('cci%') + '&isCloud=true',
};

export const ProfitUpTileMode = {
  summary: 'Summary',
  expandedDetailed: 'Expanded/Detailed',
};

export const ProfitUpTileSize = {
  short: 'short',
  medium: 'medium',
  large: 'large',
};

export const ProfitLeverSections: ProfitUpSections = {
  Speculative: {
    id: 'PU-Spec',
    name: 'PU-Spec',
    value: 0,
    shortName: 'Profit Uplift Speculative',
  },
  Backlog: {
    id: 'PU-Back',
    name: 'PU-Back',
    value: 0,
    shortName: 'Profit Uplift Backlog',
  },
};
