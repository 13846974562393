import { EventEmitter, Injectable } from '@angular/core';

import { ProfitUpliftModalConfig } from '../../components/tiles/profituplift-modal/entities/profituplift-modal-config';
import { ProfitUpliftModalTypeChanged } from '../../components/tiles/profituplift-modal/entities/profituplift-modal-events';

@Injectable({
  providedIn: 'root',
})
export class ProfitUpliftModalService {
  private openTrendModalEvent = new EventEmitter<ProfitUpliftModalConfig>();
  private typeChangedEvent = new EventEmitter<ProfitUpliftModalTypeChanged>();

  public get openTrendModalEmitted(): EventEmitter<ProfitUpliftModalConfig> {
    return this.openTrendModalEvent;
  }

  public get typeChanged(): EventEmitter<ProfitUpliftModalTypeChanged> {
    return this.typeChangedEvent;
  }
}
