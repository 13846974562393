<div class="row">
	<div class="col">
		<div ngbDropdown #optionsDropdown="ngbDropdown" class="d-inline-block">
			<div class="icon-container" id="ddl-options" container="body" placement="top" ngbDropdownAnchor
				(click)="optionsDropdown.toggle()" triggers="mouseenter:mouseleave">
				<div class="icon-position">
					<fa-icon [icon]="config.icon">
					</fa-icon>
					<span>{{config.text}}</span>
				</div>
			</div>
			<div ngbDropdownMenu aria-labelledby="dropdownForm1">
				<div *ngFor="let e of config.items; last as isLast">
					<div class="px-4">
						<div *ngIf="e.title.length" class="py-1 pb-2">
							<label class="option-title">{{e.title}}</label>
						</div>
						<div *ngFor="let b of e.options">
							<button [disabled]="b.params?.isDisabled" type="button" (click)="onValueChanged(b)" ngbDropdownItem
								class="py-2">{{b.text}}</button>
						</div>
					</div>
					<div *ngIf="!isLast" class="pt-2">
						<div class="dropdown-divider"></div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>