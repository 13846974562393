import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';

import { GridToolbarControlType } from 'src/app/shared/constants/grid.constants';
import { GridToolbarConfig } from './entities/grid-toolbar-config';
import { SearchInputComponent } from '../../../search-input/search-input.component';
import { FeatureToggleComponent } from '../../../../feature-toggle/feature-toggle.component';
import { SwitchComponent } from '../../../switch/switch.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { DropDownComponent } from '../../../drop-down/drop-down.component';
import { DropDownMultipleComponent } from '../../../drop-down-multiple/drop-down-multiple.component';
import { NgIf, NgFor, NgSwitch, NgSwitchCase, NgClass } from '@angular/common';
import { SharedModule } from 'src/app/shared-module';
import { DropDownMenuComponent } from '../../../drop-down-menu/drop-down-menu.component';

@Component({
  selector: 'app-grid-toolbar',
  templateUrl: './grid-toolbar.component.html',
  styleUrls: ['./grid-toolbar.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    NgIf,
    NgFor,
    NgSwitch,
    NgSwitchCase,
    DropDownMultipleComponent,
    DropDownComponent,
    SharedModule,
    NgClass,
    FontAwesomeModule,
    SwitchComponent,
    FeatureToggleComponent,
    SearchInputComponent,
    DropDownMenuComponent,
  ],
})
export class GridToolbarComponent {
  @Input() config: GridToolbarConfig;
  @Input() isBeta: boolean;
  @Output() searchEvent = new EventEmitter<string>();

  public get controlType(): typeof GridToolbarControlType {
    return GridToolbarControlType;
  }

  onSearchTextChanged(searchText: string): void {
    this.searchEvent.emit(searchText);
  }
}
