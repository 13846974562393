import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, Subject, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { Headers } from 'src/app/core/constants/http.constants';
import { HttpUtils, IHttpOptions } from 'src/app/core/utils/http.utils';
import { Endpoints } from '../../constants/endpoints.constants';
import { MetricType } from '../../constants/metrics.constants';
import { RequestPayload } from '../entities/request-payload';
import { ITileResponse } from '../../components/tiles/entities/itile-response';
import { TextValuePair } from '../entities/common/key-value';
import {
  ExpandedDetailedProfitUpliftTileURL,
  ProfitUpliftTileURL,
  ProfitUpTileMode,
  SummaryProfitUpliftTileURL,
} from '../../components/tiles/profituplift-tile/constants/profituplift-tile.constants';

@Injectable({
  providedIn: 'root',
})
export class ProfitupliftTileService {
  private metricChangedObservable = new Subject<TextValuePair>();

  public get metricChanged(): Subject<TextValuePair> {
    return this.metricChangedObservable;
  }

  constructor(private http: HttpClient) {}

  getTileDataByType<T extends ITileResponse>(
    type: MetricType,
    request: RequestPayload
  ): Promise<T> {
    const uri: string = this.getUrlByMetricType(type);

    const options: IHttpOptions = HttpUtils.getOptions([
      Headers.CLIENTAPPID_HEADER,
      Headers.IMPERSONATION_USER_HEADER,
      Headers.CORRELATION_ID_HEADER,
    ]);

    return this.http
      .post<T>(uri, request, options)
      .pipe(catchError(this.errorHandler.bind(this)))
      .toPromise();
  }

  private getUrlByMetricType(type: MetricType): string {
    switch (type) {
      case MetricType.Sales:
        return `${Endpoints.tile}` + ProfitUpliftTileURL.sales;
      case MetricType.Revenue:
        return `${Endpoints.tile}` + ProfitUpliftTileURL.revenue;
      case MetricType.Cci:
        return `${Endpoints.tile}` + ProfitUpliftTileURL.cci;
      default:
        return '';
    }
  }

  getSpecBacklogData<T extends ITileResponse>(
    metricType: MetricType,
    request: RequestPayload,
    isPercentage: boolean,
    tileType: string
  ): Promise<T> {
    const uri: string = this.getSummaryExpandUrlByMetricType(
      metricType,
      isPercentage,
      tileType
    );

    const options: IHttpOptions = HttpUtils.getOptions([
      Headers.CLIENTAPPID_HEADER,
      Headers.IMPERSONATION_USER_HEADER,
      Headers.CORRELATION_ID_HEADER,
    ]);

    return this.http
      .post<T>(uri, request, options)
      .pipe(catchError(this.errorHandler.bind(this)))
      .toPromise();
  }

  private getSummaryExpandUrlByMetricType(
    metricType: MetricType,
    isPercentage: boolean,
    tileType: string
  ): string {
    let urlsTypes;

    switch (tileType) {
      case ProfitUpTileMode.summary:
        urlsTypes = SummaryProfitUpliftTileURL;
        break;
      case ProfitUpTileMode.expandedDetailed:
        urlsTypes = ExpandedDetailedProfitUpliftTileURL;
        break;
      default:
        urlsTypes = '';
    }

    switch (metricType) {
      case MetricType.Sales:
        return `${Endpoints.tile}` + urlsTypes.sales;
      case MetricType.Revenue:
        return `${Endpoints.tile}` + urlsTypes.revenue;
      case MetricType.Cci:
        return (
          `${Endpoints.tile}` +
          (isPercentage ? urlsTypes.cciPercentage : urlsTypes.cci)
        );
      default:
        return '';
    }
  }

  private errorHandler(error: HttpErrorResponse): Observable<never> {
    return throwError(error || 'Server error (ProfitUpliftTileService)');
  }
}
