import Dictionary from 'src/app/core/utils/dictionary.utils';
import { MetricType } from 'src/app/shared/constants/metrics.constants';
import { ValueType } from 'src/app/shared/constants/common.constants';
import { TextValuePair } from 'src/app/shared/services/entities/common/key-value';
import { Plan } from 'src/app/shared/services/entities/filters/plan';
import { Projection } from 'src/app/shared/services/entities/filters/projection';
import { TargetsTile } from '../entities/targets-tile';
import { TargetsTileDefinition } from '../entities/targets-tile-definition';
import { TargetsTileTarget } from '../entities/targets-tile-item';
import { TargetsTileProgress } from '../entities/targets-tile-progress';
import { TargetsTileResponse } from '../entities/targets-tile-response';
import { TargetsTileConstants } from '../targets-tile/constants/targets-tile.constants';
import { Currency } from 'src/app/shared/services/entities/filters/currency';
import { CustomCurrencies } from 'src/app/shared/constants/filters.constants';

export class TargetsTileUtils {
  static getItems(
    definition: TargetsTileDefinition,
    type: MetricType,
    source: TargetsTileResponse,
    params?: Dictionary<any>
  ): TargetsTile {
    params = params ?? new Dictionary<any>();
    const projection: Projection = params.getItem('projection');
    const plan: Plan = params.getItem('plan');
    const allowPercentage: boolean = params.getItem('allowPercentage');
    const currency: Currency = params.getItem('currency');
    const hasAttributesSelected: boolean = params.getItem(
      'hasAttributesSelected'
    );
    const isFxAdjusted: boolean =
      currency.Id === CustomCurrencies.FxAdjusted.id;
    const isConstant: boolean = currency.Id === CustomCurrencies.Constant.id;
    const isProjectionDisabled: boolean = isFxAdjusted || isConstant;
    const isPlanDisabled: boolean =
      (isFxAdjusted && type === MetricType.Cci) || hasAttributesSelected;

    const targetsTile = new TargetsTile({
      title: definition.title.projection,
      type: type,
      targets: [],
      progress: [],
    });

    targetsTile.progress.push(
      new TargetsTileProgress({
        value: source.Progress,
        type: ValueType.Numeric,
      })
    );

    targetsTile.targets.push(
      new TargetsTileTarget({
        title: projection.getText(),
        progress: isProjectionDisabled
          ? null
          : this.calculateTargetProgress(
              source.Progress,
              source.Items.Projection
            ),
        target: isProjectionDisabled ? null : source.Items.Projection ?? 0,
        variance: isProjectionDisabled
          ? null
          : this.calculateVariance(source.Progress, source.Items.Projection),
      }),
      new TargetsTileTarget({
        title: plan.getText(),
        progress: isPlanDisabled
          ? null
          : this.calculateTargetProgress(source.Progress, source.Items.Plan),
        target: isPlanDisabled ? null : source.Items.Plan ?? 0,
        variance: isPlanDisabled
          ? null
          : this.calculateVariance(source.Progress, source.Items.Plan),
      })
    );

    if (allowPercentage) {
      targetsTile.progress.push(
        new TargetsTileProgress({
          value: source.PercentageValues.Progress,
          type: ValueType.Percentage,
        })
      );

      targetsTile.targets.push(
        new TargetsTileTarget({
          title: projection.getText(),
          progress: isProjectionDisabled
            ? null
            : this.calculateTargetProgress(
                source.PercentageValues.Progress,
                source.PercentageValues.Projection,
                true
              ),
          progressType: ValueType.Percentage,
          target: isProjectionDisabled
            ? null
            : source.PercentageValues.Projection ?? 0,
          targetType: ValueType.Percentage,
          variance: isProjectionDisabled
            ? null
            : this.calculateVariance(
                source.PercentageValues.Progress,
                source.PercentageValues.Projection,
                true
              ),
          varianceType: ValueType.BPS_Rounded,
        }),
        new TargetsTileTarget({
          title: plan.getText(),
          progress: isPlanDisabled
            ? null
            : this.calculateTargetProgress(
                source.PercentageValues.Progress,
                source.PercentageValues.Plan,
                true
              ),
          progressType: ValueType.Percentage,
          target: isPlanDisabled ? null : source.PercentageValues.Plan ?? 0,
          targetType: ValueType.Percentage,
          variance: isPlanDisabled
            ? null
            : this.calculateVariance(
                source.PercentageValues.Progress,
                source.PercentageValues.Plan,
                true
              ),
          varianceType: ValueType.BPS_Rounded,
        })
      );
    }

    return targetsTile;
  }

  static getDefinition(type: MetricType): TargetsTileDefinition {
    switch (type) {
      case MetricType.Sales:
        return TargetsTileConstants.Sales;
      case MetricType.Revenue:
        return TargetsTileConstants.Revenue;
      case MetricType.Cci:
        return TargetsTileConstants.Cci;
    }

    return null;
  }

  static getMetricList(
    isActuals: boolean,
    callback?: (x: TextValuePair) => void
  ): Array<TextValuePair> {
    const keys: Array<string> = Object.keys(TargetsTileConstants);
    const titleKey: string = isActuals ? 'actuals' : 'projection';

    const metrics: Array<TextValuePair> = keys.map((x: string) => {
      const textValuePair = new TextValuePair({
        text: (TargetsTileConstants as any)[x].title[titleKey],
        value: (TargetsTileConstants as any)[x].type,
      });

      if (callback) {
        callback(textValuePair);
      }

      return textValuePair;
    });

    callback = null;
    return metrics;
  }

  private static calculateVariance(
    progress: number,
    target: number,
    isPercentage?: boolean
  ): number {
    if (progress !== undefined && progress !== null && target) {
      return progress - target;
    } else if (progress !== undefined && progress !== null && !isPercentage) {
      return progress;
    } else if (isPercentage) {
      return null;
    }

    return 0;
  }

  private static calculateTargetProgress(
    progress: number,
    target: number,
    isPercentage?: boolean
  ): number {
    if (isPercentage) {
      return target && target > 0 ? 1 : null;
    } else {
      if (progress && target && target > 0) {
        return progress / target;
      } else if (progress) {
        return 1;
      }
    }

    return 0;
  }
}
