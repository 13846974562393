import { NgIf, NgFor, NgClass } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { ValueType } from 'exceljs';
import { ValueType as ValueTypeForTable } from 'src/app/shared/constants/common.constants';
import { ProfitUpliftTableConfig } from '../../entities/profituplift-table.config';
import {
  ProfitUpliftTable,
  ProfitUpliftTableRecord,
} from '../../../entities/profituplift-table-data';
import { cloneDeep } from 'lodash';
import { ProfitLeverDetailsResponse } from '../../entities/profituplift-tile-response';
import { CustomNumberPipe } from 'src/app/shared/pipes/custom-number.pipe';

@Component({
  selector: 'app-profituplift-table',
  standalone: true,
  imports: [NgIf, NgFor, NgClass, CustomNumberPipe],
  templateUrl: './profituplift-table.component.html',
  styleUrl: './profituplift-table.component.scss',
})
export class ProfitupliftTableComponent implements OnChanges {
  @Input() config: ProfitUpliftTableConfig;
  @Output() closeModalEvent = new EventEmitter<void>();

  public get valueType(): typeof ValueType {
    return ValueType;
  }

  profitUpliftTable: ProfitUpliftTable;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.config && changes.config.currentValue) {
      this.renderTable();
    }
  }

  renderTable(): void {
    this.profitUpliftTable = this.getTableData(this.config.response);
  }

  private getTableData(source: any): ProfitUpliftTable {
    const table = new ProfitUpliftTable();
    const tableData: Array<ProfitLeverDetailsResponse> = cloneDeep(source);

    table.columns = ['$'];
    table.profitLevers = [];

    tableData.forEach((x: ProfitLeverDetailsResponse) => {
      if (x.id.toLowerCase() === 'total') {
        table.total = x.value;
      } else {
        const element: ProfitUpliftTableRecord = {
          title: x.name,
          value: x.value,
          valueType: ValueTypeForTable.Numeric,
        };
        table.profitLevers.push(element);
      }
    });
    return table;
  }
}
