import { TimeframeItem } from 'src/app/shared/components/timeframe/entities/timeframe';
import { TimeframeUtils } from 'src/app/shared/components/timeframe/utils/timeframe.utils';
import { FavoriteResponseItem } from 'src/app/shared/services/entities/favorite-response';
import { ActiveDate } from 'src/app/shared/services/entities/filters/active-date';
import { Currency } from 'src/app/shared/services/entities/filters/currency';
import { MmbDate } from 'src/app/shared/services/entities/filters/date';
import { Filters } from 'src/app/shared/services/entities/filters/filters';
import { Timeframe } from 'src/app/shared/services/entities/filters/timeframe';
import {
  FavoriteSaveFiltersModel,
  FavoriteSaveModel,
} from '../entities/favorite-save-model';

export class FavoriteMapper {
  static mapContract(
    source: FavoriteResponseItem,
    filters: Filters
  ): FavoriteResponseItem {
    if (source.filters.Currency) {
      source.filters.Currency = filters.currencies.find(
        (x: Currency) => x.Id === source.filters.Currency.Id
      );
    }
    if (source.filters.Timeframe) {
      if (!source.filters.Timeframe.TimePeriodCode.includes('Custom')) {
        const timeframe: TimeframeItem = TimeframeUtils.getTimeframeItemByCode(
          filters,
          source.filters.Timeframe.TimePeriodCode
        );
        source.filters.Timeframe = new Timeframe({
          EndTimeId: timeframe.end,
          StartTimeId: timeframe.start,
          TimePeriodCode: timeframe.code,
          Name: timeframe.title,
          Description: timeframe.getDescription(),
        });
      } else {
        const appDate: ActiveDate = TimeframeUtils.getAppDate(filters);
        const appDateTime: MmbDate = filters.dates.find(
          (x: MmbDate) => x.Id === appDate.StartTimeId
        );
        const timeframe = new TimeframeItem({
          start: Number(source.filters.Timeframe.TimePeriodCode.split('-')[1]),
          end: Number(source.filters.Timeframe.TimePeriodCode.split('-')[2]),
          code: 'Custom',
          mmbPeriod: 'Custom',
          title: 'Custom',
          type: 'Custom',
          fiscalYear: appDateTime.FiscalYearNbr,
          enabled: true,
          isPast:
            appDate.StartTimeId >
            Number(source.filters.Timeframe.TimePeriodCode.split('-')[2]),
        });
        source.filters.Timeframe = new Timeframe({
          EndTimeId: timeframe.end,
          StartTimeId: timeframe.start,
          TimePeriodCode: 'Custom',
          Name: timeframe.title,
          Description: timeframe.getDescription(),
          FiscalYear: timeframe.fiscalYear,
        });
      }
    }

    return source;
  }

  static getSaveModel(favorite: FavoriteResponseItem): FavoriteSaveModel {
    return new FavoriteSaveModel({
      favoriteId: favorite.favoriteId,
      default: favorite.default,
      filters: new FavoriteSaveFiltersModel({
        Currency: favorite.filters.Currency,
        Customer: favorite.filters.Customer,
        Timeframe: new Timeframe({
          EndTimeId: favorite.filters.Timeframe.EndTimeId,
          StartTimeId: favorite.filters.Timeframe.StartTimeId,
          TimePeriodCode:
            favorite.filters.Timeframe.TimePeriodCode === 'Custom'
              ? 'Custom-' +
                favorite.filters.Timeframe.StartTimeId +
                '-' +
                favorite.filters.Timeframe.EndTimeId
              : favorite.filters.Timeframe.TimePeriodCode,
          FiscalYear: favorite.filters.Timeframe.FiscalYear,
        }),
        Alliances: favorite.filters.Alliances,
        ClientServiceGroups: favorite.filters.ClientServiceGroups,
        Wmus: favorite.filters.Wmus,
        Sgi: favorite.filters.Sgi,
        ServiceGroups: favorite.filters.ServiceGroups,
        Locations: favorite.filters.Locations,
        Industries: favorite.filters.Industries,
        Functions: favorite.filters.Functions,
        FinancialCustomer: favorite.filters.FinancialCustomer,
      }),
      name: favorite.name,
      sharedBy: favorite.sharedBy,
      metric: favorite.metric,
      period: favorite.period,
      group1: favorite.group1,
      group2: favorite.group2,
      group3: favorite.group3,
      group4: favorite.group4,
      target1: favorite.target1,
      target2: favorite.target2,
    });
  }
}
