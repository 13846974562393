import { ChangeDetectorRef, Component, OnInit, Input } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { isNumber } from 'lodash';

import { GilcSalesResponse } from '../../entities/gilc-sales-response';
import { GilcTileComponent } from '../../gilc-tile.component';
import { MetricType } from 'src/app/shared/constants/metrics.constants';
import { RequestPayload } from 'src/app/shared/services/entities/request-payload';
import { GilcTileConstants } from '../../constants/gilc-tile.constants';
import { TileUtils } from '../../../utils/tile.utils';

import { FiltersService } from 'src/app/shared/services/filters.service';
import { GilcTileService } from 'src/app/shared/services/tiles/gilc-tile.service';
import { ErrorHandlerService } from 'src/app/core/services/error-handler.service';
import { BetaMessageService } from 'src/app/shared/services/beta-message.service';
import { AppStateService } from 'src/app/shared/services/app-state.service';
import { SpinnerComponent } from '../../../../base/spinner/spinner.component';
import { FeatureToggleComponent } from '../../../../feature-toggle/feature-toggle.component';
import { NgIf, NgClass, NgFor } from '@angular/common';

@Component({
  selector: 'app-gilc-sales-tile',
  templateUrl: '../../gilc-tile.component.html',
  styleUrls: ['../../gilc-tile.component.scss'],
  standalone: true,
  imports: [NgIf, FeatureToggleComponent, NgClass, NgFor, SpinnerComponent],
})
export class SalesGilcTileComponent
  extends GilcTileComponent
  implements OnInit
{
  constructor(
    private gilcTileService: GilcTileService,
    private filterService: FiltersService,
    private errorHandlerService: ErrorHandlerService,
    private changeDetector: ChangeDetectorRef,
    betaMessage: BetaMessageService,
    appStateService: AppStateService
  ) {
    super(
      gilcTileService,
      filterService,
      errorHandlerService,
      changeDetector,
      MetricType.Sales,
      betaMessage,
      appStateService
    );
  }

  ngOnInit(): void {
    super.setTempTitle(GilcTileConstants.Sales.title.common);
    super.initializeComponent((x: RequestPayload) => {
      // this.subscription.add(
      //   this.betaMessage.isCloudSubject$.subscribe((betaFlag) => {
      //     this.isBeta = betaFlag;
      //     this.isCloud =
      //       (betaFlag && !this.isFlipFlopFlagEnabled) ||
      //       (!betaFlag && this.isFlipFlopFlagEnabled);
      if (this.filterService.customers.length > 0) {
        super.loadStarted();
        super.clearTile();
        super.setTempItems(GilcTileConstants.Sales.items);
        this.gilcTileService
          .getTileDataByType<GilcSalesResponse>(this.type, x, true) //this.isCloud
          .then((y: GilcSalesResponse) => {
            if (isNumber(y)) {
              throw new HttpErrorResponse({
                status: y,
              });
            }

            this.gilcTile = TileUtils.getItems<GilcSalesResponse>(
              GilcTileConstants.Sales,
              y
            );
          })
          .catch((error: HttpErrorResponse) => {
            super.handleTileError(error);
          })
          .finally(() => {
            super.loadCompleted();
          });
      }
    });
    //   );
    // });
  }
}
