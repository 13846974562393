import {
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';

import { SelectedFilters } from 'src/app/shared/services/entities/filters/selected-filters';
import { AppliedFilterTable } from './entities/applied-filter-table';
import { Wmu } from 'src/app/shared/services/entities/filters/wmu';
import { FinancialCustomer } from 'src/app/shared/services/entities/filters/financial-customer';
import { Location } from 'src/app/shared/services/entities/filters/location';
import { ClientGroup } from 'src/app/shared/services/entities/filters/client-group';
import { ServiceGroupValues } from 'src/app/shared/services/entities/filters/service-group';
import { Industry } from 'src/app/shared/services/entities/filters/industry';
import { ProductHierarchy } from 'src/app/shared/services/entities/filters/product-hierarchy';
import { NgFor } from '@angular/common';
import { ProfitLevers } from 'src/app/shared/services/entities/filters/profitUplift';

@Component({
  selector: 'app-applied-filters-tooltip',
  templateUrl: './applied-filters-tooltip.component.html',
  styleUrls: ['./applied-filters-tooltip.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [NgFor],
})
export class AppliedFiltersTooltipComponent implements OnChanges {
  @Input() appliedFilters: SelectedFilters;
  @Input() onlyShowProfitLevers: Boolean = false;

  appliedFilterTables: Array<AppliedFilterTable> = [];

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.appliedFilters && changes.appliedFilters.currentValue) {
      if (this.onlyShowProfitLevers) {
        this.initializeComponentPL();
      } else {
        this.initializeComponent();
      }
    }
  }

  initializeComponent(): void {
    if (this.appliedFilters.wmus?.length > 0) {
      this.appliedFilterTables.push(
        new AppliedFilterTable({
          count: this.appliedFilters.wmus.length,
          title: 'WMU',
          values: this.appliedFilters.wmus.map((x: Wmu) => x.UN),
        })
      );
    }

    if (this.appliedFilters.financialCustomers?.length > 0) {
      this.appliedFilterTables.push(
        new AppliedFilterTable({
          count: this.appliedFilters.financialCustomers.length,
          title: 'Financial Customer',
          values: this.appliedFilters.financialCustomers.map(
            (x: FinancialCustomer) => `${x.CustomerNumber} - ${x.CustomerName}`
          ),
        })
      );
    }

    if (this.appliedFilters.markets?.length > 0) {
      this.appliedFilterTables.push(
        new AppliedFilterTable({
          count: this.appliedFilters.markets.length,
          title: 'Market',
          values: this.appliedFilters.markets.map(
            (x: Location) => x.OrganizationName
          ),
        })
      );
    }

    if (this.appliedFilters.clientGroups?.length > 0) {
      this.appliedFilterTables.push(
        new AppliedFilterTable({
          count: this.appliedFilters.clientGroups.length,
          title: 'Client Group',
          values: this.appliedFilters.clientGroups.map(
            (x: ClientGroup) => x.ClientGroupDesc
          ),
        })
      );
    }

    if (this.appliedFilters.serviceGroups?.length > 0) {
      this.appliedFilterTables.push(
        new AppliedFilterTable({
          count: this.appliedFilters.serviceGroups.length,
          title: 'Service Group',
          values: this.appliedFilters.serviceGroups.map(
            (x: ServiceGroupValues) => x.ServiceGroupNm
          ),
        })
      );
    }

    if (this.appliedFilters.industries?.length > 0) {
      this.appliedFilterTables.push(
        new AppliedFilterTable({
          count: this.appliedFilters.industries.length,
          title: 'Industry',
          values: this.appliedFilters.industries.map(
            (x: Industry) => x.EntityDescription
          ),
        })
      );
    }

    if (this.appliedFilters.functions?.length > 0) {
      this.appliedFilterTables.push(
        new AppliedFilterTable({
          count: this.appliedFilters.functions.length,
          title: 'Functions',
          values: this.appliedFilters.functions.map(
            (x: ProductHierarchy) => x.ProductName
          ),
        })
      );
    }

    if (this.appliedFilters.growthPriorities?.length > 0) {
      this.appliedFilterTables.push(
        new AppliedFilterTable({
          count: this.appliedFilters.growthPriorities.length,
          title: 'Growth Priorities',
          values: this.appliedFilters.growthPriorities.map(
            (x: ProductHierarchy) =>
              x.ProductName === 'AWS' ? 'AMAZON' : x.ProductName
          ),
        })
      );
    }

    if (this.appliedFilters.alliances?.length > 0) {
      this.appliedFilterTables.push(
        new AppliedFilterTable({
          count: this.appliedFilters.alliances.length,
          title: 'Alliances',
          values: this.appliedFilters.alliances.map(
            (x: ProductHierarchy) => x.ProductName
          ),
        })
      );
    }
  }

  initializeComponentPL(): void {
    if (this.appliedFilters.profitLevers?.length > 0) {
      this.appliedFilterTables.push(
        new AppliedFilterTable({
          count: this.appliedFilters.profitLevers.length,
          title: 'ProfitLevers',
          values: this.appliedFilters.profitLevers.map(
            (x: ProfitLevers) => x.ProfitLeverName
          ),
        })
      );
    }
  }
}
