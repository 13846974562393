<li [ngClass]="{'disabled' : item.disabled, 'selectable' : item.hasCheckBox}">
    <span class="arrow">
        <fa-icon *ngIf="item.hasChildren()" [icon]="item.isOpen ? faAngleDown : faAngleRight" (click)="onToggle()">
        </fa-icon>
    </span>
    <input *ngIf="item.hasCheckBox" type="checkbox" [(ngModel)]="item.checked"
        (ngModelChange)="onItemSelection($event)">
    <div *ngIf="item.iconCssName" [ngClass]="item.iconCssName"></div>
    <span #truncatedSpan class="item-text" (click)="onItemSelection()" [ngClass]="item.getCssClasses()" [ngbTooltip]="isTruncated ? item.text : null">{{item.text}}</span>
    <ul *ngIf="item.isOpen && item.hasChildren()" class="tree-view-children">
        <app-tree-view-check-item *ngFor="let child of item.children" [item]="child" [config]="config"
            (itemSelectionChangeEvent)="onChildItemSelection($event)" [isSearch]="isSearch"></app-tree-view-check-item>
    </ul>
</li>