import { assign } from 'lodash';

import { MetricType } from 'src/app/shared/constants/metrics.constants';
import { ProfitUpliftTileDefinition } from '../../entities/profituplift-tile-definition';

class ProfitUpliftConfigSchema {
  id: string;
  type: MetricType;
  response: any;
  definition: ProfitUpliftTileDefinition;
}

export class ProfitUpliftTableConfig extends ProfitUpliftConfigSchema {
  constructor(filterAttributes?: ProfitUpliftConfigSchema) {
    super();
    assign(this, filterAttributes);
  }
}
