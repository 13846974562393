<div class="horizontal-scrollbar">
    <div class="tile-content me-3">
        <app-profituplift-tile [profitUpMode]="summaryMode"></app-profituplift-tile>
    </div>
    <div class="tile-content me-3">
        <app-profituplift-tile [profitUpMode]="expDetlMode"></app-profituplift-tile>
    </div>
</div>
<div class="row mt-3">
    <div class="col">
        <!-- Grid !-->
    </div>
</div>