import { assign } from 'lodash';

import { MetricType } from 'src/app/shared/constants/metrics.constants';

class ProfitUpProjectionTileDefinitionSchema {
  title: string;
  type: MetricType;
  allowPercentage?: boolean = false;
}

export class ProfitUpProjTileDefinition extends ProfitUpProjectionTileDefinitionSchema {
  constructor(filterAttributes?: ProfitUpProjectionTileDefinitionSchema) {
    super();
    assign(this, filterAttributes);
  }
}
