import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { TextValuePair } from 'src/app/shared/services/entities/common/key-value';
import { GridToolbarDropdownMenu } from '../grid/components/grid-toolbar/entities/grid-toolbar-config';
import { NgIf, NgFor } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-drop-down-menu',
  templateUrl: './drop-down-menu.component.html',
  styleUrls: ['./drop-down-menu.component.scss'],
  standalone: true,
  imports: [NgbDropdownModule, FontAwesomeModule, NgIf, NgFor],
})
export class DropDownMenuComponent {
  @Input() config: GridToolbarDropdownMenu;
  @Output() itemSelectionChangeEvent = new EventEmitter<TextValuePair>();

  faDownload = faDownload as IconProp;

  onValueChanged(value: TextValuePair): void {
    this.itemSelectionChangeEvent.emit(value);
  }
}
