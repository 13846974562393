import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Subject } from 'rxjs';

import { FilterType } from 'src/app/shared/constants/filters.constants';
import { TreeViewConfig } from 'src/app/shared/components/base/tree-view/entities/tree-view-config';
import { TreeViewItem } from 'src/app/shared/components/base/tree-view/entities/tree-view-item';
import { TreeViewSelection } from 'src/app/shared/components/base/tree-view/entities/tree-view-selection';
import { TreeViewUtils } from 'src/app/shared/components/base/tree-view/utils/tree-view.utils';
import { FilterSelection } from 'src/app/shared/components/filters/entities/filter-selection';
import { FilterSelectionKeyValue } from 'src/app/shared/components/filters/entities/filter-selection-key-value';
import { TreeViewComponent } from 'src/app/shared/components/base/tree-view/tree-view.component';
import { SelectionControlComponent } from 'src/app/shared/components/base/selection-control/selection-control.component';
import { SearchInputComponent } from 'src/app/shared/components/base/search-input/search-input.component';
import { ProfitLevers } from 'src/app/shared/services/entities/filters/profitUplift';

@Component({
  selector: 'app-profitlevers-dropdown',
  templateUrl: './profitLevers-dropdown.component.html',
  styleUrls: ['./profitLevers-dropdown.component.scss'],
  standalone: true,
  imports: [SearchInputComponent, SelectionControlComponent, TreeViewComponent],
})
export class ProfitLeversDropdownComponent implements OnChanges {
  @Input() profitLevers: Array<ProfitLevers> = [];
  @Input() selectedprofitLevers: Array<ProfitLevers> = [];
  @Input() searchText: string;
  @Output() itemSelectionChangeEvent = new EventEmitter<FilterSelection>();
  @Output() searchEvent = new EventEmitter<string>();
  @Output() hasChangedEvent = new EventEmitter<boolean>();
  selectAllChangedEvent = new Subject<boolean>();

  profitLeversTreeviewConfig: TreeViewConfig;
  startSelection: TreeViewSelection;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.profitLevers && changes.profitLevers.currentValue) {
      this.profitLeversTreeviewConfig = new TreeViewConfig({
        parentAttr: 'ProfitLeverId',
        textAttr: 'ProfitLeverName',
        valueAttr: 'ParentProfitLeverId',
        countByNodes: true,
        selectByNodes: true,
        items: TreeViewUtils.getItems<ProfitLevers>(
          this.profitLevers,
          'ProfitLeverId',
          'ProfitLeverName',
          'ParentProfitLeverId'
        ),
      });
    }

    this.profitLeversTreeviewConfig.items.forEach((parent) => {
      parent.children.forEach((child) => {
        child.cssList.push('pu-tree-text');
      });
    });

    if (
      changes.selectedprofitLevers &&
      changes.selectedprofitLevers.currentValue
    ) {
      this.startSelection = new TreeViewSelection({
        selectedItems: this.selectedprofitLevers.map(
          (x: ProfitLevers) =>
            new TreeViewItem({
              value: x.ProfitLeverId,
              text: x.ProfitLeverName,
            })
        ),
      });
    }
  }

  onSearchTextChanged(searchText: string): void {
    this.searchText = searchText;
    this.searchEvent.emit(this.searchText);
  }

  onItemSelection(selectedprofitLevers: TreeViewSelection): void {
    this.itemSelectionChangeEvent.emit(
      new FilterSelection({
        type: FilterType.ProfitLever,
        keyValues: selectedprofitLevers.selectedItems.map(
          (x: TreeViewItem) =>
            new FilterSelectionKeyValue({
              key: x.value,
              value: x.text,
            })
        ),
        count: selectedprofitLevers.count,
      })
    );
  }

  onAllSelectionChanged(selectAll: boolean): void {
    this.selectAllChangedEvent.next(selectAll);
  }
}
