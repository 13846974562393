import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { combineLatest, Subject, Subscription } from 'rxjs';
import { cloneDeep, isUndefined } from 'lodash';

import {
  NgbDropdown,
  NgbTooltipModule,
  NgbDropdownModule,
} from '@ng-bootstrap/ng-bootstrap';
import { TimeframeChanged } from 'src/app/shared/components/timeframe/entities/timeframe-events';
import {
  CustomCurrencies,
  DropDownType,
  GroupsBy,
  Periods,
  ProfitLeverGroupBy,
  SubMetrics,
  TimePeriodCodes,
} from 'src/app/shared/constants/filters.constants';
import { TextValuePair } from 'src/app/shared/services/entities/common/key-value';
import {
  ProfitLVGroupBy,
  ProfitParams,
  ProfitSubMetric,
  TrendFiltersConstants,
} from '../../constants/trend-tile.constants';
import { FilterDefaults } from 'src/app/shared/services/entities/filters/filter-defaults';
import { TrendFilters } from './entities/trend-filters';
import { Filters } from 'src/app/shared/services/entities/filters/filters';
import { SelectedFilters } from 'src/app/shared/services/entities/filters/selected-filters';
import { TrendFiltersConfig } from './entities/trend-filters-config';
import { FilterDefaultsSaveResponse } from 'src/app/shared/services/entities/filters/filter-defaults-response';
import { TrendFiltersChanged } from './entities/trend-filters-events';
import { CustomerFilters } from 'src/app/shared/services/entities/filters/customer-filters';
import { AppMessage } from 'src/app/shared/services/entities/app-message';
import { MessageTemplates } from 'src/app/shared/constants/messages.constants';
import { StorageUtils } from 'src/app/core/utils/storage.utils';
import { StorageType } from 'src/app/core/constants/storage.constants';
import { AppModulePaths } from 'src/app/shared/constants/navbar.constants';

import { FiltersService } from 'src/app/shared/services/filters.service';
import { FilterDefaultsService } from 'src/app/shared/services/filter-defaults.service';
import { AppMessagesService } from 'src/app/shared/services/app-messages.service';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faStar as faStarPainted,
  faExpand,
  faTimes,
  faDownload,
  faUndo,
} from '@fortawesome/free-solid-svg-icons';
import { faStar } from '@fortawesome/free-regular-svg-icons';
import { DropDownComponent } from '../../../../base/drop-down/drop-down.component';
import { SwitchComponent } from '../../../../base/switch/switch.component';
import { TimeframeComponent } from '../../../../timeframe/timeframe.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgIf } from '@angular/common';
import { SharedModule } from 'src/app/shared-module';
import { SpeculativeGridUtils } from 'src/app/modules/speculative/components/speculative-grid/utils/speculative-grid.utils';

@Component({
  selector: 'app-trend-filters',
  templateUrl: './trend-filters.component.html',
  styleUrls: ['./trend-filters.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    FontAwesomeModule,
    SharedModule,
    TimeframeComponent,
    SwitchComponent,
    DropDownComponent,
  ],
})
export class TrendFiltersComponent
  implements OnInit, OnChanges, AfterViewInit, OnDestroy
{
  subscription = new Subscription();
  @Input() config: TrendFiltersConfig;
  @Output() filtersSelectionChangedEvent =
    new EventEmitter<TrendFiltersChanged>();
  @Output() expandSelectedEvent = new EventEmitter<void>();
  @Output() loadingCompletedEvent = new EventEmitter<boolean>();
  @Output() closeEvent = new EventEmitter<void>();
  @Output() exportFileEvent = new EventEmitter<Record<string, any>>();
  @Output() resetEvent = new EventEmitter<void>();

  @ViewChild('timeframeDropdown', { static: false })
  timeframeDropdown: NgbDropdown;

  public get dropDownType(): typeof DropDownType {
    return DropDownType;
  }

  filters: Filters;
  customerFilters: CustomerFilters;
  selectedFilters: SelectedFilters;
  trendFilters: TrendFilters;
  hasAppliedDefaults = false;

  filterDefaults: FilterDefaults;
  allFilterDefaults: Array<FilterDefaults> = [];
  filterDefaultsAndSelectionDiff = false;

  periods: Array<TextValuePair> = TrendFiltersConstants.Filters.Periods;
  groupsBy: Array<TextValuePair> = [];
  subMetrics: Array<TextValuePair> = [];
  updatedTimeframeSelectedTabEvent = new Subject<number>();

  faStar = faStar as IconProp;
  faExpand = faExpand as IconProp;
  faStarPainted = faStarPainted as IconProp;
  faTimes = faTimes as IconProp;
  faDownload = faDownload as IconProp;
  faUndo = faUndo as IconProp;

  constructor(
    private router: Router,
    private changeDetector: ChangeDetectorRef,
    private filtersService: FiltersService,
    private filterDefaultsService: FilterDefaultsService,
    private appMessagesService: AppMessagesService
  ) {}

  ngOnInit(): void {
    this.getGlobalFilters();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.config &&
      changes.config.currentValue &&
      !changes.config.firstChange
    ) {
      this.initializeComponent(() => {
        if (
          changes.config.currentValue?.componentName !==
            changes.config.previousValue?.componentName ||
          changes.config.currentValue?.forceApply
        ) {
          this.onApplyFilters();
        }
      });
    }
  }

  ngAfterViewInit(): void {
    if (this.timeframeDropdown) {
      this.subscription.add(
        this.timeframeDropdown.openChange.subscribe((x: boolean) => {
          if (x) {
            this.updatedTimeframeSelectedTabEvent.next(0);
          }
        })
      );
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  getGlobalFilters(): void {
    this.subscription.add(
      combineLatest([
        this.filtersService.globalFiltersChanged,
        this.filtersService.selectedFiltersChanged,
      ]).subscribe(([x, y]: [Filters, SelectedFilters]) => {
        this.filters = x;
        const customerChanged: boolean =
          this.selectedFilters?.customer.MasterCustomerNumber !==
          y.customer.MasterCustomerNumber;
        this.selectedFilters = y;
        this.getComponentFilters();

        if (!customerChanged) {
          this.initializeComponent(() => {
            this.onApplyFilters();
          });
        }

        this.loadingCompletedEvent.emit(false);
      })
    );

    this.subscription.add(
      this.filtersService.customerFiltersChanged.subscribe(
        (x: CustomerFilters) => {
          this.customerFilters = x;
          this.initializeComponent(() => {
            this.onApplyFilters();
          });
        }
      )
    );
  }

  initializeComponent(callback?: () => void): void {
    this.subscription.add(
      this.filterDefaultsService.filterDefaultsChanged.subscribe(
        (x: Array<FilterDefaults>) => {
          this.allFilterDefaults = x;
          const filtersFound: FilterDefaults = this.allFilterDefaults.find(
            (y: FilterDefaults) => y.componentName === this.config.componentName
          );

          if (filtersFound) {
            this.hasAppliedDefaults =
              this.filterDefaults?.customerNbr === filtersFound.customerNbr &&
              this.filterDefaults?.componentName === filtersFound.componentName;
            this.filterDefaults = filtersFound;
          } else {
            this.filterDefaults = null;
          }

          if (this.config.defaultFilters && !this.config.forceApply) {
            this.trendFilters = this.config.defaultFilters;
            this.trendFilters.timeframe = cloneDeep(
              this.selectedFilters.timeframe
            );
          } else if (this.config.forceApply) {
            this.setFilterDefaults();
            this.trendFilters.isPercentage = isUndefined(
              this.config.defaultFilters.isPercentage
            )
              ? this.trendFilters.isPercentage
              : this.config.defaultFilters.isPercentage;
          } else {
            this.setFilterDefaults();
          }

          this.validateFilterSelection();
          this.compareWithUserDefaultView();
          this.showMessages();

          if (callback) {
            callback();
            callback = null;
          }
        }
      )
    );

    this.subscription.add(
      this.filterDefaultsService.newFilterDefaultsAdded.subscribe(
        (x: FilterDefaults) => {
          const index: number = this.allFilterDefaults.findIndex(
            (y: FilterDefaults) => y.id === x.id
          );
          if (index >= 0) {
            this.allFilterDefaults.splice(index, 1);
          }
          this.allFilterDefaults.push(x);
        }
      )
    );
  }

  getComponentFilters(): void {
    this.getSubMetrics();
    this.getGroupsBy();
  }

  getSubMetrics(): void {
    this.subMetrics = TrendFiltersConstants.Filters.SubMetrics(
      this.selectedFilters
    );
  }

  getGroupsBy(): void {
    const isSpeculative: boolean = this.router.url.includes(
      AppModulePaths.speculative.path
    );

    this.groupsBy = cloneDeep(TrendFiltersConstants.Filters.GroupsBy);
    if (isSpeculative && this.selectedFilters.isPUdtls) {
      this.groupsBy.push(ProfitLVGroupBy);
    } else if (
      isSpeculative &&
      !this.selectedFilters.isPUdtls &&
      this.trendFilters.groupBy.value === ProfitLVGroupBy.value
    ) {
      this.trendFilters.groupBy = this.groupsBy[0];
    }

    if (
      isSpeculative &&
      !this.groupsBy.some(
        (x: TextValuePair) => x.value === GroupsBy.ReasonCode.id
      )
    ) {
      const params: Record<string, any> = {
        tooltip: 'The following breakdown applies to Speculative only',
        isSpecificFilter: true,
      };

      this.groupsBy.push(
        new TextValuePair({
          text: GroupsBy.ReasonCode.text,
          value: GroupsBy.ReasonCode.id,
          params,
        })
      );
    }
  }

  validateFilterSelection(): void {
    this.validateGroupBySelection();
    this.validatePeriodSelection();
    this.validateSubMetricSelection();
  }

  validateGroupBySelection(): void {
    const isFxAdjusted: boolean =
      this.selectedFilters.currency.Id === CustomCurrencies.FxAdjusted.id;
    const industryOption: TextValuePair = this.groupsBy.find(
      (x: TextValuePair) => x.value === GroupsBy.Industry.id
    );
    const reasonCodeOption: TextValuePair = this.groupsBy.find(
      (x: TextValuePair) => x.value === GroupsBy.ReasonCode.id
    );
    const defaultOption: TextValuePair = this.groupsBy.find(
      (x: TextValuePair) => x.value === GroupsBy.Composition.id
    );
    const isIndustryDisabled: boolean = isFxAdjusted;
    const isReasonCodeDisabled: boolean = isFxAdjusted;

    if (industryOption) {
      industryOption.params = industryOption.params ?? {};
      industryOption.params.isDisabled = (): boolean => {
        return isIndustryDisabled;
      };
    }

    if (reasonCodeOption) {
      reasonCodeOption.params = reasonCodeOption.params ?? {};
      reasonCodeOption.params.isDisabled = (): boolean => {
        return isReasonCodeDisabled;
      };
    }

    if (
      (isIndustryDisabled &&
        this.trendFilters.groupBy.value === GroupsBy.Industry.id) ||
      (isReasonCodeDisabled &&
        this.trendFilters.groupBy.value === GroupsBy.ReasonCode.id)
    ) {
      this.trendFilters.groupBy = defaultOption;
    }
  }

  validatePeriodSelection(): void {
    const isSingleMonth: boolean =
      this.selectedFilters.timeframe.code === TimePeriodCodes.CurrentMonth ||
      this.selectedFilters.timeframe.code === TimePeriodCodes.MonthToDate;
    const quarterlyOption: TextValuePair = this.periods.find(
      (x: TextValuePair) => x.value === Periods.Quarter.id
    );

    const isQuarterlyDisabled: boolean = isSingleMonth;

    if (quarterlyOption) {
      quarterlyOption.params = {
        isDisabled: (): boolean => {
          return isQuarterlyDisabled;
        },
      };
    }

    if (
      (isQuarterlyDisabled ||
        this.selectedFilters.timeframe.code ===
          TimePeriodCodes.TwelveRollingMonths) &&
      this.trendFilters.period.value === Periods.Quarter.id
    ) {
      this.trendFilters.period = this.periods.find(
        (x: TextValuePair) => x.value === Periods.Month.id
      );
    } else if (
      this.selectedFilters.timeframe.code ===
        TimePeriodCodes.FourRollingQuarters &&
      this.trendFilters.period.value === Periods.Month.id
    ) {
      this.trendFilters.period = this.periods.find(
        (x: TextValuePair) => x.value === Periods.Quarter.id
      );
    }
  }

  validateSubMetricSelection(): void {
    const isIndustryBreakdown: boolean =
      this.trendFilters.groupBy.value === GroupsBy.Industry.id;
    const isProfitBreakdown: boolean =
      this.trendFilters.groupBy.value === ProfitLVGroupBy.value;

    if (isProfitBreakdown) {
      this.subMetrics = ProfitSubMetric;
      this.trendFilters.subMetric = this.subMetrics[0];
    } else if (!isProfitBreakdown && this.subMetrics === ProfitSubMetric) {
      this.getSubMetrics();
      this.trendFilters.subMetric = this.subMetrics.find(
        (x: TextValuePair) => x.value === SubMetrics.Projection
      );
    }

    const planOption: TextValuePair = this.subMetrics.find(
      (x: TextValuePair) => x.value === SubMetrics.Plan
    );

    const isPlanDisabled: boolean = isIndustryBreakdown;

    if (planOption) {
      planOption.params = {
        isDisabled: (): boolean => {
          return isPlanDisabled;
        },
      };
    }

    if (
      isPlanDisabled &&
      this.trendFilters.groupBy.value === GroupsBy.Industry.id
    ) {
      this.trendFilters.subMetric = this.subMetrics.find(
        (x: TextValuePair) => x.value === SubMetrics.Projection
      );
    }
  }

  showMessages(): void {
    let message: AppMessage;

    switch (this.trendFilters.groupBy.value) {
      case GroupsBy.Industry.id:
        message = MessageTemplates.IndustryBreakdownWarning;
        break;
      case GroupsBy.WMU.id:
        message = MessageTemplates.WmuBreakdownWarning;
        break;
    }

    if (message) {
      this.appMessagesService.show(message, { centered: true });
    }
  }

  compareWithUserDefaultView(): void {
    if (this.filterDefaults) {
      this.filterDefaultsAndSelectionDiff =
        this.filterDefaults.getFieldValue('period') !==
          this.trendFilters.period.value ||
        this.filterDefaults.getFieldValue('isCumulative') !==
          this.trendFilters.isCumulative ||
        this.filterDefaults.getFieldValue('groupBy') !==
          this.trendFilters.groupBy.value ||
        this.filterDefaults.getFieldValue('showPercentage') !==
          this.trendFilters.isPercentage ||
        this.filterDefaults.getFieldValue('metricName') !==
          this.trendFilters.subMetric.value;
    } else {
      this.filterDefaultsAndSelectionDiff = false;
    }
  }

  setFilterDefaults(): void {
    if (this.filterDefaults && !this.hasAppliedDefaults) {
      this.trendFilters = new TrendFilters({
        groupBy: this.groupsBy.find(
          (x: TextValuePair) =>
            x.value === this.filterDefaults.getFieldValue('groupBy')
        ),
        isCumulative: this.filterDefaults.getFieldValue('isCumulative'),
        isPercentage:
          this.config.definition.allowPercentage &&
          this.filterDefaults.getFieldValue('showPercentage'),
        period: this.periods.find(
          (x: TextValuePair) =>
            x.value === this.filterDefaults.getFieldValue('period')
        ),
        subMetric: this.subMetrics.find(
          (x: TextValuePair) =>
            x.value === this.filterDefaults.getFieldValue('metricName')
        ),
        timeframe: this.selectedFilters.timeframe,
      });
      this.hasAppliedDefaults = true;
    } else if (!this.filterDefaults && !this.trendFilters) {
      this.trendFilters = new TrendFilters({
        groupBy: this.groupsBy[0],
        isCumulative: false,
        isPercentage: this.config.definition.allowPercentage,
        period: this.periods[0],
        subMetric: this.subMetrics[0],
        timeframe: this.selectedFilters.timeframe,
      });

      this.getSessionSettings();
    } else {
      this.trendFilters.timeframe = this.selectedFilters.timeframe;
    }
  }

  getCustomerNbr(callback: (x: string) => void): void {
    if (this.filterDefaults) {
      callback(this.filterDefaults.customerNbr);
    } else {
      const subscription = new Subscription();
      subscription.add(
        this.filtersService.selectedFiltersChanged.subscribe(
          (x: SelectedFilters) => {
            callback(x.customer.MasterCustomerNumber);
            subscription.unsubscribe();
          }
        )
      );
    }
  }

  isStarEnabled(): boolean {
    return !this.trendFilters?.groupBy?.params?.isSpecificFilter;
  }

  getSessionSettings(): void {
    const isPercentageString: string = StorageUtils.get(
      StorageType.SessionStorage,
      `trend-tile-percentage-${this.config.definition.type}`
    );
    this.trendFilters.isPercentage = isPercentageString
      ? isPercentageString === 'true'
      : this.config.definition.allowPercentage;
  }

  onSubMetricChanged(selected: TextValuePair): void {
    this.trendFilters.subMetric = selected;
    this.compareWithUserDefaultView();
    this.onApplyFilters();
  }

  onPeriodChanged(selected: TextValuePair): void {
    this.trendFilters.period = selected;
    this.compareWithUserDefaultView();
    this.onApplyFilters();
  }

  onGroupByChanged(selected: TextValuePair): void {
    this.trendFilters.groupBy = selected;
    this.compareWithUserDefaultView();
    this.onApplyFilters();
    this.showMessages();
  }

  onValueTypeSwitchChanged(isNumeric: boolean): void {
    this.trendFilters.isPercentage = !isNumeric;

    if (this.config.definition.allowPercentage) {
      StorageUtils.set(
        StorageType.SessionStorage,
        `trend-tile-percentage-${this.config.definition.type}`,
        this.trendFilters.isPercentage
      );
    }

    this.compareWithUserDefaultView();
    this.onApplyFilters();
  }

  onCumulativeSwitchChanged(isCumulative: boolean): void {
    this.trendFilters.isCumulative = isCumulative;
    this.compareWithUserDefaultView();
    this.onApplyFilters();
  }

  onExpandSelected(): void {
    this.expandSelectedEvent.emit();
  }

  onCloseClicked(): void {
    this.closeEvent.emit();
  }

  onTimeframeChanged(event: TimeframeChanged): void {
    if (this.timeframeDropdown) {
      this.timeframeDropdown.close();
    }

    this.trendFilters.timeframe = event.timeframe;

    if (event.forceReload) {
      this.filtersService.updateSelectedTimeframe(event.timeframe);
      this.filtersService.applyFilters();
    }

    this.changeDetector.detectChanges();
  }

  onFileExport(): void {
    const extraParams: Record<string, any> = {
      groupBy: [
        this.trendFilters.period.value,
        this.trendFilters.groupBy.value,
      ],
      fpp: this.trendFilters.subMetric.value,
      percentCurrency: this.trendFilters.isPercentage ? 'percent' : 'currency',
      isCumulative: this.trendFilters.isCumulative,
    };

    if (this.trendFilters.groupBy.value === ProfitLeverGroupBy.id) {
      extraParams[ProfitParams.isProfitUpActive] =
        this.selectedFilters.isPUdtls.toString();
      extraParams[ProfitParams.profitLeversApplied] =
        SpeculativeGridUtils.getProfitLeversExport(
          this.selectedFilters,
          this.filters
        );
    }

    this.exportFileEvent.emit(extraParams);
  }

  onSaveUserDefaultView(): void {
    if (this.filterDefaultsAndSelectionDiff || !this.filterDefaults) {
      this.loadingCompletedEvent.emit(false);
      const filterDefaults: FilterDefaults = this.filterDefaults
        ? cloneDeep(this.filterDefaults)
        : new FilterDefaults();

      this.getCustomerNbr((x: string) => {
        filterDefaults.componentName = this.config.componentName;
        filterDefaults.customerNbr = x;
        filterDefaults.setFieldValue(
          'metricName',
          this.trendFilters.subMetric.value
        );
        filterDefaults.setFieldValue('period', this.trendFilters.period.value);
        filterDefaults.setFieldValue(
          'isCumulative',
          this.trendFilters.isCumulative
        );
        filterDefaults.setFieldValue(
          'groupBy',
          this.trendFilters.groupBy.value
        );
        filterDefaults.setFieldValue(
          'showPercentage',
          this.config.definition.allowPercentage &&
            this.trendFilters.isPercentage
        );

        this.filterDefaultsService
          .save(filterDefaults)
          .then((x: FilterDefaultsSaveResponse) => {
            if (x.isSucessfull) {
              this.filterDefaultsAndSelectionDiff = false;
              this.filterDefaults = x.filterDefaults;
            }
            this.loadingCompletedEvent.emit(true);
          });
      });
    }
  }

  onApplyFilters(): void {
    this.filtersSelectionChangedEvent.emit(
      new TrendFiltersChanged({
        filters: this.filters,
        selectedFilters: this.selectedFilters,
        trendFilters: this.trendFilters,
        customerFilters: this.customerFilters,
      })
    );
  }

  onReset(): void {
    this.resetEvent.emit();
  }
}
