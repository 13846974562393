import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { Endpoints } from '../constants/endpoints.constants';
import { MrdrPerson } from './entities/filters/mrdr-person';

@Injectable({
  providedIn: 'root',
})
export class MrdrService {
  constructor(private http: HttpClient) {}

  getEnterpriseIds(name: string): Observable<string[]> {
    const body: Record<string, any> = {
      enterpriseID: name,
    };

    if (name && name !== '') {
      return this.http
        .post<{ value: Array<MrdrPerson> }>(Endpoints.peoplePicker, body)
        .pipe(
          map((x: any) => {
            return x.value.map((p: MrdrPerson) => p.EnterpriseID);
          })
        );
    } else {
      return of([]);
    }
  }

  getPeopleKeys(name: string): Observable<string[]> {
    const body: Record<string, any> = {
      enterpriseID: name,
    };
    if (name && name !== '') {
      return this.http
        .post<{ value: Array<MrdrPerson> }>(Endpoints.peoplePicker, body)
        .pipe(
          map((x: any) => {
            return x.value.map((p: MrdrPerson) => p.PeopleKey.toString());
          })
        );
    } else {
      return of([]);
    }
  }

  getPerson(name: string): Promise<MrdrPerson> {
    const body: Record<string, any> = {
      enterpriseID: name,
    };
    if (name && name !== '') {
      return this.http
        .post<{ value: Array<MrdrPerson> }>(Endpoints.peoplePicker, body)
        .pipe(
          switchMap((x: { value: Array<MrdrPerson> }) => {
            return x.value?.length > 0
              ? of(
                  x.value[
                    x.value.findIndex((person) => person.EnterpriseID == name)
                  ]
                )
              : of(new MrdrPerson());
          })
        )
        .toPromise();
    } else {
      return of(new MrdrPerson()).toPromise();
    }
  }
}
