import { assign, cloneDeep } from 'lodash';

import { SelectedFilters } from 'src/app/shared/services/entities/filters/selected-filters';
import { CustomerFilters } from 'src/app/shared/services/entities/filters/customer-filters';
import { Filters } from 'src/app/shared/services/entities/filters/filters';
import { FilterSelection } from './filter-selection';
import {
  Alliance,
  Function,
  GrowthPriority,
} from 'src/app/shared/services/entities/filters/product-hierarchy';
import { ClientGroup } from 'src/app/shared/services/entities/filters/client-group';
import { Industry } from 'src/app/shared/services/entities/filters/industry';
import { Location } from 'src/app/shared/services/entities/filters/location';
import { ServiceGroupValues } from 'src/app/shared/services/entities/filters/service-group';
import { FinancialCustomer } from 'src/app/shared/services/entities/filters/financial-customer';
import { Wmu } from 'src/app/shared/services/entities/filters/wmu';
import { ProfitLevers } from 'src/app/shared/services/entities/filters/profitUplift';

class FiltersManagerSchema {
  wmu: FilterSelection;
  fc: FilterSelection;
  market: FilterSelection;
  cg: FilterSelection;
  sg: FilterSelection;
  industry: FilterSelection;
  functions: FilterSelection;
  gp: FilterSelection;
  alliances: FilterSelection;
  profitLevers: FilterSelection;
}

export class FiltersManager extends FiltersManagerSchema {
  constructor(filterAttributes?: FiltersManagerSchema) {
    super();
    assign(this, filterAttributes);
  }

  hasChanged(selectedFilters: SelectedFilters): boolean {
    let hasChanged = false;

    hasChanged = this.alliances?.hasChanged(
      selectedFilters?.alliances,
      'ProductId'
    );
    hasChanged =
      hasChanged ||
      this.functions?.hasChanged(selectedFilters?.functions, 'ProductId');
    hasChanged =
      hasChanged ||
      this.gp?.hasChanged(selectedFilters?.growthPriorities, 'ProductId');
    hasChanged =
      hasChanged || this.wmu?.hasChanged(selectedFilters?.wmus, 'ID');
    hasChanged =
      hasChanged ||
      this.sg?.hasChanged(selectedFilters?.serviceGroups, 'ServiceGroupCd');
    hasChanged =
      hasChanged ||
      this.market?.hasChanged(selectedFilters?.markets, 'OrganizationId');
    hasChanged =
      hasChanged ||
      this.industry?.hasChanged(selectedFilters?.industries, 'EntityCode');
    hasChanged =
      hasChanged ||
      this.fc?.hasChanged(
        selectedFilters?.financialCustomers,
        'CustomerNumber'
      );
    hasChanged =
      hasChanged ||
      this.cg?.hasChanged(selectedFilters?.clientGroups, 'ClientGroupCd');
    hasChanged =
      hasChanged ||
      this.profitLevers?.hasChanged(
        selectedFilters?.profitLevers,
        'ProfitLeverId'
      );

    return hasChanged;
  }

  applySelection(
    selectedFilters: SelectedFilters,
    filters: Filters,
    customerFilters: CustomerFilters
  ): SelectedFilters {
    // Global Filters
    const selectedCopy: SelectedFilters = cloneDeep(selectedFilters);
    selectedCopy.alliances = filters.alliances.filter((x: Alliance) =>
      this.alliances?.getKeys().includes(x.ProductId)
    );
    selectedCopy.functions = filters.functions.filter((x: Function) =>
      this.functions?.getKeys().includes(x.ProductId)
    );
    selectedCopy.growthPriorities = filters.sgi.filter((x: GrowthPriority) =>
      this.gp?.getKeys().includes(x.ProductId)
    );
    selectedCopy.industries = filters.industry.filter((x: Industry) =>
      this.industry?.getKeys().includes(x.EntityCode)
    );
    selectedCopy.markets = filters.locations.filter((x: Location) =>
      this.market?.getKeys().includes(x.OrganizationId)
    );
    selectedCopy.serviceGroups = filters.serviceGroups.ServiceGroups.filter(
      (x: ServiceGroupValues) => this.sg?.getKeys().includes(x.ServiceGroupCd)
    );
    // Customer Filters
    selectedCopy.clientGroups = customerFilters.csg.filter((x: ClientGroup) =>
      this.cg?.getKeys().includes(x.ClientGroupCd)
    );
    selectedCopy.financialCustomers = customerFilters.financialCustomer.filter(
      (x: FinancialCustomer) => this.fc?.getKeys().includes(x.CustomerNumber)
    );
    selectedCopy.wmus = customerFilters.wmu.filter((x: Wmu) =>
      this.wmu?.getKeys().includes(x.ID)
    );

    return selectedCopy;
  }

  applySelectionPF(
    selectedFilters: SelectedFilters,
    filters: Filters
  ): SelectedFilters {
    const selectedCopy: SelectedFilters = cloneDeep(selectedFilters);

    selectedCopy.profitLevers = filters.profitLevers.filter((x: ProfitLevers) =>
      this.profitLevers?.getKeys().includes(x.ProfitLeverId)
    );

    return selectedCopy;
  }
}
