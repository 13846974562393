import { ChangeDetectorRef, Component, OnDestroy, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';

import { MetricType } from 'src/app/shared/constants/metrics.constants';
import { RequestPayload } from 'src/app/shared/services/entities/request-payload';
import { SelectedFilters } from 'src/app/shared/services/entities/filters/selected-filters';
import { Tile } from '../entities/tile';
import { ValueType } from 'src/app/shared/constants/common.constants';
import { TileItem } from '../entities/tile-item';
import { TileItemDefinition } from '../entities/tile-definition';
import { ProjectionTileErrorMessages } from './constants/projection-tile.constants';
import { StorageUtils } from 'src/app/core/utils/storage.utils';
import { StorageType } from 'src/app/core/constants/storage.constants';

import { ProjectionTileService } from 'src/app/shared/services/tiles/projection-tile.service';
import { FiltersService } from 'src/app/shared/services/filters.service';
import { ErrorHandlerService } from 'src/app/core/services/error-handler.service';
import { BetaMessageService } from 'src/app/shared/services/beta-message.service';
import { AppState } from 'src/app/shared/services/entities/app-state/app-state';
import { AppStateService } from 'src/app/shared/services/app-state.service';
import { Filters } from 'src/app/shared/services/entities/filters/filters';

@Component({
  selector: 'app-projection-tile',
  template: '',
})
export class ProjectionTileComponent implements OnDestroy {
  @Input() isCloud: boolean;
  subscription = new Subscription();

  loaded: boolean;
  hasErrors: boolean;
  errorMessage: string;
  type: MetricType;
  selectedFilters: SelectedFilters;
  filters: Filters;
  projectionTile: Tile;
  isPercentage = false;
  allowPercentage = false;
  isActuals = false;
  tmpTitle: string;
  tmpItems: Array<string>;
  betaMessage: BetaMessageService;
  appStateService: AppStateService;
  isFlipFlopFlagEnabled: boolean;
  isBetaTile = false;

  constructor(
    private baseProjectionTileService: ProjectionTileService,
    private basefilterService: FiltersService,
    private baseErrorHandlerService: ErrorHandlerService,
    private baseChangeDetector: ChangeDetectorRef,
    private baseRouter: Router,
    type: MetricType,
    betaMessage: BetaMessageService,
    appStateService: AppStateService
  ) {
    this.type = type;
    this.betaMessage = betaMessage;
    this.appStateService = appStateService;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  initializeComponent(callback: (x: RequestPayload) => void): void {
    this.getSessionSettings();

    this.subscription.add(
      this.appStateService.appStateChanged.subscribe((appState) => {
        this.isFlipFlopFlagEnabled = appState.gcpFlipFlopFlagEnabled;
      })
    );

    this.subscription.add(
      this.basefilterService.selectedFiltersChanged.subscribe(
        (x: SelectedFilters) => {
          this.selectedFilters = x;
          this.isActuals = x.timeframe.isPast;
          callback(RequestPayload.createRequest(x));
        }
      )
    );

    this.subscription.add(
      this.basefilterService.globalFiltersChanged.subscribe((filters) => {
        this.filters = filters;
      })
    );
  }

  loadStarted(): void {
    this.loaded = false;
    this.baseChangeDetector.detectChanges();
  }

  loadCompleted(): void {
    this.loaded = true;
    this.baseChangeDetector.detectChanges();
  }

  handleTileError(error: HttpErrorResponse): void {
    this.hasErrors = true;

    switch (error.status as any) {
      case 504:
        this.errorMessage = ProjectionTileErrorMessages.Service504;
        break;
      case 'WMU-FC':
        this.errorMessage = ProjectionTileErrorMessages.WmuFcFilter;
        break;
      default:
        this.errorMessage = ProjectionTileErrorMessages.ServiceDefault;
        break;
    }

    this.baseErrorHandlerService.handleError(error);
    this.baseChangeDetector.detectChanges();
  }

  getTotal(): TileItem {
    const valueType: ValueType = this.isPercentage
      ? ValueType.Percentage
      : ValueType.Numeric;
    return this.projectionTile ? this.projectionTile.getTotal(valueType) : null;
  }

  getItems(): Array<TileItem> {
    const valueType: ValueType = this.isPercentage
      ? ValueType.Percentage
      : ValueType.Numeric;
    return this.projectionTile ? this.projectionTile.getItems(valueType) : [];
  }

  getSessionSettings(): void {
    const isPercentageString: string = StorageUtils.get(
      StorageType.SessionStorage,
      `projection-tile-percentage-${this.type}`
    );
    this.isPercentage = isPercentageString
      ? isPercentageString === 'true'
      : this.allowPercentage;
  }

  setTempTitle(title: string): void {
    this.tmpTitle = title;
  }

  setTempItems(items: Array<TileItemDefinition>): void {
    this.tmpItems = items
      .filter((x: TileItemDefinition) => !x.isTotal)
      .map((x: TileItemDefinition) => x.title);
  }

  clearTile(): void {
    this.projectionTile = null;
    this.hasErrors = false;
    this.errorMessage = '';
  }

  onValueTypeSwitchChanged(isNumeric: boolean): void {
    this.isPercentage = !isNumeric;

    if (this.allowPercentage) {
      StorageUtils.set(
        StorageType.SessionStorage,
        `projection-tile-percentage-${this.type}`,
        this.isPercentage
      );
    }
  }

  onLinkClicked(item: TileItem): void {
    if (item.link) {
      this.baseRouter.navigate([item.link], { queryParams: item.linkParams });
    }
  }
}
