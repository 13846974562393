import { cloneDeep } from 'lodash';

import { Periods } from 'src/app/shared/constants/filters.constants';
import {
  CustomOptions,
  GridCellItemAlign,
  ViewOptions,
} from 'src/app/shared/constants/grid.constants';
import { TextValuePair } from 'src/app/shared/services/entities/common/key-value';
import { SelectedFilters } from 'src/app/shared/services/entities/filters/selected-filters';
import { ColDef, CsvExportParams, GridOptions } from 'ag-grid-community';
import {
  GridToolbarConfig,
  GridToolbarDropdown,
} from 'src/app/shared/components/base/grid/components/grid-toolbar/entities/grid-toolbar-config';
import { TimeframeItem } from 'src/app/shared/components/timeframe/entities/timeframe';
import { GridUtils } from 'src/app/shared/components/base/grid/utils/grid.utils';
import { Filters } from 'src/app/shared/services/entities/filters/filters';
import { SortColum } from 'src/app/shared/components/base/grid/entities/grid-config';

export const ComponentNames = {
  actualSales: 'actualSales',
  periods: 'period',
  targets: 'target',
  export: 'export',
  exportMenu: 'exportMenu',
  expand: 'expand',
  compress: 'compress',
  noActivity: 'noActivity',
};

export const GridFilters = {
  Periods: [
    new TextValuePair({
      text: Periods.Quarter.text,
      value: Periods.Quarter.id,
    }),
    new TextValuePair({
      text: Periods.Month.text,
      value: Periods.Month.id,
    }),
  ],
  TargetOptions: (selectedFilters: SelectedFilters) => {
    return [
      new TextValuePair({
        text: 'Projection',
        value: ViewOptions.Current,
      }),
      new TextValuePair({
        text: selectedFilters.projection.getText(),
        value: ViewOptions.Projection,
      }),
      new TextValuePair({
        text: `Projection vs ${selectedFilters.projection.getText()} Variance`,
        value: ViewOptions.CompareProjection,
      }),
    ];
  },
};

export const GridColDefs = {
  getColDefs: (
    toolbarConfig: GridToolbarConfig,
    filters: Filters,
    selectedFilters: SelectedFilters
  ): Array<ColDef> => {
    let columnDefs: Array<ColDef> = [];

    const periodsDropdown: GridToolbarDropdown = toolbarConfig.findControl(
      ComponentNames.periods
    );

    const isCustomTimeframe: boolean =
      selectedFilters.timeframe.title === 'Custom';

    const timeframe: TimeframeItem = cloneDeep(selectedFilters.timeframe);
    // timeframe.end = customTimeframe ? timeframe.end : timeframe.start + 11;

    if (periodsDropdown.selected.value === Periods.Quarter.id) {
      columnDefs = columnDefs.concat(
        GridColDefs.Shared.addCustomRendererConfigs(
          GridUtils.getQuarterlyHeaders(
            timeframe,
            filters.dates,
            isCustomTimeframe
          )
        )
      );
    } else {
      columnDefs = columnDefs.concat(
        GridColDefs.Shared.addCustomRendererConfigs(
          GridUtils.getMonthlyHeaders(timeframe, filters.dates)
        )
      );
    }

    return GridColDefs.Shared.getBaseGridColDefs().concat(columnDefs);
  },
  Shared: {
    getBaseGridColDefs: (): Array<ColDef> => {
      return [
        {
          headerName: 'CONTRACT ID',
          colId: 'contractId',
          field: 'ContractLink',
          type: 'linkColumn',
          valueGetter: (params: any) => {
            return params.data.ContractId === '2'
              ? CustomOptions.NotAssigned.text
              : params.data.ContractId;
          },
          pinned: true,
          width: 100,
          minWidth: 100,
          sortable: true,
          resizable: true,
          sort: GridColDefs.Shared.paramSort('contractId'),
          pinnedRowCellRendererParams: { forceTotals: true },
          comparator: (valueA: string, valueB: string): number => {
            return valueA.localeCompare(valueB, 'en-US', { numeric: true });
          },
        },
        {
          headerName: 'CONTRACT NAME',
          colId: 'contractName',
          field: 'ContractLink',
          type: 'linkColumn',
          valueGetter: (params: any) => {
            if (params.data.ContractName) {
              return params.data.ContractName.includes(
                `${params.data.ContractId}-`
              )
                ? params.data.ContractName.replace(
                    `${params.data.ContractId}-`,
                    ''
                  )
                : params.data.ContractName.includes(params.data.ContractId)
                ? params.data.ContractName.replace(params.data.ContractId, '')
                : params.data.ContractName === CustomOptions.NotAssigned.text
                ? '-'
                : params.data.ContractName;
            }
          },
          pinned: true,
          width: 175,
          minWidth: 175,
          sortable: true,
          resizable: true,
          sort: GridColDefs.Shared.paramSort('contractName'),
        },
        {
          headerName: 'ACTUALS',
          colId: 'Actuals',
          field: 'ActualsSales',
          type: 'numberColumn',
          sortable: true,
          resizable: true,
          sort: GridColDefs.Shared.paramSort('Actuals'),
          aggFunc: 'sum',
          pinned: true,
          width: 200,
          minWidth: 100,
          pinnedRowCellRendererParams: { align: GridCellItemAlign.Right },
        },
      ] as Array<ColDef>;
    },
    addCustomRendererConfigs: (periodColDefs: Array<any>): Array<ColDef> => {
      periodColDefs = periodColDefs.map((x: ColDef) => ({
        ...x,
        colId: x.field,
        minWidth: 65,
        field: `${x.field}.Sales`,
        sortable: true,
        resizable: true,
        sort: GridColDefs.Shared.paramSort(x.field),
        aggFunc: 'sum',
        type: 'numberColumn',
      }));

      return periodColDefs;
    },
    paramSort: (colId: string): string => {
      const sortColumsConfig: SortColum[] = [
        { colId: 'Actuals', sort: 'desc' },
      ];
      const sortColum = sortColumsConfig.find(
        (x: SortColum) => x.colId === colId
      );
      return sortColum ? sortColum.sort : '';
    },
  },
  getExtraGridOptions: (): GridOptions => {
    return {
      defaultCsvExportParams: {
        sheetName: 'Opportunities',
        fileName: 'CBP Opportunities',
      } as CsvExportParams,
    } as GridOptions;
  },
};
