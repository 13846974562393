import { assign } from 'lodash';

import { MetricType } from 'src/app/shared/constants/metrics.constants';

// Type Changed
class ProfitUpliftModalTypeChangedSchema {
  tileParentId: string;
  type: MetricType;
  reloadRequired?: boolean = true;
  callback?: () => void;
}

export class ProfitUpliftModalTypeChanged extends ProfitUpliftModalTypeChangedSchema {
  constructor(filterAttributes?: ProfitUpliftModalTypeChangedSchema) {
    super();
    assign(this, filterAttributes);
  }
}
