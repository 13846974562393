<div *ngIf="values?.length > 0" class="container mb-2">
    <div class="row">
        <div *ngFor="let value of values" class="col-6">
            <div class="check-value">
                <input class="checker-input" type="checkbox" [id]="value.id" [(ngModel)]="value.checked"
                    (ngModelChange)="onInternalValueSelection()">
                <label class="checker-label" [for]="value.id">
                    {{value.text}}
                </label>
            </div>
        </div>
    </div>
</div>