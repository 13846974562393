<div *ngIf="config" class="trend-filters">
    <div *ngIf="config.resettable" class="trend-filter">
        <button class="btn btn-link icon-button reset-icon" (click)="onReset()">
            <fa-icon [icon]="faUndo">
            </fa-icon>
            <span>RESET CHART</span>
        </button>
    </div>
    <div *ngIf="config.exportable" class="trend-filter">
        <button class="btn btn-link icon-button export-icon" (click)="onFileExport()">
            <fa-icon [icon]="faDownload">
            </fa-icon>
            <span>EXPORT</span>
        </button>
    </div>
    <div class="trend-filter">
        <button class="btn btn-link icon-button star-icon" ngbTooltip="Save View as User Default"
            container="body"
            (click)="onSaveUserDefaultView()" [disabled]="!isStarEnabled()">
            <fa-icon [icon]="filterDefaults && !filterDefaultsAndSelectionDiff ? faStarPainted : faStar">
            </fa-icon>
        </button>
    </div>
    <div *ngIf="config.expandable" class="trend-filter">
        <button class="btn btn-link icon-button expand-icon" (click)="onExpandSelected()">
            <span>
                Expand
                <fa-icon [icon]="faExpand">
                </fa-icon>
            </span>
        </button>
    </div>
    <div *ngIf="config.showTimeframePicker" class="trend-filter">
        <div ngbDropdown #timeframeDropdown="ngbDropdown" class="ddl-control d-inline-block" container="body"
            display="dynamic">
            <button class="btn btn-ddl-control btn-ddl-timeframe" ngbDropdownToggle>
                {{trendFilters?.timeframe?.getFullName()}}
            </button>
            <div ngbDropdownMenu class="ddl-control-menu">
                <app-timeframe (selectionChangeEvent)="onTimeframeChanged($event)"
                    [onUpdateSelectedTabObs]="updatedTimeframeSelectedTabEvent.asObservable()"></app-timeframe>
            </div>
        </div>
    </div>
    <div *ngIf="config.definition.allowPercentage" class="trend-filter" style="margin-left: 3px; margin-top: 0.3rem !important;">
        <app-switch (switchChangeEvent)="onValueTypeSwitchChanged($event)" [checked]="!trendFilters?.isPercentage">
        </app-switch>
    </div>
    <div *ngIf="config.showSubMetrics" class="trend-filter">
        <app-drop-down [items]="subMetrics" [selectedItem]="trendFilters?.subMetric"
            (itemSelectionChangeEvent)="onSubMetricChanged($event)" [type]="dropDownType.Link"></app-drop-down>
    </div>
    <div class="trend-filter">
        <span *ngIf="trendFilters?.groupBy?.params?.isSpecificFilter" class="special-filter"
            [ngbTooltip]="trendFilters?.groupBy?.params?.tooltip" placement="bottom" container="body"></span>
        <app-drop-down [items]="groupsBy" [selectedItem]="trendFilters?.groupBy"
            (itemSelectionChangeEvent)="onGroupByChanged($event)" [type]="dropDownType.Link"></app-drop-down>
    </div>
    <div class="trend-filter">
        <app-drop-down [items]="periods" [selectedItem]="trendFilters?.period"
            (itemSelectionChangeEvent)="onPeriodChanged($event)" [type]="dropDownType.Link"></app-drop-down>
    </div>
    <div class="trend-filter" style="margin-left: 3px; margin-top: 0.3rem !important;">
        <app-switch (switchChangeEvent)="onCumulativeSwitchChanged($event)" leftText="Cumulative" rightText=""
            [checked]="trendFilters?.isCumulative">
        </app-switch>
    </div>
    <div *ngIf="config.closable" class="trend-filter">
        <button class="btn btn-link icon-button close-icon" (click)="onCloseClicked()">
            <span>
                Close
                <fa-icon [icon]="faTimes">
                </fa-icon>
            </span>
        </button>
    </div>
</div>
