import {
  ChartData,
  ChartDataset,
  ChartOptions,
  TooltipLabelStyle,
  TooltipModel,
  LegendItem,
  FontSpec,
} from 'chart.js';
import { toFont } from 'chart.js/helpers';
import {
  cloneDeep,
  every,
  max,
  min,
  orderBy,
  reduce,
  remove,
  sortBy,
  sum,
  sumBy,
  toPairsIn,
  uniq,
  zipWith,
} from 'lodash';
import {
  GroupsBy,
  ProfitLeverGroupBy,
  SubMetrics,
} from 'src/app/shared/constants/filters.constants';
import { MetricType } from 'src/app/shared/constants/metrics.constants';
import { ValueType } from 'src/app/shared/constants/common.constants';
import { CustomNumberPipe } from 'src/app/shared/pipes/custom-number.pipe';
import { TextValuePair } from 'src/app/shared/services/entities/common/key-value';
import { MmbDate } from 'src/app/shared/services/entities/filters/date';
import { SelectedFilters } from 'src/app/shared/services/entities/filters/selected-filters';
import {
  LabeledData,
  TrendChart,
  TrendLineLabeledData,
  TrendLines,
  TrendStack,
} from '../entities/trend-chart-data';
import { TrendTileDefinition } from '../entities/trend-tile-definition';
import {
  TrendTileResponse,
  TrendTileResponseData,
  TrendTileResponseSubMetric,
  TrendTileResponseValue,
} from '../entities/trend-tile-response';
import { TrendChartConfig } from '../trend-tile/components/trend-chart/entities/trend-chart-config';
import { TrendFilters } from '../trend-tile/components/trend-filters/entities/trend-filters';
import {
  TrendColoursConstants,
  TrendSubMetricsConfigConstants,
} from '../trend-tile/constants/trend-tile.constants';
import { TrendUtils } from './trend.utils';

export class TrendChartUtils {
  static getStackData(
    definition: TrendTileDefinition,
    source: TrendTileResponse,
    trendFilters: TrendFilters,
    dates: Array<MmbDate>
  ): TrendStack {
    const stackData: TrendTileResponseSubMetric = cloneDeep(source.Current);

    let items: Array<LabeledData> = [];

    stackData.totalsByPeriods = sortBy(stackData.totalsByPeriods, 'name');

    const periods: Array<string> = stackData.totalsByPeriods.map(
      (x: TrendTileResponseValue) => x.name
    );

    const periodLabels: Array<any> =
      TrendUtils.transformLabels<TrendTileResponseValue>(
        stackData.totalsByPeriods,
        'name',
        trendFilters,
        dates
      ).map((x: TrendTileResponseValue) => x.name);

    const totalsData: Array<number> = stackData.totalsByPeriods.map(
      (x: TrendTileResponseValue) =>
        trendFilters.isPercentage
          ? x.value * 100 === 0
            ? null
            : x.value * 100
          : x.value
    );

    const totals = new LabeledData({
      data: totalsData,
      label: `${MetricType[definition.type].toUpperCase()}${
        trendFilters.isPercentage ? '%' : ''
      }`,
    });

    if (trendFilters.groupBy.value === GroupsBy.Composition.id) {
      stackData.data = TrendUtils.transformLabels<TrendTileResponseData>(
        stackData.data,
        'GroupKey',
        trendFilters,
        dates
      );

      stackData.data.forEach((x: TrendTileResponseData) => {
        x.data.forEach((y: TrendTileResponseValue) => {
          const exists: LabeledData = items.find(
            (i: LabeledData) => i.label === y.name
          );
          const newValue: number =
            trendFilters.isPercentage && TrendUtils.needsMultiply(y.name)
              ? y.value * 100 === 0
                ? null
                : y.value * 100
              : y.value;

          if (exists) {
            exists.data.push(newValue);
          } else {
            items.push(
              new LabeledData({
                label: y.name,
                data: [newValue],
              })
            );
          }
        });
      });
    } else {
      uniq(stackData.data.map((x: TrendTileResponseData) => x.GroupKey)).map(
        (x: string) => {
          const values: Array<number> = periods.map((y: string) => {
            const response: TrendTileResponseData = stackData.data.find(
              (z: TrendTileResponseData) =>
                z.GroupKey === x && z.ParentGroupKey === y && z.data?.length > 0
            );
            return response?.data.find((z: TrendTileResponseValue) =>
              z.name.includes(
                TrendUtils.getSubMetricKpiKey(
                  definition.type,
                  trendFilters.isPercentage
                )
              )
            )?.value;
          });

          items.push(
            new LabeledData({
              label: x.split('---')[0],
              data: values,
            })
          );
        }
      );

      // Generates 'Other' label
      if (items.length > 6) {
        items = this.generateOtherLabel(items);
      }
    }

    // set temp labels so that grid doesnt break if there are no labels
    if (items.length === 0) {
      items.push(
        new LabeledData({
          label: 'metrics',
          data: [0],
        })
      );
    }

    // Set 0 instead of null
    items.forEach((x: LabeledData) => {
      x.data = x.data.map((y: number) => y || 0);
    });

    // Sort by element totals
    items = sortBy(items, (x: LabeledData) => {
      return sum(x.data);
    }).reverse();

    // ProfitLever US, 'Other' at the end of items
    if (
      trendFilters.groupBy.text === ProfitLeverGroupBy.text &&
      items.some((x) => x.label === 'Other')
    ) {
      const otherPosition = items.findIndex((x) => x.label === 'Other');
      items.push(items.splice(otherPosition, 1)[0]);
    }

    return new TrendStack({
      items: items,
      periods: periodLabels,
      totals: totals,
    });
  }

  static getLinesData(
    definition: TrendTileDefinition,
    source: TrendTileResponse,
    trendFilters: TrendFilters,
    dates: Array<MmbDate>,
    selectedFilters: SelectedFilters
  ): TrendLines {
    const targetLinesData: Array<[string, TrendTileResponseSubMetric]> =
      toPairsIn(cloneDeep(source)).filter(
        (x: [string, TrendTileResponseSubMetric]) =>
          x[0] !== TrendSubMetricsConfigConstants.Current.text &&
          x[0] !== TrendSubMetricsConfigConstants.Forecast.text
      );

    const items: Array<TrendLineLabeledData> = [];
    let periods: Array<string>;

    targetLinesData.forEach((x: [string, TrendTileResponseSubMetric]) => {
      const target: string = x[0];
      const borderDash: [number, number] = (
        TrendSubMetricsConfigConstants as any
      )[target].borderDash;

      x[1].totalsByPeriods = TrendUtils.transformLabels<TrendTileResponseValue>(
        x[1].totalsByPeriods,
        'name',
        trendFilters,
        dates
      );

      periods =
        periods ??
        x[1].totalsByPeriods.map((y: TrendTileResponseValue) => y.name);

      const data: Array<number> = x[1].totalsByPeriods.map(
        (y: TrendTileResponseValue) =>
          TrendUtils.transformData(
            target,
            y.value,
            definition.type,
            trendFilters,
            selectedFilters
          )
      );

      items.push(
        new TrendLineLabeledData({
          data: data,
          label: this.getSubMetricLabel(selectedFilters, target),
          borderDash: borderDash,
        })
      );
    });

    return new TrendLines({
      items,
      periods,
    });
  }

  static getChartOptions(
    trendChart: TrendChart,
    config: TrendChartConfig
  ): ChartOptions {
    const options: ChartOptions = {
      plugins: {
        maintainAspectRatio: false,
        responsive: false,
        legend: {
          display: false,
          position: 'right',
          labels: {
            boxWidth: 25,
            fontSize: 10,
            filter: (legendItem: LegendItem, data: ChartData) => {
              const dataSets: ChartDataset = data.datasets.find(
                (x: ChartDataset) => x.label === legendItem.text
              );
              return sumBy(dataSets.data as Array<number>) !== 0;
            },
          },
        },
        tooltip: {
          mode: 'index',
          position: 'nearest',
          intersect: false,
          backgroundColor: '#fff',
          bodyFontColor: '#000',
          cornerRadius: 0,
          titleSpacing: 0,
          titleMarginBottom: 0,
          enabled: false,
          external: function (model) {
            TrendChartUtils.createTooltip(
              model?.chart,
              model?.tooltip as TooltipModel<any>,
              trendChart,
              config
            );
          },
        },
        scales: {
          x: [
            {
              stacked: true,
              ticks: {
                beginAtZero: true,
              },
            },
          ],
          y: TrendChartUtils.getYAxisConfig(trendChart, config),
        },
      },
    } as ChartOptions;

    if (config.isExpanded) {
      options['zoom'] = {
        enabled: true,
        mode: 'y',
        drag: false,
        speed: 0.1,
        // onZoom: (chart: Chart) => {
        //   console.log(`I am zooming!!!`);
        // },
        // onZoomComplete: (chart: Chart) => {
        //   console.log(`I was zoomed!!!`);
        // },
      };

      options['pan'] = {
        enabled: true,
        mode: 'y',
        // onPan: (chart: Chart) => {
        //   console.log(`I am panning!!!`);
        // },
        // onPanComplete: (chart: Chart) => {
        //   console.log(`I was Panned!!!`);
        // },
      };
    }

    return options;
  }

  static getLinesOptions(): ChartDataset<'line'> {
    return {
      hoverBackgroundColor: '#000',
      backgroundColor: '#000',
      type: 'line',
      borderColor: '#000',
      hoverBorderColor: '#000',
      borderCapStyle: 'butt',
      fill: false,
      //lineTension: 0,
      borderWidth: 2,
      data: [],
    };
  }

  static getColoursForStackBars(
    stackData: Array<LabeledData>,
    trendFilters: TrendFilters
  ): Array<TooltipLabelStyle> {
    if (
      this.existDefinedColoursForAll(stackData) &&
      TrendColoursConstants.GeneratedGroups.includes(trendFilters.groupBy.value)
    ) {
      return this.useDefinedColours(stackData);
    }

    return this.generateColours(stackData, trendFilters.isPercentage);
  }

  static clearTooltips(): void {
    document
      .querySelectorAll('.chartjs-tooltip')
      .forEach((x: any) => x.remove());
  }

  private static getSubMetricLabel(
    selectedFilters: SelectedFilters,
    target: string
  ): string {
    switch (target) {
      case SubMetrics.Projection:
        return selectedFilters.projection.getText();
      case SubMetrics.Plan:
        return target.toUpperCase();
      default:
        return target;
    }
  }

  private static generateColours(
    stackData: Array<LabeledData>,
    isPercentage: boolean
  ): Array<TooltipLabelStyle> {
    let count = 0;
    const colours: Array<TooltipLabelStyle> = [];

    stackData.forEach((x: LabeledData) => {
      if ((isPercentage && x.label === 'CCI%') || x.label === 'Other') {
        const colour: TextValuePair = TrendColoursConstants.Defined.find(
          (y: TextValuePair) => y.value === x.label
        );
        if (colour) {
          colours.push({
            borderColor: colour.text,
            backgroundColor: colour.text,
          });
        }
      } else {
        while (
          colours.findIndex(
            (i) =>
              i.backgroundColor === TrendColoursConstants.Random[count]?.text
          ) !== -1
        ) {
          count++;
        }
        colours.push({
          borderColor: TrendColoursConstants.Random[count]?.text,
          backgroundColor: TrendColoursConstants.Random[count]?.text,
        });
      }
    });

    return colours;
  }

  private static useDefinedColours(
    stackData: Array<LabeledData>
  ): Array<TooltipLabelStyle> {
    const colours: Array<TooltipLabelStyle> = [];

    stackData.forEach((x: LabeledData) => {
      const colour: TextValuePair = TrendColoursConstants.Defined.find(
        (y: TextValuePair) => y.value === x.label
      );
      if (colour) {
        colours.push({
          borderColor: colour.text,
          backgroundColor: colour.text,
        });
      }
    });

    return colours;
  }

  private static existDefinedColoursForAll(
    stackData: Array<LabeledData>
  ): boolean {
    const exists: boolean = stackData.every((x: LabeledData) => {
      const colour: TextValuePair = TrendColoursConstants.Defined.find(
        (y: TextValuePair) => y.value === x.label
      );

      return !!colour;
    });

    return exists;
  }

  private static getYAxisConfig(
    trendChart: TrendChart,
    config: TrendChartConfig
  ): Array<any> {
    let maxValue = 0;
    let minValue = 0;

    const yAxisConfigs: Array<any> = [];

    if (config.definition.allowPercentage && config.trendFilters.isPercentage) {
      maxValue = max([
        trendChart.lines.getMaxValue(),
        max(trendChart.stack.totals.data),
        max(trendChart.stack.items[0]?.data),
      ]);
      minValue = min([
        trendChart.lines.getMinValue(),
        min(trendChart.stack.totals.data),
      ]);

      yAxisConfigs.push({
        display: false,
        ticks: {
          suggestedMax: maxValue,
          scaleStepWidth: 1000,
          suggestedMin: minValue,
          beginAtZero: true,
          callback: (value: any) => {
            return value + '%';
          },
        },
      });
    } else {
      maxValue = max([
        trendChart.lines.getMaxValue(),
        trendChart.stack.getMaxValue(trendChart.stack.totals),
        max(trendChart.stack.items[0]?.data),
      ]);
      minValue = min([
        trendChart.lines.getMinValue(),
        trendChart.stack.getMinValue(),
      ]);

      yAxisConfigs.push({
        stacked: true,
        display: false,
        ticks: {
          suggestedMax: maxValue,
          suggestedMin: minValue,
          beginAtZero: true,
        },
      });
    }

    yAxisConfigs.push({
      id: 'line-axis',
      stacked: false,
      display: true,
      ticks: {
        suggestedMax: maxValue,
        suggestedMin: minValue,
        beginAtZero: true,
        maxTicksLimit: 10,
        userCallback: this.transformAxisNumericValues.bind(this),
      },
    });

    return yAxisConfigs;
  }

  private static transformAxisNumericValues(value: number): string {
    value = Math.round(value);

    let isNegative = false;

    if (value < 0) {
      isNegative = true;
      value = value * -1;
    }

    let auxValue: string = value.toString();
    auxValue = auxValue.split(/(?=(?:...)*$)/).join(',');

    if (isNegative) {
      auxValue = `-${auxValue}`;
    }

    return auxValue;
  }

  private static generateOtherLabel(
    items: Array<LabeledData>
  ): Array<LabeledData> {
    items = orderBy(
      items,
      (i: LabeledData) =>
        reduce(
          i.data ?? [],
          (res: number, n: number | undefined) => {
            return res + (n ?? 0);
          },
          0
        ),
      'desc'
    );

    const others: Array<LabeledData> = remove(
      items,
      (value: LabeledData, index: number) => index > 4
    );
    const sumArray = (...other: any): number => sum(other);
    const othersResult = zipWith.apply(
      null,
      others.map((x: any) => x.data).concat(sumArray)
    ) as Array<number>;

    items.push(
      new LabeledData({
        label: 'Other',
        data: othersResult,
      })
    );

    return items;
  }

  public static getLegendClassForLine(legendItem: any): string {
    const itemText: string = legendItem.text.toLowerCase();

    if (itemText.indexOf('plan') !== -1) {
      return 'plan-key';
    }

    return 'projection-key';
  }

  private static createTooltip(
    chart: any,
    model: TooltipModel<any>,
    trendChart: TrendChart,
    config: TrendChartConfig
  ): void {
    if (
      trendChart.stack.items.length === 0 &&
      trendChart.lines.items.length === 0
    ) {
      return;
    }

    let tooltipEl: HTMLElement = document.getElementById(`chartjs-tooltip`);
    let tootltipDataLength: number;
    let innerHTML = '';
    const numberPipe = new CustomNumberPipe();

    if (!tooltipEl) {
      tooltipEl = document.createElement('div');
      tooltipEl.id = `chartjs-tooltip`;
      tooltipEl.className = 'chartjs-tooltip';
      tooltipEl.innerHTML = `<table class='chartjs-tooltip-table'></table>`;
      document.body.appendChild(tooltipEl);
    }

    // Hide if no tooltip
    if (model.opacity === 0) {
      tooltipEl.style.opacity = '0';
      return;
    }

    // Set caret Position
    tooltipEl.classList.remove('above', 'below', 'no-transform');
    if (model.yAlign) {
      tooltipEl.classList.add(model.yAlign);
    } else {
      tooltipEl.classList.add('no-transform');
    }

    if (model.body) {
      // Tooltip title
      innerHTML += '<thead>';
      (model.title || []).forEach((x: string) => {
        innerHTML += `<tr class="chartjs-tooltip-table-row"><th>${x}</th></tr>`;
      });
      innerHTML += '</thead><tbody>';

      let bodyLines: Array<string[]> = model.body.map((x: any) => x.lines);

      // Percentage tooltip logic
      if (config.trendFilters.isPercentage) {
        const tooltipData: Array<string[]> = TrendChartUtils.getCciBodyLines(
          trendChart.stack,
          model
        );
        const colours: Array<TooltipLabelStyle> = this.getColoursForStackBars(
          trendChart.stack.items,
          config.trendFilters
        );

        if (bodyLines[bodyLines.length - 1][0].includes('CCI%')) {
          bodyLines.pop();
          model.labelColors.pop();
        }

        bodyLines = bodyLines.concat(tooltipData);
        model.labelColors = model.labelColors.concat(colours);
      }

      // Total label
      bodyLines.splice(TrendChartUtils.totalsPositionInTooltip(bodyLines), 0, [
        `Total: ${
          trendChart.stack.totals.data[TrendChartUtils.getXAxisLabel(model)]
            ? trendChart.stack.totals.data[TrendChartUtils.getXAxisLabel(model)]
            : 0
        }`,
      ]);

      const totalColor: TextValuePair = TrendColoursConstants.Defined.find(
        (x: TextValuePair) => x.value === 'Total'
      );

      model.labelColors.splice(
        TrendChartUtils.totalsPositionInTooltip(bodyLines),
        0,
        {
          backgroundColor: config.trendFilters.isPercentage
            ? totalColor.text
            : '#FFF',
          borderColor: config.trendFilters.isPercentage
            ? totalColor.text
            : '#FFF',
        }
      );

      // if (config.selectedFilters.hasAttributesSelected()) {
      //   // Remove QCF and PLAN
      // }

      tootltipDataLength = bodyLines.length;

      // Body lines handler
      bodyLines.forEach((bodyLine: any, i: number) => {
        const dataPoint: Array<string> = bodyLine[0].split(':');
        let lineStyle = '';
        let isLine = false;
        let label: string;
        let value: number;
        let valueText: string;

        if (dataPoint.length > 0) {
          label = dataPoint[0];
          value = config.trendFilters.isPercentage
            ? parseFloat(dataPoint[1].replace(/,/g, '')) / 100
            : parseFloat(dataPoint[1].replace(/,/g, ''));
          valueText = numberPipe.transform(
            value,
            config.trendFilters.isPercentage
              ? ValueType.Percentage
              : ValueType.Numeric,
            '-'
          );
        }

        if (value !== 0) {
          if (bodyLine[0].toLowerCase().includes('plan')) {
            lineStyle += 'transform: translateY(-50%);border-bottom: 2px solid';
            isLine = true;
          } else if (bodyLine[0].toLowerCase().includes('projection')) {
            lineStyle +=
              'transform: translateY(-50%);border-bottom: 2px dotted';
            isLine = true;
          }

          if (isLine) {
            lineStyle +=
              '; width: 15px; height: 10px; position: absolute; border-color:';
            lineStyle += '; border-width: 2px';
          } else {
            lineStyle = 'background:' + model.labelColors[i]?.backgroundColor;
            lineStyle +=
              '; width: 15px; height: 10px; position: absolute; border-color:' +
              model.labelColors[i]?.borderColor;
            lineStyle += '; border-width: 2px';
          }

          const iconSpan: string = '<span style="' + lineStyle + '"></span>';
          const bodySpan = `<span style='margin-left: 25px;'>${label} </span>`;
          const dataSpan = `<span class="${
            value < 0 ? 'negative' : ''
          }">${valueText}</span>`;
          // eslint-disable-next-line max-len
          innerHTML += `<tr style='${
            label === 'Total' ? 'font-weight: 600' : ''
          }'> <td style='white-space: nowrap; overflow: hidden; text-overflow: ellipsis; max-width: 145px;'>
                                ${iconSpan} ${bodySpan}</td><td style="text-align: right;">${dataSpan}</td></tr>`;
        }
      });

      tooltipEl.querySelector('table').innerHTML = innerHTML;
    }

    const position: DOMRect = chart.canvas.getBoundingClientRect();
    const bodyFont: FontSpec = toFont(model.options.bodyFont as FontSpec);

    tooltipEl.style.opacity = '1';
    tooltipEl.style.position = 'absolute';
    tooltipEl.style.left =
      position.left + window.pageXOffset + model.caretX + 'px';
    tooltipEl.style.top = 180 + 25 * tootltipDataLength + position.top + 'px';
    tooltipEl.style.fontSize = bodyFont.size + 'px';
    tooltipEl.style.fontStyle = bodyFont.style;
    tooltipEl.style.padding = model.options.padding + 'px';
    tooltipEl.style.pointerEvents = 'none';
    tooltipEl.style.zIndex = '1100';
  }

  private static getXAxisLabel(model: TooltipModel<any>): number {
    if (model.dataPoints) {
      return model.dataPoints[0].dataIndex;
    }

    return 0;
  }

  private static totalsPositionInTooltip(lines: Array<string[]>): number {
    let index = 0;

    lines.forEach((x: Array<string>) => {
      if (
        x[0].toLowerCase().includes('plan') ||
        x[0].toLowerCase().includes('projection')
      ) {
        index++;
      }
    });

    return index;
  }

  private static getCciBodyLines(stack: TrendStack, model: TooltipModel<any>) {
    const currentIndex: number = TrendChartUtils.getXAxisLabel(model);
    const tooltipData: Array<string[]> = [];

    stack.items.forEach((x: LabeledData) => {
      const data: number = x.data[currentIndex];

      if (!isNaN(data)) {
        tooltipData.push([`${x.label}: ${data}`]);
      }
    });

    return tooltipData;
  }
}
