import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { assign } from 'lodash';
import { DropDownType } from 'src/app/shared/constants/filters.constants';
import { GridToolbarControlType } from 'src/app/shared/constants/grid.constants';
import { TextValuePair } from 'src/app/shared/services/entities/common/key-value';
import { FileExporterOptions } from 'src/app/shared/services/entities/file-exporter-options';

// Config

class GridToolbarConfigSchema {
  componentName: string;
  controls: Array<any>;
  searchText?: string;
}

export class GridToolbarConfig extends GridToolbarConfigSchema {
  constructor(filterAttributes?: GridToolbarConfigSchema) {
    super();
    assign(this, filterAttributes);
  }

  findControl<T extends IGridToolbarControl>(controlName: string): T {
    return this.controls?.find((x: T) => x.controlName === controlName);
  }
}

// GridToolbar Control Interface

export interface IGridToolbarControl {
  get controlType(): GridToolbarControlType;
  controlName: string;
  isVisible?: (x: GridToolbarConfig) => boolean;
  isDisabled?: (x: GridToolbarConfig) => boolean;
}

// Dropdown

class GridToolbarDropdownSchema {
  controlName: string;
  type?: DropDownType = DropDownType.Button;
  selected: TextValuePair;
  isVisible?: (x: GridToolbarConfig) => boolean;
  isDisabled?: (x: GridToolbarConfig) => boolean;
  items: Array<TextValuePair>;
  onChange: (x: TextValuePair, y: GridToolbarDropdown) => void;
}

export class GridToolbarDropdown
  extends GridToolbarDropdownSchema
  implements IGridToolbarControl
{
  get controlType(): GridToolbarControlType {
    return GridToolbarControlType.Dropdown;
  }

  constructor(filterAttributes?: GridToolbarDropdownSchema) {
    super();
    assign(this, filterAttributes);
  }
}

class GridToolbarDropdownMenuSchema {
  controlName: string;
  text: string;
  icon: IconProp;
  tooltip?: string;
  isVisible?: (x: GridToolbarConfig) => boolean;
  isDisabled?: (x: GridToolbarConfig) => boolean;
  items: Array<FileExporterOptions>;
  onChange: (x: TextValuePair, y: GridToolbarDropdownMenu) => void;
}

export class GridToolbarDropdownMenu
  extends GridToolbarDropdownMenuSchema
  implements IGridToolbarControl
{
  get controlType(): GridToolbarControlType {
    return GridToolbarControlType.DropdownMenu;
  }

  constructor(filterAttributes?: GridToolbarDropdownMenuSchema) {
    super();
    assign(this, filterAttributes);
  }
}

class GridToolbarMultipleDropdownSchema {
  controlName: string;
  placeholder?: string;
  type?: DropDownType = DropDownType.Button;
  selected: Array<TextValuePair>;
  isVisible?: (x: GridToolbarConfig) => boolean;
  isDisabled?: (x: GridToolbarConfig) => boolean;
  items: Array<TextValuePair>;
  onChange: (x: Array<TextValuePair>, y: GridToolbarMultipleDropdown) => void;
}

export class GridToolbarMultipleDropdown
  extends GridToolbarMultipleDropdownSchema
  implements IGridToolbarControl
{
  get controlType(): GridToolbarControlType {
    return GridToolbarControlType.MultipleDropdown;
  }

  constructor(filterAttributes?: GridToolbarMultipleDropdownSchema) {
    super();
    assign(this, filterAttributes);
  }
}

// Icon

class GridToolbarIconSchema {
  controlName: string;
  icon: IconProp;
  text?: string;
  tooltip?: string;
  cssClass?: string;
  isVisible?: (x: GridToolbarConfig) => boolean;
  isDisabled?: (x: GridToolbarConfig) => boolean;
  onClick: () => void;
}

export class GridToolbarIcon
  extends GridToolbarIconSchema
  implements IGridToolbarControl
{
  get controlType(): GridToolbarControlType {
    return GridToolbarControlType.Icon;
  }

  constructor(filterAttributes?: GridToolbarIconSchema) {
    super();
    assign(this, filterAttributes);
  }
}

// Button

class GridToolbarButtonSchema {
  controlName: string;
  text: string;
  tooltip?: string;
  cssClass?: string;
  isVisible?: (x: GridToolbarConfig) => boolean;
  isDisabled?: (x: GridToolbarConfig) => boolean;
  onClick: () => void;
}

export class GridToolbarButton
  extends GridToolbarButtonSchema
  implements IGridToolbarControl
{
  get controlType(): GridToolbarControlType {
    return GridToolbarControlType.Button;
  }

  constructor(filterAttributes?: GridToolbarButtonSchema) {
    super();
    assign(this, filterAttributes);
  }
}

// Switch

class GridToolbarSwitchSchema {
  controlName: string;
  text: string;
  switchValue?: boolean = false;
  isVisible?: (x: GridToolbarConfig) => boolean;
  isDisabled?: (x: GridToolbarConfig) => boolean;
  onSwitch: (x: boolean, y: GridToolbarSwitch) => void;
}

export class GridToolbarSwitch
  extends GridToolbarSwitchSchema
  implements IGridToolbarControl
{
  get controlType(): GridToolbarControlType {
    return GridToolbarControlType.Switch;
  }

  constructor(filterAttributes?: GridToolbarSwitchSchema) {
    super();
    assign(this, filterAttributes);
  }
}
