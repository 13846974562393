import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { combineLatest, filter, Subscription } from 'rxjs';
import { cloneDeep } from 'lodash';
import {
  NgbDropdown,
  NgbDropdownModule,
  NgbPopoverModule,
} from '@ng-bootstrap/ng-bootstrap';

import { SelectedFilters } from 'src/app/shared/services/entities/filters/selected-filters';
import { FiltersService } from 'src/app/shared/services/filters.service';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import { AppliedFiltersTooltipComponent } from 'src/app/shared/components/filters-picker/components/applied-filters-tooltip/applied-filters-tooltip.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SharedModule } from 'src/app/shared-module';
import { AppModulePaths } from 'src/app/shared/constants/navbar.constants';
import { NgClass, NgIf } from '@angular/common';
import { NavigationStart, Router } from '@angular/router';
import { Filters } from 'src/app/shared/services/entities/filters/filters';
import { CustomerFilters } from 'src/app/shared/services/entities/filters/customer-filters';
import { FiltersManager } from 'src/app/shared/components/filters/entities/filters-manager';
import { FilterSelection } from 'src/app/shared/components/filters/entities/filter-selection';
import { FilterType } from 'src/app/shared/constants/filters.constants';
import { ProfitLeversDropdownComponent } from './profitLevers-dropdown/profitLevers-dropdown.component';
import { BetaMessageService } from 'src/app/shared/services/beta-message.service';
import { AppStateService } from 'src/app/shared/services/app-state.service';
import { AppState } from 'src/app/shared/services/entities/app-state/app-state';
import { ViewOptions } from 'src/app/shared/constants/grid.constants';

@Component({
  selector: 'app-filters-picker-profituplift',
  templateUrl: './filters-picker-profituplift.component.html',
  styleUrls: ['./filters-picker-profituplift.component.scss'],
  standalone: true,
  imports: [
    SharedModule,
    FontAwesomeModule,
    AppliedFiltersTooltipComponent,
    ProfitLeversDropdownComponent,
    NgIf,
    NgClass,
  ],
})
export class FiltersPickerProfitupliftComponent implements OnInit, OnDestroy {
  @ViewChild('filtersDropdown', { static: false }) filtersDropdown: NgbDropdown;

  betaMessage: BetaMessageService;
  appStateService: AppStateService;
  isCloud: boolean;
  isFlipFlopFlagEnabled: boolean;
  subscription = new Subscription();
  hasChanged = false;
  filters: Filters;
  selectedFilters: SelectedFilters;
  filtersManager = new FiltersManager();
  searchText: string;
  isTargetCurrProj = false;

  isSpeculative: boolean;
  isDisablePU: boolean;
  faFilter = faFilter as IconProp;

  constructor(
    private router: Router,
    private filtersService: FiltersService,
    private changeDetector: ChangeDetectorRef,
    appStateService: AppStateService,
    betaMessage: BetaMessageService
  ) {
    this.appStateService = appStateService;
    this.betaMessage = betaMessage;
  }

  ngOnInit(): void {
    this.subscription.add(
      combineLatest([
        this.filtersService.globalFiltersChanged,
        this.filtersService.selectedFiltersChanged,
        this.router.events.pipe(
          filter((event: any) => event instanceof NavigationStart)
        ),
        this.appStateService.appStateChanged,
        this.betaMessage.isCloudSubject$,
      ]).subscribe(
        ([filters, selectedFilter, route, appState, betaFlag]: [
          Filters,
          SelectedFilters,
          NavigationStart,
          AppState,
          Boolean
        ]) => {
          if (route instanceof NavigationStart) {
            this.isSpeculative = route.url.includes(
              AppModulePaths.speculative.path
            );
          }
          this.filters = filters;
          this.isTargetCurrProj =
            selectedFilter.target.value === ViewOptions.Current;
          this.isDisablePU = !selectedFilter.isPUdtls || !this.isTargetCurrProj;
          this.selectedFilters = selectedFilter;
          this.isFlipFlopFlagEnabled = appState.gcpFlipFlopFlagEnabled;

          this.isCloud =
            (betaFlag && !this.isFlipFlopFlagEnabled) ||
            (!betaFlag && this.isFlipFlopFlagEnabled);
        }
      )
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  onApplyFilters(): void {
    this.filtersDropdown.close();

    const selectedFilters: SelectedFilters =
      this.filtersManager.applySelectionPF(this.selectedFilters, this.filters);

    this.filtersService.updateSelectedFilters(selectedFilters);
    this.filtersService.applyFilters();
  }

  getSearchEvent(event: string): void {
    this.searchText = event;
  }

  onClearFilters(): void {
    this.filtersDropdown.close();
    this.searchText = '';
    const selectedFilters: SelectedFilters = cloneDeep(this.selectedFilters);
    selectedFilters.profitLevers = [];
    this.filtersService.updateSelectedFilters(selectedFilters);
    this.filtersService.applyFilters();
  }

  onItemSelection(selection: FilterSelection): void {
    switch (selection.type) {
      case FilterType.ProfitLever:
        this.filtersManager.profitLevers = selection;
        break;
    }
    this.changeDetector.detectChanges();
    this.checkForChanges();
  }

  checkForChanges(): void {
    this.hasChanged = this.filtersManager.hasChanged(this.selectedFilters);
  }
}
