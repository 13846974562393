<app-search-input placeholder="Search for Currency" (searchEvent)="onSearchTextChanged($event)">
</app-search-input>
<div class="scrolleable-list">
    <button *ngFor="let c of currencies | filterArray : searchText : 'Description'" (click)="onCurrencyChanged(c, true)"
        ngbDropdownItem>
        <fa-icon [icon]="faCheckCircle" *ngIf="selectedCurrency?.Description === c.Description"></fa-icon>
        <span
            [ngClass]="{'not-selected' : selectedCurrency?.Description !== c.Description, 'selected' : selectedCurrency?.Description === c.Description}">{{c.Description}}</span>
    </button>
</div>
<div *ngIf="(currencies | filterArray : searchText : 'Description').length === 0">
    <button ngbDropdownItem>
        <span class="not-selected">Not found</span>
    </button>
</div>
