import { Component, OnDestroy, OnInit, Input } from '@angular/core';
import { ColDef } from 'ag-grid-community';
import { combineLatest, ReplaySubject, Subject, Subscription } from 'rxjs';
import { cloneDeep, has } from 'lodash';
import { BetaMessageService } from 'src/app/shared/services/beta-message.service';

import {
  ComponentNames,
  GridColDefs,
  GridFilters,
} from './constants/analytics-grid.constants';
import {
  GridToolbarButton,
  GridToolbarConfig,
  GridToolbarDropdown,
  GridToolbarIcon,
  GridToolbarMultipleDropdown,
} from 'src/app/shared/components/base/grid/components/grid-toolbar/entities/grid-toolbar-config';
import { GridData } from 'src/app/shared/components/base/grid/entities/grid-data';
import { Filters } from 'src/app/shared/services/entities/filters/filters';
import { SelectedFilters } from 'src/app/shared/services/entities/filters/selected-filters';
import {
  GridConfig,
  GridConfigFeatures,
} from 'src/app/shared/components/base/grid/entities/grid-config';
import { GridObservables } from 'src/app/shared/components/base/grid/entities/grid-observables';
import { TextValuePair } from 'src/app/shared/services/entities/common/key-value';
import { AnalyticsGridUtils } from './utils/analytics-grid.utils';
import { AnalyticsResponseItem } from 'src/app/shared/services/entities/grids/analytics-response';
import {
  CustomOptions,
  ViewOptions,
} from 'src/app/shared/constants/grid.constants';
import {
  CustomCurrencies,
  GroupsBy,
  Periods,
  TimePeriodCodes,
} from 'src/app/shared/constants/filters.constants';
import { AppState } from 'src/app/shared/services/entities/app-state/app-state';
import { MessageTemplates } from 'src/app/shared/constants/messages.constants';
import { FavoriteResponseItem } from 'src/app/shared/services/entities/favorite-response';
import { MmbDate } from 'src/app/shared/services/entities/filters/date';
import { FavoriteFilterDefaults } from '../favorite-modal/entities/favorite-filter-defaults';
import { AnalyticsRequestPayload } from 'src/app/shared/services/entities/analytics-request-payload';
import { Wmu } from 'src/app/shared/services/entities/filters/wmu';

import { AppMessagesService } from 'src/app/shared/services/app-messages.service';
import { AppToastsService } from 'src/app/shared/services/app-toasts.service';
import { FiltersService } from 'src/app/shared/services/filters.service';
import { AnalyticsGridService } from 'src/app/shared/services/grids/analytics-grid.service';
import { AppStateService } from 'src/app/shared/services/app-state.service';
import { CustomerFilters } from 'src/app/shared/services/entities/filters/customer-filters';
import { FavoriteModalService } from 'src/app/shared/services/modals/favorite-modal.service';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { GridComponent } from '../../../../shared/components/base/grid/grid.component';
import { GridToolbarComponent } from '../../../../shared/components/base/grid/components/grid-toolbar/grid-toolbar.component';

@Component({
  selector: 'app-analytics-grid',
  templateUrl: './analytics-grid.component.html',
  styleUrls: ['./analytics-grid.component.scss'],
  standalone: true,
  imports: [GridToolbarComponent, GridComponent],
})
export class AnalyticsGridComponent implements OnInit, OnDestroy {
  subscription = new Subscription();

  loadChangedEvent = new Subject<boolean>();
  updateRowDataEvent = new ReplaySubject<GridData>(1);
  updateColDefsEvent = new ReplaySubject<Array<ColDef>>(1);
  filtersChangedEvent = new Subject<string>();
  expandChangedEvent = new Subject<boolean>();

  isFlipFlopEnabled: boolean;
  isCloud: boolean;
  filters: Filters;
  customerFilters: CustomerFilters;
  selectedFilters: SelectedFilters;
  appState: AppState;
  gridToolbarConfig: GridToolbarConfig;
  gridConfig: GridConfig;
  favoriteFilterDefaults: FavoriteFilterDefaults;

  constructor(
    private filtersService: FiltersService,
    private analyticsGridService: AnalyticsGridService,
    private appStateService: AppStateService,
    private favoriteModalService: FavoriteModalService,
    private appMessagesService: AppMessagesService,
    private appToastsService: AppToastsService,
    private betaMessage: BetaMessageService
  ) {}

  ngOnInit(): void {
    this.subscription.add(
      combineLatest([
        this.filtersService.globalFiltersChanged,
        this.filtersService.selectedFiltersChanged,
        this.filtersService.customerFiltersChanged,
        this.appStateService.appStateChanged,
      ]).subscribe(
        ([w, x, y, z]: [
          Filters,
          SelectedFilters,
          CustomerFilters,
          AppState
        ]) => {
          this.filters = w;
          this.isFlipFlopEnabled = z.gcpFlipFlopFlagEnabled;
          const timeframeChanged: boolean =
            (this.selectedFilters &&
              this.selectedFilters.timeframe.getDescription() !==
                x.timeframe.getDescription()) ||
            false;
          this.selectedFilters = x;
          this.customerFilters = y;
          this.appState = z;

          if (!this.gridToolbarConfig) {
            this.initializeToolbar();
            this.initializeGridConfig();
          }

          this.clearData();
          this.validateFilterSelection(timeframeChanged);
          this.setDefaultModalParameters();
        }
      )
    );

    this.subscription.add(
      this.favoriteModalService.applyFavoriteEmitted.subscribe(
        (x: FavoriteResponseItem) => {
          this.initializeToolbar(x);
          this.validateFilterSelection();
          this.setDefaultModalParameters();
          setTimeout(() => {
            this.onApply();
          }, 0);
        }
      )
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  initializeToolbar(appliedFavorite?: FavoriteResponseItem): void {
    const targets: Array<TextValuePair> = GridFilters.TargetOptions(
      this.selectedFilters
    );

    this.gridToolbarConfig = new GridToolbarConfig({
      componentName: ComponentNames.analytics,
      controls: [
        new GridToolbarMultipleDropdown({
          controlName: ComponentNames.metrics,
          placeholder: 'Metrics',
          items: GridFilters.Metrics,
          onChange: this.onMetricsChanged.bind(this),
          selected: appliedFavorite
            ? GridFilters.Metrics.filter((x: TextValuePair) =>
                appliedFavorite.metric.includes(x.value)
              )
            : [],
        }),
        new GridToolbarDropdown({
          controlName: ComponentNames.periods,
          items: GridFilters.Periods.map((x: TextValuePair) => {
            if (x.value !== CustomOptions.None) {
              x.params.isDisabled = () => {
                return [
                  TimePeriodCodes.CurrentMonth,
                  TimePeriodCodes.MonthToDate,
                ].includes(this.selectedFilters.timeframe.code);
              };
            }
            return x;
          }),
          onChange: this.onPeriodChanged.bind(this),
          selected: appliedFavorite
            ? TextValuePair.findValueOrDefault(
                GridFilters.Periods,
                appliedFavorite.period,
                Periods.Quarter.id
              )
            : TextValuePair.findValueOrDefault(
                GridFilters.Periods,
                Periods.Quarter.id
              ),
        }),
        new GridToolbarDropdown({
          controlName: ComponentNames.group1,
          items: cloneDeep(GridFilters.Groups)
            .filter((x: TextValuePair) => x.value !== CustomOptions.None)
            .map((x: TextValuePair) => {
              x.params.isDisabled = () => {
                return this.isGroupOptionDisabled(ComponentNames.group1, x);
              };
              return x;
            }),
          onChange: this.onGroupChanged.bind(this),
          selected: appliedFavorite
            ? TextValuePair.findValueOrDefault(
                cloneDeep(GridFilters.Groups),
                appliedFavorite.group1,
                GroupsBy.ClientGroup.id
              )
            : TextValuePair.findValueOrDefault(
                cloneDeep(GridFilters.Groups),
                GroupsBy.ClientGroup.id
              ),
        }),
        new GridToolbarDropdown({
          controlName: ComponentNames.group2,
          items: cloneDeep(GridFilters.Groups).map((x: TextValuePair) => {
            x.params.isDisabled = () => {
              return this.isGroupOptionDisabled(ComponentNames.group2, x);
            };
            return x;
          }),
          onChange: this.onGroupChanged.bind(this),
          selected: appliedFavorite
            ? TextValuePair.findValueOrDefault(
                cloneDeep(GridFilters.Groups),
                appliedFavorite.group2,
                CustomOptions.None
              )
            : TextValuePair.findValueOrDefault(
                cloneDeep(GridFilters.Groups),
                GroupsBy.ServiceGroup.id
              ),
        }),
        new GridToolbarDropdown({
          controlName: ComponentNames.group3,
          items: cloneDeep(GridFilters.Groups).map((x: TextValuePair) => {
            x.params.isDisabled = () => {
              return this.isGroupOptionDisabled(ComponentNames.group3, x);
            };
            return x;
          }),
          onChange: this.onGroupChanged.bind(this),
          selected: appliedFavorite
            ? TextValuePair.findValueOrDefault(
                cloneDeep(GridFilters.Groups),
                appliedFavorite.group3,
                CustomOptions.None
              )
            : TextValuePair.findValueOrDefault(
                cloneDeep(GridFilters.Groups),
                GroupsBy.Market.id
              ),
        }),
        new GridToolbarDropdown({
          controlName: ComponentNames.group4,
          items: cloneDeep(GridFilters.Groups).map((x: TextValuePair) => {
            x.params.isDisabled = () => {
              return this.isGroupOptionDisabled(ComponentNames.group4, x);
            };
            return x;
          }),
          onChange: this.onGroupChanged.bind(this),
          selected: appliedFavorite
            ? TextValuePair.findValueOrDefault(
                cloneDeep(GridFilters.Groups),
                appliedFavorite.group4,
                CustomOptions.None
              )
            : TextValuePair.findValueOrDefault(
                cloneDeep(GridFilters.Groups),
                GroupsBy.Industry.id
              ),
        }),
        new GridToolbarDropdown({
          controlName: ComponentNames.dataset1,
          items: targets
            .filter(
              (x: TextValuePair) => x.value !== CustomOptions.None.toLowerCase()
            )
            .map((x: TextValuePair) => {
              x.params.isDisabled = () => {
                return this.isDatasetOptionDisabled(ComponentNames.dataset1, x);
              };
              return x;
            }),
          onChange: this.onDatasetChanged.bind(this),
          selected: appliedFavorite
            ? TextValuePair.findValueOrDefault(
                targets,
                appliedFavorite.target1,
                ViewOptions.Current
              )
            : TextValuePair.findValueOrDefault(targets, ViewOptions.Current),
        }),
        new GridToolbarDropdown({
          controlName: ComponentNames.dataset2,
          items: targets.map((x: TextValuePair) => {
            x.params.isDisabled = () => {
              return this.isDatasetOptionDisabled(ComponentNames.dataset2, x);
            };
            return x;
          }),
          onChange: this.onDatasetChanged.bind(this),
          selected: appliedFavorite
            ? TextValuePair.findValueOrDefault(
                targets,
                appliedFavorite.target2,
                CustomOptions.None.toLowerCase()
              )
            : TextValuePair.findValueOrDefault(
                targets,
                CustomOptions.None.toLowerCase()
              ),
        }),
        new GridToolbarButton({
          controlName: ComponentNames.apply,
          onClick: this.onApply.bind(this),
          text: 'Apply',
          cssClass: 'btn btn-primary btn-apply',
          isDisabled: (x: GridToolbarConfig) => {
            const metricsDropdown: GridToolbarMultipleDropdown = x.findControl(
              ComponentNames.metrics
            );
            return metricsDropdown.selected?.length === 0;
          },
          tooltip: 'Click Apply button for changes to take effect.',
        }),
        new GridToolbarIcon({
          controlName: ComponentNames.export,
          icon: faDownload as IconProp,
          onClick: this.onFileExport.bind(this),
          text: 'EXPORT',
          isDisabled: (x: GridToolbarConfig) => {
            const metricsDropdown: GridToolbarMultipleDropdown = x.findControl(
              ComponentNames.metrics
            );
            return metricsDropdown.selected?.length === 0;
          },
        }),
      ],
    });
  }

  initializeGridConfig(): void {
    this.gridConfig =
      this.gridConfig ??
      new GridConfig({
        features: new GridConfigFeatures({
          hasTotalsRow: true,
        }),
        columns: [],
        options: GridColDefs.getExtraGridOptions(),
        observables: new GridObservables({
          loadChanged: this.loadChangedEvent.asObservable(),
          updateColDefs: this.updateColDefsEvent,
          updateRowData: this.updateRowDataEvent,
          filterChanged: this.filtersChangedEvent.asObservable(),
          expandChanged: this.expandChangedEvent.asObservable(),
        }),
      });

    this.updateColDefs();
  }

  updateColDefs(): void {
    this.updateColDefsEvent.next(
      GridColDefs.getColDefs(
        this.gridToolbarConfig,
        this.filters,
        this.selectedFilters
      )
    );
  }

  getData(): void {
    this.loadChangedEvent.next(true);

    const groupBy = `${this.favoriteFilterDefaults.group1},${this.favoriteFilterDefaults.group2},${this.favoriteFilterDefaults.group3},${this.favoriteFilterDefaults.group4},${this.favoriteFilterDefaults.period}`;

    const serviceAttributesExtraParams: Array<Record<string, any>> = [];

    if (
      this.selectedFilters.wmus?.length === 0 &&
      groupBy.includes(GroupsBy.WMU.id)
    ) {
      const root: Wmu = this.customerFilters.wmu.find(
        (x: Wmu) => x.PID === null || x.PID === 0
      );

      if (root) {
        serviceAttributesExtraParams.push({
          Name: 'RolledUpWmuList',
          Value: this.customerFilters.wmu
            .filter((x: Wmu) => x.PID === root.ID)
            .map((x: Wmu) => x.ID)
            .join(','),
        });
      }
    }

    this.subscription.add(
      this.betaMessage.isCloudSubject$.subscribe((betaFlag) => {
        this.isCloud =
          (betaFlag && !this.isFlipFlopEnabled) ||
          (!betaFlag && this.isFlipFlopEnabled);
        this.analyticsGridService
          .getAnalytics(
            AnalyticsRequestPayload.createRequest(
              this.selectedFilters,
              groupBy,
              this.favoriteFilterDefaults.target1,
              this.favoriteFilterDefaults.target2,
              this.favoriteFilterDefaults.metric,
              null,
              serviceAttributesExtraParams
            ),
            this.isCloud
          )
          .then((x: Array<AnalyticsResponseItem>) => {
            if (groupBy.includes(GroupsBy.ByRecord.id)) {
              x = AnalyticsGridUtils.adjustDataByRecord(
                x,
                this.favoriteFilterDefaults.group1,
                this.favoriteFilterDefaults.group2,
                this.favoriteFilterDefaults.group3
              );
            }
            this.updateRowDataEvent.next(this.mapToGridData(x));
            this.loadChangedEvent.next(false);
            this.expandChangedEvent.next(true);
          });
      })
    );
  }

  mapToGridData(response: Array<AnalyticsResponseItem>): GridData {
    const analytics: Array<AnalyticsResponseItem> = cloneDeep(response);

    const result = new GridData({
      rows: AnalyticsGridUtils.mapData(analytics),
      pinnedTop: [],
    });

    return result;
  }

  isGroupOptionDisabled(groupName: string, value: TextValuePair): boolean {
    const groups: Array<string> = [
      ComponentNames.group1,
      ComponentNames.group2,
      ComponentNames.group3,
      ComponentNames.group4,
    ];

    const datasets: Array<string> = [
      ComponentNames.dataset1,
      ComponentNames.dataset2,
    ];

    return (
      (this.hasValueAlreadySelected(
        groups.filter((x: string) => x !== groupName),
        value.value
      ) ||
        ([GroupsBy.Composition.id, GroupsBy.ByRecord.id].includes(
          value.value
        ) &&
          this.hasValueAlreadySelected(datasets, ViewOptions.Plan) &&
          !this.appState.planCodeEnabled)) &&
      value.value !== CustomOptions.None
    );
  }

  isDatasetOptionDisabled(dataset: string, value: TextValuePair): boolean {
    const datasets: Array<string> = [
      ComponentNames.dataset1,
      ComponentNames.dataset2,
    ];

    const groups: Array<string> = [
      ComponentNames.group1,
      ComponentNames.group2,
      ComponentNames.group3,
      ComponentNames.group4,
    ];

    const isPlanDisabled: boolean =
      value.value === ViewOptions.Plan &&
      (this.selectedFilters.plan.timeId === -1 ||
        [
          TimePeriodCodes.TwelveRollingMonths,
          TimePeriodCodes.FourRollingQuarters,
        ].includes(this.selectedFilters.timeframe.code) ||
        (this.selectedFilters.currency.Id === CustomCurrencies.FxAdjusted.id &&
          (this.selectedFilters.plan.timeId === -1 ||
            this.selectedFilters.plan.monthLabel.includes('WIP'))) ||
        (this.selectedFilters.plan.timeId === 0 &&
          (this.hasValueAlreadySelected(groups, GroupsBy.Composition.id) ||
            this.hasValueAlreadySelected(groups, GroupsBy.ByRecord.id))));

    const projectionDate: MmbDate = this.filters.dates.find(
      (x: MmbDate) => x.Id === this.selectedFilters.projection.StartTimeId
    );

    const isProjectionDisabled: boolean =
      value.value === ViewOptions.Projection &&
      ([CustomCurrencies.FxAdjusted.id, CustomCurrencies.Constant.id].includes(
        this.selectedFilters.currency.Id
      ) ||
        this.selectedFilters.timeframe.fiscalYear <
          projectionDate.FiscalYearNbr);

    return (
      this.hasValueAlreadySelected(
        datasets.filter((x: string) => x !== dataset),
        value.value
      ) ||
      isPlanDisabled ||
      isProjectionDisabled
    );
  }

  validateFilterSelection(timeframeChanged?: boolean): void {
    const colDefsUpdated = false;

    this.validatePeriodSelection();
    this.validateGroupsSelection();
    this.validateDataSetsSelection();

    if (timeframeChanged && !colDefsUpdated) {
      this.updateColDefs();
    }
  }

  validatePeriodSelection(): void {
    const periodsDropdown: GridToolbarDropdown =
      this.gridToolbarConfig.findControl(ComponentNames.periods);

    const selected: TextValuePair = periodsDropdown.items.find(
      (x: TextValuePair) => x.value === periodsDropdown.selected.value
    );

    if (has(selected.params, 'isDisabled') && selected.params.isDisabled()) {
      const periodsDropdown: GridToolbarDropdown =
        this.gridToolbarConfig.findControl(ComponentNames.periods);
      periodsDropdown.selected = periodsDropdown.items.find(
        (x: TextValuePair) => x.value === CustomOptions.None
      );
    }
  }

  validateGroupsSelection(): void {
    const group1Dropdown: GridToolbarDropdown =
      this.gridToolbarConfig.findControl(ComponentNames.group1);

    const group2Dropdown: GridToolbarDropdown =
      this.gridToolbarConfig.findControl(ComponentNames.group2);

    const group3Dropdown: GridToolbarDropdown =
      this.gridToolbarConfig.findControl(ComponentNames.group3);

    const group4Dropdown: GridToolbarDropdown =
      this.gridToolbarConfig.findControl(ComponentNames.group4);

    const selectedGroup1: TextValuePair = group1Dropdown.items.find(
      (x: TextValuePair) => x.value === group1Dropdown.selected.value
    );

    const selectedGroup2: TextValuePair = group2Dropdown.items.find(
      (x: TextValuePair) => x.value === group2Dropdown.selected.value
    );

    const selectedGroup3: TextValuePair = group3Dropdown.items.find(
      (x: TextValuePair) => x.value === group3Dropdown.selected.value
    );

    const selectedGroup4: TextValuePair = group4Dropdown.items.find(
      (x: TextValuePair) => x.value === group4Dropdown.selected.value
    );

    if (
      has(selectedGroup1.params, 'isDisabled') &&
      selectedGroup1.params.isDisabled()
    ) {
      group1Dropdown.selected = group1Dropdown.items.find(
        (x: TextValuePair) => x.value === GroupsBy.ClientGroup.id
      );
    }

    if (
      has(selectedGroup2.params, 'isDisabled') &&
      selectedGroup2.params.isDisabled()
    ) {
      group2Dropdown.selected = group2Dropdown.items.find(
        (x: TextValuePair) => x.value === CustomOptions.None
      );
    }

    if (
      has(selectedGroup3.params, 'isDisabled') &&
      selectedGroup3.params.isDisabled()
    ) {
      group3Dropdown.selected = group3Dropdown.items.find(
        (x: TextValuePair) => x.value === CustomOptions.None
      );
    }

    if (
      has(selectedGroup4.params, 'isDisabled') &&
      selectedGroup4.params.isDisabled()
    ) {
      group4Dropdown.selected = group4Dropdown.items.find(
        (x: TextValuePair) => x.value === CustomOptions.None
      );
    }
  }

  validateDataSetsSelection(): void {
    const dataset1Dropdown: GridToolbarDropdown =
      this.gridToolbarConfig.findControl(ComponentNames.dataset1);

    const dataset2Dropdown: GridToolbarDropdown =
      this.gridToolbarConfig.findControl(ComponentNames.dataset2);

    const selectedDataset1: TextValuePair = dataset1Dropdown.items.find(
      (x: TextValuePair) => x.value === dataset1Dropdown.selected.value
    );

    const selectedDataset2: TextValuePair = dataset2Dropdown.items.find(
      (x: TextValuePair) => x.value === dataset2Dropdown.selected.value
    );

    if (
      has(selectedDataset1.params, 'isDisabled') &&
      selectedDataset1.params.isDisabled()
    ) {
      dataset1Dropdown.selected = dataset1Dropdown.items.find(
        (x: TextValuePair) => x.value === ViewOptions.Current
      );
    }

    if (
      has(selectedDataset2.params, 'isDisabled') &&
      selectedDataset2.params.isDisabled()
    ) {
      dataset2Dropdown.selected = dataset2Dropdown.items.find(
        (x: TextValuePair) => x.value === CustomOptions.None.toLowerCase()
      );
    }
  }

  hasValueAlreadySelected(controlNames: Array<string>, value: string): boolean {
    return controlNames
      .map((x: string) => {
        const control: GridToolbarDropdown =
          this.gridToolbarConfig.findControl(x);
        return control.selected.value === value;
      })
      .some((x: boolean) => x);
  }

  clearData(): void {
    this.updateRowDataEvent.next(
      new GridData({
        pinnedTop: [],
        rows: [],
      })
    );
  }

  setDefaultModalParameters(): void {
    const metricsDropdown: GridToolbarMultipleDropdown =
      this.gridToolbarConfig.findControl(ComponentNames.metrics);

    const periodsDropdown: GridToolbarDropdown =
      this.gridToolbarConfig.findControl(ComponentNames.periods);

    const group1Dropdown: GridToolbarDropdown =
      this.gridToolbarConfig.findControl(ComponentNames.group1);

    const group2Dropdown: GridToolbarDropdown =
      this.gridToolbarConfig.findControl(ComponentNames.group2);

    const group3Dropdown: GridToolbarDropdown =
      this.gridToolbarConfig.findControl(ComponentNames.group3);

    const group4Dropdown: GridToolbarDropdown =
      this.gridToolbarConfig.findControl(ComponentNames.group4);

    const dataset1Dropdown: GridToolbarDropdown =
      this.gridToolbarConfig.findControl(ComponentNames.dataset1);

    const dataset2Dropdown: GridToolbarDropdown =
      this.gridToolbarConfig.findControl(ComponentNames.dataset2);

    this.favoriteFilterDefaults = new FavoriteFilterDefaults({
      metric: metricsDropdown.selected
        .map((x: TextValuePair) => x.value)
        .join(','),
      period: periodsDropdown.selected.value,
      group1: group1Dropdown.selected.value,
      group2: group2Dropdown.selected.value,
      group3: group3Dropdown.selected.value,
      group4: group4Dropdown.selected.value,
      target1: dataset1Dropdown.selected.value,
      target2: dataset2Dropdown.selected.value,
    });

    this.favoriteModalService.setFavoriteModalParamsEmitted.next(
      this.favoriteFilterDefaults
    );
  }

  onMetricsChanged(
    values: Array<TextValuePair>,
    control: GridToolbarMultipleDropdown
  ): void {
    control.selected = values;
    this.setDefaultModalParameters();
  }

  onPeriodChanged(value: TextValuePair, control: GridToolbarDropdown): void {
    control.selected = value;
    this.setDefaultModalParameters();
  }

  onGroupChanged(value: TextValuePair, control: GridToolbarDropdown): void {
    control.selected = value;
    this.validateGroupsSelection();
    this.setDefaultModalParameters();
  }

  onDatasetChanged(value: TextValuePair, control: GridToolbarDropdown): void {
    control.selected = value;
    this.setDefaultModalParameters();
  }

  onApply(): void {
    this.updateColDefs();
    this.getData();
  }

  onSearchTextChanged(searchText: string): void {
    this.filtersChangedEvent.next(searchText);
  }

  onFileExport(): void {
    this.appMessagesService.show(MessageTemplates.FileExporterInit, {
      centered: true,
    });

    this.analyticsGridService
      .export(
        this.selectedFilters,
        this.customerFilters,
        this.favoriteFilterDefaults,
        this.isCloud
      )
      .then((x: boolean) => {
        if (!x) {
          this.appMessagesService.show(MessageTemplates.UnexpectedError, {
            centered: true,
          });
        }
      });
  }
}
