<div *ngIf="isSpeculative && isCloud" class="ddl-control" ngbDropdown #filtersDropdown="ngbDropdown"
    [ngClass]="{'disabled' : isDisablePU}" placement="bottom-center">
    <div class="filter-icon" id="ddl-filters" ngbDropdownAnchor (click)="filtersDropdown.toggle()"
        [ngbPopover]="appliedContent" triggers="mouseenter:mouseleave" placement="right" container="body"
        [disablePopover]="selectedFilters?.profitLevers.length === 0">
        <fa-icon [icon]="faFilter">
        </fa-icon>
        <span>PROFIT UPLIFT <br> FILTER ({{selectedFilters?.profitLevers ?
            selectedFilters.profitLevers.length : 0}})</span>
    </div>
    <div ngbDropdownMenu aria-labelledby="ddl-filters" class="ddl-content">
        <div class="ddl-inputs mb-2">
            <div>
                <button class="btn btn-link" [disabled]="selectedFilters?.profitLevers.length === 0"
                    (click)="onClearFilters()">Clear All</button>
            </div>
            <div>
                <button class="btn btn-primary" [disabled]="!hasChanged" (click)="onApplyFilters()">Apply</button>
            </div>
        </div>
        <div class="scrollbar">
            <div class="filter-container">
                <div class="cont">
                    <app-profitlevers-dropdown [profitLevers]="filters?.profitLevers"
                        [selectedprofitLevers]="selectedFilters?.profitLevers"
                        (itemSelectionChangeEvent)="onItemSelection($event)" [searchText]="searchText"
                        (searchEvent)="getSearchEvent($event)">
                    </app-profitlevers-dropdown>
                </div>
            </div>
        </div>
    </div>
</div>
<ng-template #appliedContent>
    <app-applied-filters-tooltip [appliedFilters]="selectedFilters" [onlyShowProfitLevers]="true"></app-applied-filters-tooltip>
</ng-template>