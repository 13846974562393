import { assign } from 'lodash';

import { FullLocation } from 'src/app/shared/services/entities/filters/location';
import { SpeculativePhasingPeriod } from 'src/app/shared/services/entities/grids/speculative-response';
import { ReasonCode } from 'src/app/shared/services/entities/reason-code';

class SpeculativeSchema {
  id: string;
  name: string;
  masterCustomerNumber: string;
  masterCustomerName: string;
  reasonCode: ReasonCode;
  financialCustomerNumber: string;
  responsible: string;
  updatedBy?: string;
  status: string;
  lastUpdated: Date;
  startDate: number;
  endDate: number;
  currency: string;
  sales: number;
  wonCCIPercentage: number;
  netRevenue: number;
  deliveredCCIDollar: number;
  deliveredCCIPercentage: any;
  totalSales: number;
  totalCCI: number;
  currentSales?: number;
  currentWonCCIPercentage?: number;
  currentNetRevenue?: number;
  currentDeliveredCCIDollar?: number;
  currentDeliveredCCIPercentage?: any;
  currentTotalSales?: number;
  currentTotalCCI?: number;
  targetSales?: number;
  targetWonCCIPercentage?: number;
  targetNetRevenue?: number;
  targetDeliveredCCIDollar?: number;
  targetDeliveredCCIPercentage?: any;
  targetTotalSales?: number;
  targetTotalCCI?: number;
  serviceGroups: Array<Record<string, any>>;
  clientServiceGroup: Record<string, string>;
  location: FullLocation;
  wmu: Record<string, any>;
  industryCode?: string;
  phasingMonthly: Array<SpeculativePhasingPeriod>;
  projectedData?: Array<Record<string, number>>;
  detailView: string;
  comment?: string;
  lastUpdatedBy?: string;
  profitUplift?: Array<Record<string, any>>;
}

export class Speculative extends SpeculativeSchema {
  constructor(filterAttributes?: SpeculativeSchema) {
    super();
    assign(this, filterAttributes);
  }

  initialize(): void {
    this.serviceGroups = [];
    this.clientServiceGroup = {};
    this.location = new FullLocation();
    this.wmu = {};
    this.phasingMonthly = [];
    this.projectedData = [];
  }

  isNew(): boolean {
    return !this.id || this.id === '';
  }
}
