import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  Input,
  OnInit,
} from '@angular/core';
import { combineLatest, Subscription } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { v4 as uuidv4 } from 'uuid';

import { MetricType } from 'src/app/shared/constants/metrics.constants';
import { RequestPayload } from 'src/app/shared/services/entities/request-payload';
import { SelectedFilters } from 'src/app/shared/services/entities/filters/selected-filters';
import { Tile } from '../entities/tile';
import { ValueType } from 'src/app/shared/constants/common.constants';
import { TileItem } from '../entities/tile-item';
import {
  PLTileDropdownConstants,
  ProfitUpliftTileConstants,
  ProfitTileErrorMessages,
  TotalProfitUpliftTile,
  ProfitUpTileSize,
  ProfitUpTileMode,
  PLProjTileDropdownConstants,
  ProfitLeverSections,
} from './constants/profituplift-tile.constants';

import { FiltersService } from 'src/app/shared/services/filters.service';
import { ErrorHandlerService } from 'src/app/core/services/error-handler.service';
import { Filters } from 'src/app/shared/services/entities/filters/filters';
import { TextValuePair } from 'src/app/shared/services/entities/common/key-value';
import {
  NgIf,
  NgClass,
  NgFor,
  NgSwitch,
  NgSwitchCase,
  NgSwitchDefault,
} from '@angular/common';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { DropDownComponent } from '../../base/drop-down/drop-down.component';
import { SpinnerComponent } from '../../base/spinner/spinner.component';
import {
  Periods,
  TimePeriodCodes,
} from 'src/app/shared/constants/filters.constants';
import Dictionary from 'src/app/core/utils/dictionary.utils';
import { TileUtils } from '../utils/tile.utils';
import { ProfitupliftTileService } from 'src/app/shared/services/tiles/profituplift-tile.service';
import {
  TileDefinition,
  TileItemDefinition,
} from '../entities/tile-definition';
import {
  ProfitLeverDetailsResponse,
  ProfitUpExpandDetailResponse,
  ProfitUpliftTileResponse,
  PUSummaryCCIpctTileResponse,
  PUSummaryCCITileResponse,
  PUSummarySalesRevTileResponse,
} from './entities/profituplift-tile-response';
import { cloneDeep } from 'lodash';
import { ProfitLevers } from 'src/app/shared/services/entities/filters/profitUplift';
import { ProfitUpliftModalService } from 'src/app/shared/services/tiles/profituplift-modal.service';
import { ProfitUpliftModalConfig } from '../profituplift-modal/entities/profituplift-modal-config';
import { ProfitUpliftModalTypeChanged } from '../profituplift-modal/entities/profituplift-modal-events';
import { SpeculativeGridUtils } from 'src/app/modules/speculative/components/speculative-grid/utils/speculative-grid.utils';
import { SwitchComponent } from '../../base/switch/switch.component';
import { StorageUtils } from 'src/app/core/utils/storage.utils';
import { StorageType } from 'src/app/core/constants/storage.constants';
@Component({
  selector: 'app-profituplift-tile',
  templateUrl: './profituplift-tile.component.html',
  styleUrls: ['./profituplift-tile.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    NgClass,
    NgFor,
    SpinnerComponent,
    DropDownComponent,
    NgbTooltipModule,
    SwitchComponent,
    NgSwitch,
    NgSwitchCase,
    NgSwitchDefault,
  ],
})
export class ProfitupliftTileComponent implements OnDestroy, OnInit {
  @Input() isProfitUpReq = false;
  @Input() profitUpMode = '';

  subscription = new Subscription();

  loaded: boolean;
  hasErrors: boolean;
  errorMessage: string;
  request: RequestPayload;
  type: MetricType;
  selectedFilters: SelectedFilters;
  filters: Filters;
  profitUpTile: Tile;
  isActuals = false;
  tmpItems: Array<string>;
  types: Array<TextValuePair> = [];
  selectedType: TextValuePair;
  id: string = uuidv4();
  response: Array<any>;
  isPercentage = false;
  allowPercentage = false;
  tileSize: string = ProfitUpTileSize.medium;
  summaryMode: string = ProfitUpTileMode.summary;
  expDetlMode: string = ProfitUpTileMode.expandedDetailed;

  constructor(
    private baseProfitUpTileService: ProfitupliftTileService,
    private basefilterService: FiltersService,
    private baseErrorHandlerService: ErrorHandlerService,
    private baseChangeDetector: ChangeDetectorRef,
    private profitUpliftModalService: ProfitUpliftModalService
  ) {}

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngOnInit(): void {
    this.subscription.add(
      combineLatest([
        this.basefilterService.selectedFiltersChanged,
        this.basefilterService.globalFiltersChanged,
      ]).subscribe(
        ([selectedFiltersCh, globalFilterCh]: [SelectedFilters, Filters]) => {
          this.selectedFilters = selectedFiltersCh;
          this.isActuals = selectedFiltersCh.timeframe.isPast;
          this.request = RequestPayload.createRequest(
            selectedFiltersCh,
            this.getExtraParams()
          );
          this.filters = globalFilterCh;

          if (!this.types.length) {
            this.getTypes(
              this.profitUpMode
                ? PLProjTileDropdownConstants
                : PLTileDropdownConstants
            );
          }
          this.creatingTile();
        }
      )
    );

    this.subscription.add(
      this.profitUpliftModalService.typeChanged.subscribe(
        (x: ProfitUpliftModalTypeChanged) => {
          this.type = x.type;
          this.selectedType = this.types.find(
            (x: TextValuePair) => x.value === this.type
          );
          this.creatingTile(() => {
            this.onOpenProfitModalByType();
            if (x.callback) {
              x.callback();
            }
          });
        }
      )
    );
  }

  creatingTile(callback?: () => void) {
    this.getSessionSettings();
    this.loadStarted();
    this.clearTile();

    switch (this.profitUpMode) {
      case ProfitUpTileMode.summary:
        this.serviceSummary();
        break;
      case ProfitUpTileMode.expandedDetailed:
        this.serviceExpandedDetailed();
        break;
      default:
        this.serviceShowProfitLever(callback);
        break;
    }
  }

  private serviceShowProfitLever(callback?: () => void): void {
    this.baseProfitUpTileService
      .getTileDataByType<ProfitUpliftTileResponse>(this.type, this.request)
      .then((y: ProfitUpliftTileResponse) => {
        this.response = this.fullListItems(y);
        const definition = this.setDefinition(
          this.response,
          ProfitUpliftTileConstants.ProfitTileDefinition
        );
        this.profitUpTile = TileUtils.getItems<Array<ProfitUpliftTileResponse>>(
          definition,
          this.response,
          this.setParams()
        );
        this.profitUpTile = this.setTileValues(
          this.profitUpTile,
          this.response
        );
      })
      .catch((error: HttpErrorResponse) => {
        this.handleTileError(error);
      })
      .finally(() => {
        this.loadCompleted();
        if (callback) {
          callback();
          callback = null;
        }
      });
  }

  private serviceExpandedDetailed(callback?: () => void) {
    this.tileSize = ProfitUpTileSize.large;
    this.allowPercentage = this.type === MetricType.Cci;
    Promise.all(
      this.getSpecBacklogService(this.type, this.request, this.profitUpMode)
    )
      .then((z: Array<ProfitUpExpandDetailResponse>) => {
        this.profitUpTile = this.getExpandedDetailedTile(this.type, z);
      })
      .catch((error: HttpErrorResponse) => {
        this.handleTileError(error);
      })
      .finally(() => {
        this.loadCompleted();
        if (callback) {
          callback();
          callback = null;
        }
      });
  }

  private serviceSummary(callback?: () => void) {
    this.tileSize = ProfitUpTileSize.large;
    this.allowPercentage = this.type === MetricType.Cci;
    Promise.all(
      this.getSpecBacklogService(this.type, this.request, this.profitUpMode)
    )
      .then(
        (
          summaryResponse:
            | PUSummarySalesRevTileResponse[]
            | [PUSummaryCCITileResponse, PUSummaryCCIpctTileResponse]
        ) => {
          this.profitUpTile = this.getSummaryTile(this.type, summaryResponse);
        }
      )
      .catch((error: HttpErrorResponse) => {
        this.handleTileError(error);
      })
      .finally(() => {
        this.loadCompleted();
        if (callback) {
          callback();
          callback = null;
        }
      });
  }

  onTypeChanged(type: TextValuePair): void {
    this.selectedType = type;
    this.type = this.selectedType.value;
    this.creatingTile();
  }

  getTotal(): TileItem {
    const valueType: ValueType =
      this.isPercentage && this.type === MetricType.Cci && this.profitUpMode
        ? ValueType.Percentage
        : ValueType.Numeric;

    return this.profitUpTile ? this.profitUpTile.getTotal(valueType) : null;
  }

  getItems(): Array<TileItem> {
    const valueType: ValueType =
      this.isPercentage && this.type === MetricType.Cci && this.profitUpMode
        ? ValueType.Percentage
        : ValueType.Numeric;

    return this.profitUpTile ? this.profitUpTile.getItems(valueType) : [];
  }

  onValueTypeSwitchChanged(isNumeric: boolean): void {
    this.isPercentage = !isNumeric;

    if (this.allowPercentage) {
      StorageUtils.set(
        StorageType.SessionStorage,
        `profit-proj-tile-percentage-${this.type}-${this.profitUpMode}`,
        this.isPercentage
      );
    }
  }

  getSessionSettings(): void {
    const isPercentageString: string = StorageUtils.get(
      StorageType.SessionStorage,
      `profit-proj-tile-percentage-${this.type}-${this.profitUpMode}`
    );
    this.isPercentage = isPercentageString
      ? isPercentageString === 'true'
      : this.allowPercentage;
  }

  onOpenProfitModalByType(): void {
    if (!this.profitUpMode) {
      //Summary and ExpandDetailed pending...
      if (!this.profitUpMode) {
        //Summary and ExpandDetailed pending...
        this.profitUpliftModalService.openTrendModalEmitted.emit(
          new ProfitUpliftModalConfig({
            response: this.response,
            type: this.type,
            tileParentId: this.id,
            filters: this.filters,
            selectedFilters: this.selectedFilters,
            tileTotal: this.getTotal()?.getValue(),
          })
        );
      }
    }
  }

  private getExtraParams(): Record<string, any> {
    return {
      isProfitUp: 'true', //Always show Profit Tile information (this.selectedFilters.isPUdtls.toString()),
      profitLevers: SpeculativeGridUtils.getProfitLevers(
        this.selectedFilters,
        this.filters
      ),
    };
  }

  private getTypes(dropdownValue: any): void {
    const keys: Array<string> = Object.keys(dropdownValue);
    this.type = MetricType.Cci; //Default selection

    keys.forEach((x: string) => {
      const textValuePair = new TextValuePair({
        text: (dropdownValue as any)[x].title,
        value: (dropdownValue as any)[x].type,
      });

      if (textValuePair.value === this.type) {
        this.selectedType = textValuePair;
      }

      this.types.push(textValuePair);
    });
  }

  private loadStarted(): void {
    this.loaded = false;
  }

  private loadCompleted(): void {
    this.loaded = true;
  }

  private fullListItems(
    callResponse: ProfitUpliftTileResponse
  ): Array<ProfitLeverDetailsResponse> {
    const sortedResponse = this.sortByAmoutPLPosition(callResponse);

    const total = TotalProfitUpliftTile;
    total.value = callResponse.total;

    sortedResponse.unshift(total);

    return sortedResponse;
  }

  private handleTileError(error: HttpErrorResponse): void {
    this.hasErrors = true;

    switch (error.status as any) {
      case 504:
        this.errorMessage = ProfitTileErrorMessages.Service504;
        break;
      default:
        this.errorMessage = ProfitTileErrorMessages.ServiceDefault;
        break;
    }

    this.baseErrorHandlerService.handleError(error);
    this.baseChangeDetector.detectChanges();
  }

  private setParams(
    allowPercentage = false,
    isPercentage = false
  ): Dictionary<any> {
    const params = new Dictionary<any>();
    params.add('metric', this.type);
    params.add('allowPercentage', allowPercentage);
    params.add('isPercentage', isPercentage);
    params.add('isActuals', this.isActuals);
    const isSingleMonth: boolean = [
      TimePeriodCodes.CurrentMonth,
      TimePeriodCodes.MonthToDate,
    ].includes(this.selectedFilters.timeframe.code);
    params.add('period', isSingleMonth ? Periods.Month.id : Periods.Quarter.id);

    return params;
  }

  private clearTile(): void {
    this.profitUpTile = null;
    this.hasErrors = false;
    this.errorMessage = '';
  }

  private setTileValues(
    profitUpTile: Tile,
    values: ProfitLeverDetailsResponse[]
  ): Tile {
    const tile = cloneDeep(profitUpTile);

    tile.items.forEach((item: TileItem) => {
      item.value = values.find(
        (x: ProfitLeverDetailsResponse) => x.name === item.title
      ).value;
    });
    tile.totals[0].value = values.find(
      (x: ProfitLeverDetailsResponse) => x.name === tile.totals[0].title
    ).value;

    return tile;
  }

  private sortByAmoutPLPosition(
    callResponse: ProfitUpliftTileResponse
  ): Array<ProfitLeverDetailsResponse> {
    const response = callResponse.profitLevers;
    const profitLeverMap = new Map(
      this.filters.profitLevers.map((pl) => [pl.ProfitLeverId, pl])
    );

    return response
      .filter((pl) => profitLeverMap.has(pl.id))
      .sort((a, b) => {
        if (b.value !== a.value) {
          return b.value - a.value;
        }

        const indexA = [...profitLeverMap.keys()].indexOf(a.id);
        const indexB = [...profitLeverMap.keys()].indexOf(b.id);
        return indexA - indexB;
      })
      .map((pl) => ({
        ...pl,
        name: profitLeverMap.get(pl.id)?.ProfitLeverName || '',
        shortName: profitLeverMap.get(pl.id)?.ProfitLeverShortName || '',
      }));
  }

  private setDefinition(
    response: ProfitLeverDetailsResponse[],
    tileDefinition: TileDefinition,
    elementsToShow = 4
  ): TileDefinition {
    const definition = { ...tileDefinition };
    const items = response
      .slice(0, elementsToShow)
      .map((element) => this.createTileItem(element));

    definition.items = items;
    return definition;
  }

  private createTileItem(
    element: ProfitLeverDetailsResponse
  ): TileItemDefinition {
    const isTotal = element.id.toLowerCase() === TotalProfitUpliftTile.id;

    return {
      id: element.id,
      title: element.name,
      link: element.shortName,
      typeFnc: (params: Dictionary<any>) => {
        return params.getItem('isPercentage')
          ? ValueType.Percentage
          : ValueType.Numeric;
      },
      valueFnc: () => 0,
      isTotal,
      negativeStyleEnabled: true,
      positiveStyleEnabled: isTotal,
    };
  }

  private getSpecBacklogService(
    type: MetricType,
    request: RequestPayload,
    tileType: string
  ) {
    const calls = [
      this.baseProfitUpTileService.getSpecBacklogData<any>(
        type,
        request,
        false,
        tileType
      ),
    ];

    if (type === MetricType.Cci) {
      calls.push(
        this.baseProfitUpTileService.getSpecBacklogData<any>(
          type,
          request,
          true,
          tileType
        )
      );
    }
    return calls;
  }

  private getSummaryTile(
    type: MetricType,
    response:
      | PUSummarySalesRevTileResponse[]
      | [PUSummaryCCITileResponse, PUSummaryCCIpctTileResponse]
  ): Tile {
    if (type === MetricType.Cci) {
      const params1 = this.setParams(true, false);
      const params2 = this.setParams(true, true);

      return TileUtils.combineTiles([
        TileUtils.getItems<any>(
          ProfitUpliftTileConstants.summaryTile,
          response[0],
          params1
        ),
        TileUtils.getItems<any>(
          ProfitUpliftTileConstants.summaryTile,
          response[1],
          params2
        ),
      ]);
    } else {
      const params = this.setParams();

      return TileUtils.getItems<any>(
        ProfitUpliftTileConstants.summaryTile,
        response[0],
        params
      );
    }
  }

  private getExpandedDetailedTile(
    type: MetricType,
    data: ProfitUpExpandDetailResponse[]
  ): Tile {
    const sections = [
      ProfitLeverSections.Speculative,
      ProfitLeverSections.Backlog,
    ];
    const expandeDefinition = ProfitUpliftTileConstants.expandedDetailed;
    const params =
      type === MetricType.Cci
        ? [this.setParams(true, false), this.setParams(true, true)]
        : [this.setParams()];

    const tiles = this.generateExpDtldTile(
      data,
      sections,
      expandeDefinition,
      params
    );

    if (type === MetricType.Cci) {
      return TileUtils.combineTiles([tiles[0], tiles[1]]);
    } else {
      return tiles[0];
    }
  }

  private generateExpDtldTile(
    data: ProfitUpExpandDetailResponse[],
    sections: ProfitLeverDetailsResponse[],
    constDefinition: TileDefinition,
    params: Dictionary<any>[]
  ): Array<Tile> {
    const tilesGenerated = [];

    data.forEach((valueNumPct, NumPctIndex) => {
      let tileBySection: Tile;
      const valObject = this.getNumPctObject(valueNumPct);

      sections.forEach((section, index) => {
        const tileSection = this.getTileSection(valObject[index], section);
        const definition = this.setDefinition(tileSection, constDefinition, 5);

        const tile = this.getTileValues(
          definition,
          tileSection,
          params[NumPctIndex]
        );

        if (!tileBySection) {
          tileBySection = tile;
        } else {
          tileBySection.items = [...tileBySection.items, ...tile.items];
        }
      });

      tileBySection.totals[0].value = valueNumPct.total;
      tilesGenerated.push(tileBySection);
    });

    return tilesGenerated;
  }

  private getNumPctObject(
    value: ProfitUpExpandDetailResponse
  ): Array<ProfitUpliftTileResponse> {
    return [
      {
        profitLevers: value.speculativePU,
        total: value.speculativePUTotal,
      },
      {
        profitLevers: value.backlogPU,
        total: value.backlogPUTotal,
      },
    ];
  }

  private getTileSection(
    response: ProfitUpliftTileResponse,
    sectionHeader: ProfitLeverDetailsResponse
  ): Array<ProfitLeverDetailsResponse> {
    const itemsList = this.fullListItems(response);
    sectionHeader.value = response.total;
    itemsList.unshift(sectionHeader);

    return itemsList;
  }

  private getTileValues(
    definition: TileDefinition,
    section: ProfitLeverDetailsResponse[],
    params: Dictionary<any>
  ): Tile {
    const tile = TileUtils.getItems<any>(definition, section, params);
    return this.setTileValues(tile, section);
  }
}
