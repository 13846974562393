<div *ngIf="isAnalytics" class="ddl-control d-inline-block" ngbDropdown #favoriteDropdown="ngbDropdown"
    placement="bottom-center">
    <div class="favorite-icon" id="ddlFavorite" ngbDropdownToggle>
        <fa-icon [icon]="faStarPainted">
        </fa-icon>
        <span>FAVORITES ({{favorites ? favorites.length - 1 : 0}})</span>
    </div>
    <div ngbDropdownMenu aria-labelledby="ddlFavorite" class="ddl-content">
        <app-spinner name="favorites-picker-spinner" [showSpinner]="!loaded" [fullScreen]="false"
            spinnerColor="#3e4851">
        </app-spinner>
        <div *ngIf="loaded" class="ddl-header"><span>Default</span></div>
        <div *ngIf="loaded" class="ddl-inputs">
            <ul class="favorites-items">
                <li *ngFor="let f of favorites" class="favorites-item">
                    <div class="favorite-item">
                        <div class="favorite-header" (click)="onSelect(f)">
                            <fa-icon [icon]="f.default ? faStarPainted : faStar" id="favorite"
                                (click)="onDefaultChanged(f)"></fa-icon>
                            <span>{{f.name}}</span>
                        </div>
                        <div *ngIf="f.name !== noneFavoriteName" class="favorite-actions">
                            <fa-icon [icon]="faShare" title="Share Favorite" id="share" class="me-2"
                                (click)="onShare(f)">
                            </fa-icon>
                            <fa-icon [icon]="faEdit" title="Edit Favorite" id="edit" class="me-2" (click)="onEdit(f)">
                            </fa-icon>
                            <fa-icon [icon]="faTrash" title="Delete Favorite" id="delete" class="me-2"
                                (click)="onDelete(f)">
                            </fa-icon>
                            <fa-icon [icon]="faCopy" title="Duplicate Favorite" id="content_copy"
                                (click)="onEdit(f, true)"></fa-icon>
                        </div>
                    </div>
                </li>
            </ul>
            <div>
                <button class="btn btn-primary" (click)="onOpenFavoriteModal()">Add Favorite</button>
            </div>
        </div>
    </div>
</div>
<app-share-favorite-modal></app-share-favorite-modal>
