import { NavBarTab } from '../services/entities/navbar-tab';

export const AppModulePaths = {
  overview: {
    id: 'overview',
    path: 'overview',
    fullpath: 'overview',
    text: 'Overview',
  },
  backlog: {
    id: 'backlog',
    path: 'backlog',
    fullpath: 'backlog',
    text: 'Backlog',
  },
  ieopportunity: {
    id: 'ieopportunity',
    path: 'ieopportunity',
    fullpath: 'ieopportunity',
    text: 'Projection %',
    subtabs: {
      opportunities: {
        id: 'opportunities',
        path: 'opportunities',
        fullpath: 'ieopportunity/opportunities',
        text: 'Opportunities',
      },
      actualsales: {
        id: 'actual-sales',
        path: 'actualsales',
        fullpath: 'ieopportunity/actualsales',
        text: 'Actual Sales',
      },
      wins: {
        id: 'wins',
        path: 'wins',
        fullpath: 'ieopportunity/wins',
        text: 'Wins',
      },
    },
  },
  phasing: {
    id: 'phasing',
    path: 'phasing',
    fullpath: 'phasing',
    text: 'Phasing',
  },
  speculative: {
    id: 'speculative',
    path: 'speculative',
    fullpath: 'speculative',
    text: 'Speculative',
  },
  analytics: {
    id: 'analytics',
    path: 'analytics',
    fullpath: 'analytics',
    text: 'Analytics',
  },
  profituplift: {
    id: 'profituplift',
    path: 'profituplift',
    fullpath: 'profituplift',
    text: 'Profit Uplift',
  },
};

export const AppNavBarTabs: Array<NavBarTab> = [
  new NavBarTab({
    id: AppModulePaths.overview.id,
    text: AppModulePaths.overview.text,
    url: `/${AppModulePaths.overview.path}`,
  }),
  new NavBarTab({
    id: AppModulePaths.backlog.id,
    text: AppModulePaths.backlog.text,
    url: `/${AppModulePaths.backlog.path}`,
  }),
  new NavBarTab({
    id: AppModulePaths.ieopportunity.id,
    text: AppModulePaths.ieopportunity.text,
    url: `/${AppModulePaths.ieopportunity.path}`,
    subtabs: [
      new NavBarTab({
        id: AppModulePaths.ieopportunity.subtabs.opportunities.id,
        text: AppModulePaths.ieopportunity.subtabs.opportunities.text,
        url: AppModulePaths.ieopportunity.subtabs.opportunities.path,
      }),
      new NavBarTab({
        id: AppModulePaths.ieopportunity.subtabs.actualsales.id,
        text: AppModulePaths.ieopportunity.subtabs.actualsales.text,
        url: AppModulePaths.ieopportunity.subtabs.actualsales.path,
      }),
      new NavBarTab({
        id: AppModulePaths.ieopportunity.subtabs.wins.id,
        text: AppModulePaths.ieopportunity.subtabs.wins.text,
        url: AppModulePaths.ieopportunity.subtabs.wins.path,
      }),
    ],
  }),
  new NavBarTab({
    id: AppModulePaths.phasing.id,
    text: AppModulePaths.phasing.text,
    url: `/${AppModulePaths.phasing.path}`,
  }),
  new NavBarTab({
    id: AppModulePaths.speculative.id,
    text: AppModulePaths.speculative.text,
    url: `/${AppModulePaths.speculative.path}`,
  }),
  new NavBarTab({
    id: AppModulePaths.analytics.id,
    text: AppModulePaths.analytics.text,
    url: `/${AppModulePaths.analytics.path}`,
  }),
];

export const ProfitTab: NavBarTab =
  //TODO: Include below object to AppNavBarTabs when ProfiTab is fully launched.
  new NavBarTab({
    id: AppModulePaths.profituplift.id,
    text: AppModulePaths.profituplift.text,
    url: `/${AppModulePaths.profituplift.path}`,
  });
