<ng-template #shareBoardModal let-modal>
  <div class="modal-body">
    <div class="modal-header mt-3">
      <div class="header-left">
        <label>Client Business Projection</label>
      </div>
      <div class="header-right me-1">
        <button class="btn btn-link close-icon" (click)="onCloseModal()">
          <span>
            Close
            <fa-icon [icon]="faTimes">
            </fa-icon>
          </span>
        </button>
      </div>
    </div>
    <div class="pe-3 ps-3 share-container">
      <div class="mt-3 share-title">
        <label>Share: {{config?.board.name}}</label>
      </div>
      <div class="mt-3 share-people-search">
        <label>People:</label>
        <app-auto-complete [search]="personSearch" placeholder="To: Enter Enterprise ID"
          (selectedItemChangedEvent)="onPersonSelected($event)" [clearAfterSelect]="true">
        </app-auto-complete>
      </div>
      <div *ngIf="selectedPeople?.length > 0" class="share-people-container">
        <div *ngFor="let person of selectedPeople" class="share-person-item">
          <label class="me-3">{{person.EnterpriseID}}</label>
          <fa-icon [icon]="faTimes" (click)="onDeletePerson(person)">
          </fa-icon>
        </div>
      </div>
      <div class="copy-clipboard-container">
        <button class="btn copy-clipboard-btn" ngxClipboard (cbOnSuccess)="onCopied($event)"
          [cbContent]="boardLink">Copy link to {{config?.board.name}} <i>(i.e Board Name)</i></button>
        <div class="mt-2" *ngIf="isCopied">
          <span>Link copied!</span>
        </div>
      </div>
      <div class="text-end mt-3 mb-3">
        <button class="btn btn-primary me-3" [disabled]="selectedPeople?.length < 1"
          (click)="onShare()">Confirm</button>
        <button class="btn btn-secondary" (click)="onCloseModal()">Cancel</button>
      </div>
    </div>
  </div>
</ng-template>