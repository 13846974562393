import { Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import {
  NavigationEnd,
  Router,
  RouterLink,
  RouterLinkActive,
} from '@angular/router';
import { isUndefined, remove } from 'lodash';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Subscription } from 'rxjs';
import { StorageType } from 'src/app/core/constants/storage.constants';
import { StorageUtils } from 'src/app/core/utils/storage.utils';

import { environment } from 'src/environments/environment';
import { AppNavBarTabs, ProfitTab } from '../../constants/navbar.constants';
import { Customer } from '../../services/entities/filters/customer';
import { CustomerFilters } from '../../services/entities/filters/customer-filters';
import { FinancialCustomer } from '../../services/entities/filters/financial-customer';
import { SelectedFilters } from '../../services/entities/filters/selected-filters';
import { NavBarTab } from '../../services/entities/navbar-tab';

import { FiltersService } from '../../services/filters.service';
import { SharedService } from '../../services/shared.service';

import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { BetaMessageService } from '../../services/beta-message.service';
import { AppStateService } from '../../services/app-state.service';
import { AppState } from '../../services/entities/app-state/app-state';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { DataRefreshComponent } from '../data-refresh/data-refresh.component';
import { CurrencyPickerComponent } from '../currency-picker/currency-picker.component';
import { TimeframePickerComponent } from '../timeframe-picker/timeframe-picker.component';
import { FeatureToggleComponent } from '../feature-toggle/feature-toggle.component';
import { NgFor, NgClass, NgIf } from '@angular/common';
import { RebarAuthService } from 'src/app/core/rebarauth/rebar.auth.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  standalone: true,
  imports: [
    NgFor,
    RouterLink,
    RouterLinkActive,
    NgClass,
    NgIf,
    FeatureToggleComponent,
    TimeframePickerComponent,
    CurrencyPickerComponent,
    DataRefreshComponent,
    FontAwesomeModule,
  ],
})
export class NavbarComponent implements OnInit, OnDestroy {
  subscription = new Subscription();
  tabs: Array<NavBarTab>;
  selectedTab: NavBarTab;
  logOutPath = environment.logOutPath;
  dropdownOpen = false;

  faSignOutAlt = faSignOutAlt as IconProp;
  isCurrentTabBeta = false;
  flipflopValueEnabled: boolean;
  isCloud = false;
  betaAccessUserList = [];
  isBetaUser: boolean;
  updateClass: boolean;
  constructor(
    private router: Router,
    private filtersService: FiltersService,
    private sharedService: SharedService,
    private el: ElementRef,
    private betaMessage: BetaMessageService,
    private appStateService: AppStateService,
    private rebarAuth: RebarAuthService
  ) {}

  ngOnInit(): void {
    this.subscription.add(
      this.appStateService.appStateChanged.subscribe((x: AppState) => {
        this.betaAccessUserList = x.betaAccessUserList; //TODO: Comment this line when ProfiTab is fully launched.
        // this.betaAccessUserList = ['']; TODO: Uncomment when ProfiTab is fully launched.
        this.isBetaUser = this.betaAccessUserList.includes(
          this.sharedService.getEnterpriseId()
        );
        //TODO: Remove both 'if and else if' when ProfiTab is fully launched.
        if (this.isBetaUser && !this.tabs.includes(ProfitTab)) {
          this.tabs.push(ProfitTab);
        } else if (!this.isBetaUser && this.tabs.includes(ProfitTab)) {
          this.tabs.pop();
        }
      })
    );
    this.getNavTabs();
    this.getActiveNavTab();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  getNavTabs(): void {
    this.tabs = AppNavBarTabs;

    this.tabs.forEach((t: NavBarTab) => {
      const entry: [string, boolean] = Object.entries(
        environment.tabsAvailability
      ).find((x: [string, boolean]) => x[0] === t.id);
      t.enable = entry ? entry[1] : false;
    });
  }

  getActiveNavTab(): void {
    this.subscription.add(
      this.router.events.subscribe((e) => {
        if (e instanceof NavigationEnd) {
          this.betaMessage.updateBetaMessage(this.isCurrentTabBeta);
          this.changeSelectedTab(
            this.tabs.find((x: NavBarTab) => e.url.startsWith(x.url)),
            this.isCurrentTabBeta
          );
          this.saveTabForMetrics(e);
        }
      })
    );
  }

  saveTabForMetrics(navigation: NavigationEnd): void {
    this.subscription.add(
      this.filtersService.customerFiltersChanged.subscribe(
        (x: CustomerFilters) => {
          const path: Array<string> = navigation.urlAfterRedirects.split('/');
          remove(path, (y: string) => isUndefined(y) || y.trim().length === 0);
          let result: string;
          let tab: NavBarTab;

          path.forEach((y: string) => {
            if (!tab) {
              tab = this.tabs.find((z: NavBarTab) => z.url === `/${y}`);
              result = tab?.text;
            } else if (tab && tab.subtabs?.length > 0) {
              tab = tab.subtabs.find((z: NavBarTab) => z.url === y);
              result += ` - ${tab?.text}`;
            }
          });

          const cachedFilters: string = StorageUtils.get(
            StorageType.LocalStorage,
            'selectedFilters'
          );

          if (cachedFilters) {
            const selectedFilters = new SelectedFilters(
              JSON.parse(cachedFilters)
            );

            this.sharedService.saveTabForMetrics(
              result,
              selectedFilters.customer
            );
          }
        }
      )
    );
  }

  changeSelectedTab(t: NavBarTab, isTargetTabBeta?: boolean): void {
    if (this.isCurrentTabBeta !== isTargetTabBeta) {
      this.isCurrentTabBeta = isTargetTabBeta;
      if (this.selectedTab.id == t.id) {
        this.betaMessage.updateBetaMessage(isTargetTabBeta);
      }
    }

    this.updateClass = t?.id == 'ieopportunity' && !this.isCurrentTabBeta;
    this.selectedTab = t;
    this.dropdownOpen = false;
    if (isTargetTabBeta) {
      this.tabs.forEach((t: NavBarTab) => {
        const myTag = this.el.nativeElement.querySelector('#' + t.id);
        myTag.classList.remove('navbar-tab-active');
      });
    }
  }

  toggleDropdown(): void {
    this.dropdownOpen = !this.dropdownOpen;
  }
  logout() {
    this.rebarAuth.logout();
  }
}
