<ng-template #profitupliftModal let-modal>
    <div class="profit-modal-header">
        <div class="profit-modal-title">
            <app-drop-down [items]="types" [selectedItem]="selectedType"
                (itemSelectionChangeEvent)="onTypeChanged($event)">
            </app-drop-down>
        </div>
        <div class="profit-modal-filters">
            <div class="profit-filter">
                <button class="btn btn-link icon-button close-icon" (click)="onCloseModal()">
                    <span>
                        Close
                        <fa-icon [icon]="faTimes">
                        </fa-icon>
                    </span>
                </button>
            </div>
        </div>
    </div>
    <div *ngIf="loaded" class="profit-modal-total">
        <span class="profit-total">Total : {{config.tileTotal}}</span>
    </div>
    <div *ngIf="config.response && loaded" class="modal-body-table">
        <app-profituplift-table [config]="profitUpliftTableConfig" (closeModalEvent)="onCloseModal()"></app-profituplift-table>
    </div>
    <app-spinner name="profit-modal-spinner" [showSpinner]="!loaded" [fullScreen]="false" spinnerColor="#3e4851">
    </app-spinner>
</ng-template>