<ng-template #speculativeModal let-modal>
  <form autocomplete="off" *ngIf="headerForm" [formGroup]="headerForm" novalidate>
    <div *ngIf="loaded" class="container speculative-content">
      <div class="speculative-header mt-3">
        <div class="header-left">
          <div class="row ms-2">
            <div class="col-auto pe-0 ps-0">
              <label class="mb-0">Speculative*:</label>
            </div>
            <div class="col-auto pe-0">
              <input class="form-control form-control-sm spec-id" type="text" formControlName="id" readonly>
            </div>
            <div class="col-auto pe-0">
              <input class="form-control form-control-sm spec-name" type="text" placeholder="Speculative Name"
                formControlName="name" [readonly]="config.isReadOnly && !config.isAdmin" (click)="onInputClick($event)"
                [ngClass]="{'invalid' : !(headerForm.controls.name.valid || headerForm.controls.name.pristine)}">
            </div>
          </div>
        </div>
        <div class="header-right me-1">
          <button class="btn btn-link close-icon" (click)="onCloseModal()">
            <span>
              Close
              <fa-icon [icon]="faTimes">
              </fa-icon>
            </span>
          </button>
        </div>
      </div>
      <ngb-alert class="speculative-modal-alert mt-3 mb-0" *ngIf="!amountsAlert" (closed)="amountsAlert = true">
        <fa-icon class="me-2" [icon]="faExclamationCircle"></fa-icon>Remember! Speculative amounts must be entered in
        Thousands.
      </ngb-alert>
      <ngb-alert class="speculative-modal-alert mt-3 mb-0" *ngIf="!thousandsAlert" (closed)="thousandsAlert = true">
        <fa-icon class="me-2" [icon]="faExclamationCircle"></fa-icon>Remember! Please enter amounts in Thousands and in
        Global currency. Fx Adjusted / Constant currencies are not allowed.
      </ngb-alert>
      <div class="speculative-body mt-3">
        <div class="row">
          <div class="speculative-inputs">
            <label for="totalSales" style="margin-bottom: 0.5rem;">Total Sales*</label>
            <input class="form-control form-control-sm text-end" type="text" formControlName="sales"
              (click)="onInputClick($event)" (focus)="onNumberInputFocus($event, headerForm, 'sales', 1)"
              (blur)="onNumberInputBlur($event, headerForm, 'sales', 1)"
              [readonly]="config.isReadOnly && !config.isAdmin"
              [ngClass]="{'invalid' : !((headerForm.controls.sales.valid || headerForm.controls.sales.pristine) && hasValidMetrics), 'negative' : isNegative('sales', 1)}">
          </div>
          <div class="speculative-inputs">
            <label for="totalRevenue" style="margin-bottom: 0.5rem;">Total Revenue*</label>
            <input class="form-control form-control-sm text-end" type="text" formControlName="revenue"
              (click)="onInputClick($event)" (focus)="onNumberInputFocus($event, headerForm, 'revenue', 1)"
              (blur)="onNumberInputBlur($event, headerForm, 'revenue', 1)"
              [readonly]="config.isReadOnly && !config.isAdmin"
              [ngClass]="{'invalid' : !((headerForm.controls.revenue.valid || headerForm.controls.revenue.pristine) && hasValidMetrics), 'negative' : isNegative('revenue', 1)}">
          </div>
          <div class="speculative-inputs">
            <label for="totalCci$" style="margin-bottom: 0.5rem;">Total CCI$*</label>
            <input class="form-control form-control-sm text-end" type="text" formControlName="cci"
              (click)="onInputClick($event)" (focus)="onNumberInputFocus($event, headerForm, 'cci', 1)"
              (blur)="onNumberInputBlur($event, headerForm, 'cci', 1)" [readonly]="config.isReadOnly && !config.isAdmin"
              [ngClass]="{'invalid' : !((headerForm.controls.cci.valid || headerForm.controls.cci.pristine) && hasValidMetrics), 'negative' : isNegative('cci', 1)}">
          </div>
          <div class="speculative-inputs">
            <label for="totalCci%" style="margin-bottom: 0.5rem;">Total CCI%</label>
            <input class="form-control form-control-sm text-end" type="text" formControlName="cciPercentage"
              [ngClass]="{'negative' : isNegative('cciPercentage', 3)}" readonly>
          </div>
          <div class="speculative-inputs">
            <label for="currency" style="margin-bottom: 0.5rem;">Currency (thousands)</label>
            <input class="form-control form-control-sm" type="text" [value]="currencyDescription" readonly>
          </div>
        </div>
        <div class="row mt-3">
          <div class="speculative-inputs">
            <label for="reason" style="margin-bottom: 0.5rem;">Reason*</label>
            <select class="form-control form-control-sm" id="reason" formControlName="reasonCode"
              [attr.disabled]="config.isReadOnly && !config.isAdmin ? true : null">
              <option *ngFor="let r of config.reasonCodes" [value]="r.ReasonCodeId">{{ r.Description }}</option>
            </select>
          </div>
          <div class="speculative-inputs" style="width: 40%;">
            <label for="financialCustomer" style="margin-bottom: 0.5rem;">Financial Customer*</label>
            <input class="form-control form-control-sm" list="fc-item" id="financialCustomer" name="financialCustomer"
              formControlName="financialCustomerName" [readonly]="config.isReadOnly && !config.isAdmin"
              [ngClass]="{'invalid' : !(headerForm.controls.financialCustomerName.valid || headerForm.controls.financialCustomerName.pristine)}">
            <datalist id="fc-item">
              <option *ngFor="let fc of config.customerFilters.financialCustomer" [value]="fc.getFullName()">
            </datalist>
          </div>
          <div class="speculative-inputs" style="width: 40%;">
            <label for="responsible" style="margin-bottom: 0.5rem;">Responsible*</label>
            <div class="responsible-input">
              <app-auto-complete [search]="personSearch" [value]="speculative?.responsible"
                formControlName="responsible" [isReadOnly]="config.isReadOnly && !config.isAdmin"
                [ngClass]="{'invalid' : !(headerForm.controls.responsible.valid || headerForm.controls.responsible.pristine)}">
              </app-auto-complete>
            </div>
          </div>
        </div>
        <div class="row mt-3">
          <div class="speculative-inputs w-100">
            <label for="comments" style="margin-bottom: 0.5rem;">Comments</label>
            <input class="form-control form-control-sm" style="height: 50px;" type="text" formControlName="comment"
              [readOnly]="config.isReadOnly && !config.isAdmin">
          </div>
        </div>
      </div>
      <div class="row mt-3 speculative-tabs">
        <div class="col-12">
          <ul ngbNav #nav="ngbNav" class="nav-tabs" [destroyOnHide]="false">
            <li class="me-2" [ngbNavItem]="1" [ngClass]="{'disabled' : !sgTabEnable}" [disabled]="!sgTabEnable">
              <a ngbNavLink>SERVICE GROUP*</a>
              <ng-template ngbNavContent>
                <div class="tab-item pt-3 pb-3"
                  [ngClass]="{'disabled' : !sgTabEnable || (config.isReadOnly && !config.isAdmin)}">
                  <div class="service-groups-container">
                    <div class="service-groups-options col-6">
                      <app-selection-control (selectionChangeEvent)="onSgSelectionChanged($event)">
                      </app-selection-control>
                      <app-options-list [config]="sgListConfig" [startSelection]="startSelectionSgs"
                        [onSelectAllChangedObs]="sgSelectAllChangedEvent.asObservable()"
                        (itemSelectionChangeEvent)="onSgItemSelection($event)">
                      </app-options-list>
                    </div>
                    <div class="service-groups-assignment col-6" [formGroup]="sgForm">
                      <div *ngFor="let s of selectedSgs" class="service-group-assignment mb-1">
                        <div>
                          {{s.name}}:
                        </div>
                        <div>
                          <input class="form-control form-control-sm" type="text" [formControlName]="s.id"
                            (click)="onInputClick($event)" (focus)="onNumberInputFocus($event, sgForm, s.id, 3)"
                            (blur)="onNumberInputBlur($event, sgForm, s.id, 3)" (input)="calculateSgTotal()">
                        </div>
                      </div>
                      <div class="service-group-assignment mb-1">
                        <div>
                          <b>Total:</b>
                        </div>
                        <div>
                          <input class="form-control form-control-sm" type="text" formControlName="total" readonly>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-template>
            </li>
            <li class="me-2" [ngbNavItem]="2" [ngClass]="{'disabled' : !cgTabEnable}" [disabled]="!cgTabEnable">
              <a ngbNavLink>CLIENT GROUP*</a>
              <ng-template ngbNavContent>
                <div class="tab-item p-3"
                  [ngClass]="{'tab-content-disabled' : !cgTabEnable || (config.isReadOnly && !config.isAdmin) }">
                  <app-search-input placeholder="Search for Client Group"
                    (searchEvent)="onCgsSearchTextChanged($event)">
                  </app-search-input>
                  <div class="mt-4 pe-2 ps-2">
                    <app-options-list [config]="cgListConfig" [startSelection]="startSelectionCgs"
                      [searchText]="searchTextCgs" (itemSelectionChangeEvent)="onCgItemSelection($event)"
                      [isDisabled]="!cgTabEnable || (config.isReadOnly && !config.isAdmin)">
                    </app-options-list>
                  </div>
                </div>
              </ng-template>
            </li>
            <li class="me-2" [ngbNavItem]="3" [ngClass]="{'disabled' : !marketTabEnable}" [disabled]="!marketTabEnable">
              <a ngbNavLink>COUNTRY / BENORG*</a>
              <ng-template ngbNavContent>
                <div class="tab-item p-3"
                  [ngClass]="{'tab-content-disabled' : !marketTabEnable || (config.isReadOnly && !config.isAdmin)}">
                  <app-search-input placeholder="Search for Market" (searchEvent)="onMarketsSearchTextChanged($event)">
                  </app-search-input>
                  <div class="mt-4 pe-2 ps-2">
                    <app-tree-view [config]="marketsTreeViewConfig" [searchText]="searchTextMarkets"
                      [startSelection]="startSelectionMarkets"
                      (itemSelectionChangeEvent)="onMarketItemSelection($event)">
                    </app-tree-view>
                    <app-spinner name="speculative-modal-country-spinner" [showSpinner]="!marketsLoaded"
                      [fullScreen]="false" spinnerColor="#3e4851">
                    </app-spinner>
                  </div>
                </div>
              </ng-template>
            </li>
            <li class="me-2" [ngbNavItem]="4" [ngClass]="{'disabled' : !wmuTabEnable}" [disabled]="!wmuTabEnable">
              <a ngbNavLink>WORK MANAGEMENT UNIT</a>
              <ng-template ngbNavContent>
                <div class="tab-item p-3"
                  [ngClass]="{'tab-content-disabled' : !wmuTabEnable || (config.isReadOnly && !config.isAdmin)}">
                  <p class="optional-step-message">
                    <fa-icon [icon]="faExclamationCircle" class="me-2"></fa-icon>
                    Please, notice that this is an optional step.
                  </p>
                  <app-search-input placeholder="Search for WMU" (searchEvent)="onWmuSearchTextChanged($event)">
                  </app-search-input>
                  <div class="radio-btn-content mt-4 pe-2 ps-2">
                    <app-tree-view [config]="wmuTreeViewConfig" [searchText]="searchTextWmu"
                      [startSelection]="startSelectionWmus" (itemSelectionChangeEvent)="onWmuItemSelection($event)">
                    </app-tree-view>
                  </div>
                </div>
              </ng-template>
            </li>
            <li class="me-2" [ngbNavItem]="5" [ngClass]="{'disabled' : !industryTabEnable}"
              [disabled]="!industryTabEnable">
              <a ngbNavLink>INDUSTRY</a>
              <ng-template ngbNavContent>
                <div class="tab-item p-3"
                  [ngClass]="{'tab-content-disabled' : !industryTabEnable || (config.isReadOnly && !config.isAdmin)}">
                  <p class="optional-step-message">
                    <fa-icon [icon]="faExclamationCircle" class="me-2"></fa-icon>
                    Please, notice that this is an optional step.
                  </p>
                  <app-search-input placeholder="Search for Industry"
                    (searchEvent)="onIndustrySearchTextChanged($event)">
                  </app-search-input>
                  <div class="mt-4 pe-2 ps-2">
                    <app-tree-view [config]="industriesTreeViewConfig" [searchText]="searchTextIndustry"
                      [startSelection]="startSelectionIndustries"
                      (itemSelectionChangeEvent)="onIndustryItemSelection($event)">
                    </app-tree-view>
                  </div>
                </div>
              </ng-template>
            </li>
            <li class="me-2" [ngbNavItem]="6" [ngClass]="{'disabled' : !phasingTabEnable}"
              [disabled]="!phasingTabEnable">
              <a ngbNavLink>PHASING*</a>
              <ng-template ngbNavContent>
                <div class="tab-item phasing-scroll" [ngClass]="{'disabled' : !phasingTabEnable}">
                  <app-speculative-edit-grid [config]="editGridConfig" (phasingChangedEvent)="onPhasingChanged($event)"
                    class="modal-grid">
                  </app-speculative-edit-grid>
                </div>
              </ng-template>
            </li>
            <li *ngIf="this.headerForm.controls.reasonCode.value === 'PI' && isCloud" class="mr-2" [ngbNavItem]="7"
              [ngClass]="{'disabled' : !profitLeverTabEnable}" [disabled]="!profitLeverTabEnable">
              <a ngbNavLink>PROFIT UPLIFT</a>
              <ng-template ngbNavContent>
                <div class="tab-item pt-3 pb-3"
                [ngClass]="{'disabled' : !profitLeverTabEnable || (config.isReadOnly && !config.isAdmin)}">
                  <div class="service-groups-container">
                    <div class="service-groups-options col-5">
                      <app-selection-control (selectionChangeEvent)="onPUSelectionChanged($event)">
                      </app-selection-control>
                      <app-tree-view [config]="profitUpliftViewConfig"
                        (itemSelectionChangeEvent)="onPUItemSelection($event)"
                        [onSelectAllChangedObs]="puSelectAllChangedEvent.asObservable()"
                        [startSelection]="startSelectionPU">
                      </app-tree-view>
                    </div>
                    <div class="service-groups-assignment col-7" [formGroup]="puForm">
                      <div class="pu-total-container">
                        <div class="pu-text"><b>Total:</b></div>
                        <input class="form-control form-control-sm pu-input-flex" type="text" formControlName="totalPU"
                          readonly>
                      </div>
                      <div class="pu-headers">
                        <div class="pu-alignHeaders"></div>
                        <div class="pu-headersText"></div>
                        <div class="pu-headersText">Sales</div>
                        <div class="pu-headersText">Revenue</div>
                        <div class="pu-headersText">CCI $</div>
                      </div>
                      <div *ngFor="let s of selectedPU" class="service-group-assignment mb-1">
                        <div class="pu-text">{{s.name}}:</div>
                        <div class="pu-headers">
                          <input class="form-control form-control-sm pu-input-flex" type="text" [formControlName]="s.id"
                            (click)="onInputClick($event)" (focus)="onNumberInputFocus($event, puForm, s.id, 3)"
                            (blur)="onNumberInputBlur($event, puForm, s.id, 3)" (input)="calculatePUTotal()">
                          <input class="form-control form-control-sm pu-input-flex" type="text"
                            [formControlName]="s.id + '_sales'" readonly>
                          <input class="form-control form-control-sm pu-input-flex" type="text"
                            [formControlName]="s.id + '_revenue'" readonly>
                          <input class="form-control form-control-sm pu-input-flex" type="text"
                            [formControlName]="s.id + '_cci'" readonly>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-template>
            </li>
          </ul>
          <div [ngbNavOutlet]="nav" class="mt-2"></div>
        </div>
      </div>
      <div class="d-flex justify-content-between align-items-center mt-3 mb-3">
        <div>
          <ng-container *ngIf="!config.isNew()">
            <b>Last Updated By: </b>{{speculative?.updatedBy}}
          </ng-container>
        </div>
        <div>
          <button class="btn btn-secondary ms-3" (click)="onCloseModal()">Close</button>
          <button *ngIf="!config?.isReadOnly" class="btn btn-primary ms-3" [disabled]="!saveEnable"
            (click)="onSave()">Save</button>
          <button *ngIf="!config?.isReadOnly" class="btn btn-primary ms-3" [disabled]="!saveEnable"
            (click)="onSaveAndClose()">Save and
            Close</button>
        </div>
      </div>
    </div>
  </form>
  <app-spinner name="speculative-modal-spinner" [showSpinner]="!loaded" [fullScreen]="false" spinnerColor="#3e4851">
  </app-spinner>
</ng-template>