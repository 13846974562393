import { isUndefined, round } from 'lodash';

export class CalcUtils {
  static sumValues(total: number, value: number): number {
    return (total || 0) + (value || 0);
  }

  static calculateCustomPercentage(dividend: number, divisor: number): number {
    if (divisor !== 0) {
      const calculation: number =
        (Number(dividend) / Math.abs(Number(divisor))) * 100;
      return isFinite(calculation)
        ? Number(calculation.toFixed(1))
        : isNaN(calculation)
        ? undefined
        : 0;
    }

    return 0;
  }

  static calculateCciFollowsRevenue(
    revenue: number,
    totalRevenue: number,
    totalCci: number
  ): number {
    if (totalRevenue !== 0) {
      const calculation: number =
        (Number(revenue) / Number(totalRevenue)) * Number(totalCci);

      return isFinite(calculation)
        ? Number(calculation.toFixed(3))
        : isNaN(calculation)
        ? undefined
        : 0;
    }

    return 0;
  }

  static roundExponencial(value: number): number {
    return value.toString().indexOf('e-') > 0 ? round(value, 5) : value;
  }

  static roundAxisValue(value: number): number {
    if (!value || value === 0) {
      return 0;
    }

    const isNegative: boolean = value < 0;
    let result: string;
    const strValue: string = Math.abs(value).toFixed(0);
    result = (parseInt(strValue[0]) + 1).toString();

    for (let i = 1; i < strValue.length; i++) {
      result += 0;
    }

    return isNegative ? parseInt(result) * -1 : parseInt(result);
  }
}
