import { Component, OnInit } from '@angular/core';
import { combineLatest, filter, Subscription } from 'rxjs';

import { SelectedFilters } from 'src/app/shared/services/entities/filters/selected-filters';

import { FiltersService } from 'src/app/shared/services/filters.service';
import { SwitchComponent } from 'src/app/shared/components/base/switch/switch.component';
import { SharedModule } from 'src/app/shared-module';
import { NavigationStart, Router } from '@angular/router';
import { AppModulePaths } from 'src/app/shared/constants/navbar.constants';
import { NgIf, NgClass } from '@angular/common';
import { BetaMessageService } from 'src/app/shared/services/beta-message.service';
import { AppStateService } from 'src/app/shared/services/app-state.service';
import { AppState } from 'src/app/shared/services/entities/app-state/app-state';
import { ViewOptions } from 'src/app/shared/constants/grid.constants';

@Component({
  selector: 'app-profituplift-switch-filter',
  templateUrl: './profitUplift-switch-filter.component.html',
  styleUrls: ['./profitUplift-switch-filter.component.scss'],
  standalone: true,
  imports: [SharedModule, SwitchComponent, NgIf, NgClass],
})
export class ProfitUpliftSwitchComponent implements OnInit {
  subscription = new Subscription();

  isSpeculative: boolean;
  betaMessage: BetaMessageService;
  appStateService: AppStateService;
  isCloud: boolean;
  isFlipFlopFlagEnabled: boolean;
  isPUdtls = false;
  hasAccountPlan = false;
  isDisabled = false;
  isAdmin = false;
  isTargetCurrProj = false;

  constructor(
    private router: Router,
    private filtersService: FiltersService,
    appStateService: AppStateService,
    betaMessage: BetaMessageService
  ) {
    this.appStateService = appStateService;
    this.betaMessage = betaMessage;
  }

  ngOnInit() {
    this.subscription.add(
      combineLatest([
        this.router.events.pipe(
          filter((event: any) => event instanceof NavigationStart)
        ),
        this.filtersService.selectedFiltersChanged,
        this.appStateService.appStateChanged,
        this.betaMessage.isCloudSubject$,
      ]).subscribe(
        ([route, selectedFilter, appState, betaFlag]: [
          NavigationStart,
          SelectedFilters,
          AppState,
          Boolean
        ]) => {
          this.isPUdtls = selectedFilter.isPUdtls;
          this.isFlipFlopFlagEnabled = appState.gcpFlipFlopFlagEnabled;
          this.isTargetCurrProj =
            selectedFilter.target.value === ViewOptions.Current;

          if (route instanceof NavigationStart) {
            this.isSpeculative = route.url.includes(
              AppModulePaths.speculative.path
            );
          }

          this.isCloud =
            (betaFlag && !this.isFlipFlopFlagEnabled) ||
            (!betaFlag && this.isFlipFlopFlagEnabled);
        }
      )
    );
  }

  onSwitch(value: boolean): void {
    this.isPUdtls = value;
    this.filtersService.updateSelectedPUdtls(this.isPUdtls);
    this.filtersService.applyFilters();
  }
}
