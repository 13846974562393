import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { CellValueChangedEvent, ColDef, GridOptions } from 'ag-grid-community';
import { cloneDeep, has, isNull, isNumber, isUndefined, replace } from 'lodash';
import { ReplaySubject, Subscription } from 'rxjs';

import {
  GridConfig,
  GridConfigFeatures,
} from '../../../base/grid/entities/grid-config';
import { GridData } from '../../../base/grid/entities/grid-data';
import { GridObservables } from '../../../base/grid/entities/grid-observables';
import { TimeframeItem } from '../../../timeframe/entities/timeframe';
import { GridColDefs } from './constants/phasing-edit-grid.constants';
import { PhasingEditGridConfig } from './entities/phasing-edit-grid-config';
import {
  OpportunityPhasingChanged,
  OpportunityPhasingMetricChanged,
} from './entities/phasing-edit-grid-events';
import { PhasingEditGridUtils } from './utils/phasing-edit-grid.utils';
import { CalcUtils } from 'src/app/core/utils/calc.utils';
import { GridComponent } from '../../../base/grid/grid.component';

@Component({
  selector: 'app-phasing-edit-grid',
  templateUrl: './phasing-edit-grid.component.html',
  styleUrls: ['./phasing-edit-grid.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [GridComponent],
})
export class PhasingEditGridComponent implements OnChanges, OnDestroy {
  @Input() config: PhasingEditGridConfig;
  @Output() phasingChangedEvent = new EventEmitter<OpportunityPhasingChanged>();

  subscription = new Subscription();

  updateRowDataEvent = new ReplaySubject<GridData>(1);
  updateColDefsEvent = new ReplaySubject<Array<ColDef>>(1);

  editGridConfig: GridConfig;
  gridData: GridData;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.config && changes.config.currentValue) {
      this.initializeGridConfig(changes.config.firstChange);
      this.getData();
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  initializeGridConfig(isFirstChange?: boolean): void {
    const options: GridOptions = GridColDefs.getExtraGridOptions();
    options.context = this;

    this.editGridConfig =
      this.editGridConfig ??
      new GridConfig({
        columns: [],
        options,
        observables: new GridObservables({
          updateColDefs: this.updateColDefsEvent,
          updateRowData: this.updateRowDataEvent,
        }),
        expandable: false,
      });

    if (isFirstChange) {
      this.addSubscriptions();
    }

    this.updateColDefs();
  }

  updateColDefs(): void {
    this.updateColDefsEvent.next(
      GridColDefs.getColDefs(
        this.config.phasingTab,
        this.config.filters,
        this.config.selectedFilters,
        this.config.customerFilters
      )
    );
  }

  addSubscriptions(): void {
    this.subscription.add(
      this.editGridConfig.observables.cellValueChanged.subscribe(
        (x: CellValueChangedEvent) => {
          this.onCellValueChanged(x);
        }
      )
    );
  }

  getData(): void {
    if (this.config.phasingMonths) {
      this.gridData = this.config.phasingMonths;
      this.recalculateData();
    } else {
      this.gridData = new GridData({
        rows: PhasingEditGridUtils.mapData(
          this.config.filters,
          this.config.selectedFilters,
          this.config.phasingTab
        ),
        pinnedTop: [],
      });

      this.config.originalPhasingMonths = cloneDeep(this.gridData);
    }

    this.updateRowDataEvent.next(this.gridData);
  }

  sumRowData(row: Record<string, any>): number {
    if (row) {
      const months: Array<number> = this.getMonths();
      let sum = 0;

      months.forEach((x: number) => {
        sum += isNumber(row[x.toString()]) ? row[x.toString()] : 0;
      });

      return sum;
    }

    return 0;
  }

  getMonths(): Array<number> {
    const timeframe: TimeframeItem =
      PhasingEditGridUtils.getOppPhasingTimeframe(
        this.config.filters,
        this.config.selectedFilters,
        this.config.phasingTab
      );
    const months: Array<number> = [];

    for (let i = timeframe.start; i <= timeframe.end; i++) {
      months.push(i);
    }

    months.push(GridColDefs.Shared.beyondColId);
    return months;
  }

  getRow(metric: string): Record<string, any> {
    return this.gridData.rows.find(
      (x: Record<string, any>) => x.attrName === metric
    );
  }

  getGridStatusEvent(): OpportunityPhasingChanged {
    const event = new OpportunityPhasingChanged({
      phasingMonths: this.gridData,
      originalPhasingMonths: this.config.originalPhasingMonths,
      selectedSg: this.config.phasingTab.ServiceGroupCode,
      changes: [],
    });

    const metrics: Array<string> = ['NetRevenue', 'Cci'];
    const months: Array<number> = this.getMonths();

    metrics.forEach((x: string) => {
      const row: Record<string, any> = this.getRow(x);
      event.changes.push(
        new OpportunityPhasingMetricChanged({
          hasChanged: this.hasRowChanged(row, months),
          hasErrors: this.hasRowWithErrors(row, months),
          hasVarianceAllocated: this.hasRowWithVarianceAllocated(row),
          metricName: x,
        })
      );
    });

    return event;
  }

  recalculateData(): void {
    const months: Array<number> = this.getMonths();

    this.gridData.rows.forEach((x: Record<string, any>) => {
      months.forEach((y: number) => {
        this.calculateRowData(x, y);
      });
    });
  }

  calculateRowData(row: Record<string, any>, month: number): void {
    if (row) {
      // Calculate CCI% by period (column by column)
      if (['NetRevenue', 'Cci'].includes(row.attrName)) {
        const revenueRow: Record<string, any> = this.getRow('NetRevenue');
        const cciRow: Record<string, any> = this.getRow('Cci');
        const cciPercentageRow: Record<string, any> = this.getRow('CciPercent');

        if (
          this.config.phasingTab.CCIFollowsRevenue ||
          isUndefined(this.config.phasingTab.CCIFollowsRevenue)
        ) {
          cciRow[month.toString()] = CalcUtils.calculateCciFollowsRevenue(
            revenueRow[month.toString()],
            revenueRow.total,
            cciRow.total
          );
        }

        cciPercentageRow[month] = CalcUtils.calculateCustomPercentage(
          cciRow[month.toString()],
          revenueRow[month.toString()]
        );

        revenueRow[month] = Number(revenueRow[month].toFixed(3));

        revenueRow.variance = Math.round(
          revenueRow.total - this.sumRowData(revenueRow)
        );
        cciRow.variance = Math.round(cciRow.total - this.sumRowData(cciRow));
      }
    }
  }

  hasRowChanged(row: Record<string, any>, months: Array<number>): boolean {
    for (let i = 0; i < months.length; i++) {
      const month: number = months[i];
      const originalRow: Record<string, any> =
        this.config.originalPhasingMonths.rows.find(
          (x: Record<string, any>) => x.attrName === row.attrName
        );
      const originalValue: number =
        originalRow && has(originalRow, month.toString())
          ? originalRow[month.toString()]
          : 0;

      if (
        (!originalRow && Number(row[month.toString()]) !== 0) ||
        (originalRow && originalValue !== Number(row[month.toString()]))
      ) {
        return true;
      }
    }

    return false;
  }

  hasRowWithErrors(row: Record<string, any>, months: Array<number>): boolean {
    for (let i = 0; i < months.length; i++) {
      const month: number = months[i];
      const value = Number(row[month.toString()]);

      if (
        !isNull(value) &&
        (!isNumber(value) || isNaN(Number(replace(value?.toString(), /,/, ''))))
      ) {
        return true;
      }
    }

    return false;
  }

  hasRowWithVarianceAllocated(row: Record<string, any>): boolean {
    return row.variance !== 0;
  }

  onCellValueChanged(event: CellValueChangedEvent): void {
    if (event && Number(event.oldValue) !== Number(event.newValue)) {
      const row: Record<string, any> = event.data;

      if (row) {
        this.calculateRowData(row, Number(event.colDef.colId));
        this.updateRowDataEvent.next(this.gridData);
        this.phasingChangedEvent.emit(this.getGridStatusEvent());
      }
    }
  }
}
