import { Component } from '@angular/core';
import { Subject, Subscription } from 'rxjs';

import { MetricType } from 'src/app/shared/constants/metrics.constants';
import { TextValuePair } from 'src/app/shared/services/entities/common/key-value';

import { GilcTileService } from 'src/app/shared/services/tiles/gilc-tile.service';
import { ProjectionTileService } from 'src/app/shared/services/tiles/projection-tile.service';
import { TargetTileService } from 'src/app/shared/services/tiles/target-tile.service';
import { TrendTileService } from 'src/app/shared/services/tiles/trend-tile.service';
import { SpeculativeGridComponent } from './components/speculative-grid/speculative-grid.component';
import { TargetsTileComponent } from '../../shared/components/tiles/targets-tile/targets-tile.component';
import { TrendTileComponent } from '../../shared/components/tiles/trend-tile/trend-tile.component';
import { CciTileComponent } from '../../shared/components/tiles/projection-tile/components/cci-tile/cci-tile.component';
import { RevenueTileComponent } from '../../shared/components/tiles/projection-tile/components/revenue-tile/revenue-tile.component';
import { WonCciTileComponent } from '../../shared/components/tiles/projection-tile/components/won-cci-tile/won-cci-tile.component';
import { SalesTileComponent } from '../../shared/components/tiles/projection-tile/components/sales-tile/sales-tile.component';
import { ProfitupliftTileComponent } from 'src/app/shared/components/tiles/profituplift-tile/profituplift-tile.component';
import { NgClass } from '@angular/common';

@Component({
  selector: 'app-speculative',
  templateUrl: './speculative.component.html',
  styleUrls: ['./speculative.component.scss'],
  standalone: true,
  imports: [
    NgClass,
    SalesTileComponent,
    WonCciTileComponent,
    RevenueTileComponent,
    CciTileComponent,
    TrendTileComponent,
    TargetsTileComponent,
    SpeculativeGridComponent,
    ProfitupliftTileComponent,
  ],
})
export class SpeculativeComponent {
  openTrendModalEmittedEvent = new Subject<boolean>();
  isGridExpanded: boolean;

  subscription = new Subscription();

  public get metricType(): typeof MetricType {
    return MetricType;
  }

  constructor(
    private targetTileService: TargetTileService,
    private trendTileService: TrendTileService,
    private projectionTileService: ProjectionTileService,
    private gilcTileService: GilcTileService
  ) {}

  onOpenTrendModalEmitted(isChartView: boolean, type: MetricType): void {
    this.openTrendModalEmittedEvent.next(isChartView);
  }

  onMetricChanged(selected: TextValuePair): void {
    this.targetTileService.metricChanged.next(selected);
    this.trendTileService.metricChanged.next(selected);
    this.projectionTileService.metricChanged.next(selected);
    this.gilcTileService.metricChanged.next(selected);
  }
}
