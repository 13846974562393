import { assign } from 'lodash';
import { MetricType } from 'src/app/shared/constants/metrics.constants';

class ProfitUpliftTileDefinitionSchema {
  title: string;
  type: MetricType;
}

export class ProfitUpliftTileDefinition extends ProfitUpliftTileDefinitionSchema {
  constructor(filterAttributes?: ProfitUpliftTileDefinitionSchema) {
    super();
    assign(this, filterAttributes);
  }
}
