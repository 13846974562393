import { assign } from 'lodash';

import { ClientGroup } from 'src/app/shared/services/entities/filters/client-group';
import { Currency } from 'src/app/shared/services/entities/filters/currency';
import { Customer } from 'src/app/shared/services/entities/filters/customer';
import { FinancialCustomer } from 'src/app/shared/services/entities/filters/financial-customer';
import { Industry } from 'src/app/shared/services/entities/filters/industry';
import { Location } from 'src/app/shared/services/entities/filters/location';
import { ProductHierarchy } from 'src/app/shared/services/entities/filters/product-hierarchy';
import { ServiceGroupValues } from 'src/app/shared/services/entities/filters/service-group';
import { Timeframe } from 'src/app/shared/services/entities/filters/timeframe';
import { Wmu } from 'src/app/shared/services/entities/filters/wmu';

class FavoriteSaveModelSchema {
  favoriteId?: string;
  name?: string;
  sharedBy?: string;
  filters?: FavoriteSaveFiltersModel;
  default?: boolean;
  metric?: string;
  period?: string;
  group1?: string;
  group2?: string;
  group3?: string;
  group4?: string;
  target1?: string;
  target2?: string;
}

export class FavoriteSaveModel extends FavoriteSaveModelSchema {
  constructor(filterAttributes?: FavoriteSaveModelSchema) {
    super();
    assign(this, filterAttributes);
  }
}

class FavoriteSaveFiltersModelSchema {
  Industries?: Array<Industry>;
  Alliances?: Array<ProductHierarchy>;
  Sgi?: Array<ProductHierarchy>;
  Functions?: Array<ProductHierarchy>;
  ServiceGroups?: Array<ServiceGroupValues>;
  Locations?: Array<Location>;
  ClientServiceGroups?: Array<ClientGroup>;
  Wmus?: Array<Wmu>;
  FinancialCustomer?: Array<FinancialCustomer>;
  Currency?: Currency;
  Customer?: Customer;
  Timeframe?: Timeframe;
}

export class FavoriteSaveFiltersModel extends FavoriteSaveFiltersModelSchema {
  constructor(filterAttributes?: FavoriteSaveFiltersModelSchema) {
    super();
    assign(this, filterAttributes);
  }
}
