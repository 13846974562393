import {
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import {
  NgbModal,
  NgbModalRef,
  NgbAlertModule,
  NgbNavModule,
} from '@ng-bootstrap/ng-bootstrap';
import {
  combineLatest,
  Observable,
  of,
  OperatorFunction,
  Subject,
  Subscription,
} from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  finalize,
  map,
  switchMap,
} from 'rxjs/operators';
import {
  cloneDeep,
  endsWith,
  has,
  isUndefined,
  last,
  maxBy,
  minBy,
  pullAll,
  replace,
  startsWith,
} from 'lodash';

import { CustomNumberPipe } from '../../pipes/custom-number.pipe';
import { SpeculativeModalConfig } from './entities/speculative-modal-config';
import { Speculative } from './entities/speculative';
import { RequestPayload } from '../../services/entities/request-payload';
import { OptionItem } from '../base/options-list/entities/option-item';
import { OptionsListConfig } from '../base/options-list/entities/options-list-config';
import { OptionsListUtils } from '../base/options-list/utils/options-list.utils';
import { ServiceGroupValues } from '../../services/entities/filters/service-group';
import { FinancialCustomer } from '../../services/entities/filters/financial-customer';
import { SpeculativeEditGridConfig } from './components/speculative-edit-grid/entities/speculative-edit-grid-config';
import { ValueType } from '../../constants/common.constants';
import { ClientGroup } from '../../services/entities/filters/client-group';
import { TreeViewConfig } from '../base/tree-view/entities/tree-view-config';
import { TreeViewSelection } from '../base/tree-view/entities/tree-view-selection';
import { TreeViewUtils } from '../base/tree-view/utils/tree-view.utils';
import { TreeViewItem } from '../base/tree-view/entities/tree-view-item';
import { Wmu } from '../../services/entities/filters/wmu';
import { Industry } from '../../services/entities/filters/industry';
import {
  FullLocation,
  Location,
} from '../../services/entities/filters/location';
import {
  CustomCurrencies,
  getTreeViewItemIconCssName,
  SubMetrics,
} from '../../constants/filters.constants';
import {
  MetricTotalChanged,
  SpeculativePhasingChanged,
} from './components/speculative-edit-grid/entities/speculative-edit-grid-events';
import { ReasonCode } from '../../services/entities/reason-code';
import { SpeculativePhasingPeriod } from '../../services/entities/grids/speculative-response';
import { MmbDate } from '../../services/entities/filters/date';
import { CalcUtils } from 'src/app/core/utils/calc.utils';
import { MessageTemplates } from '../../constants/messages.constants';
import { SpeculativeEditGridUtils } from './components/speculative-edit-grid/utils/speculative-edit-grid.utils';
import {
  AppMessage,
  AppMessageButton,
} from '../../services/entities/app-message';

import { FiltersService } from '../../services/filters.service';
import { SpeculativeModalService } from '../../services/modals/speculative-modal.service';
import { MrdrService } from '../../services/mrdr.service';
import { AppMessagesService } from '../../services/app-messages.service';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faTimes,
  faExclamationCircle,
} from '@fortawesome/free-solid-svg-icons';
import { BetaMessageService } from '../../services/beta-message.service';
import { SpeculativeEditGridComponent } from './components/speculative-edit-grid/speculative-edit-grid.component';
import { SpinnerComponent } from '../base/spinner/spinner.component';
import { TreeViewComponent } from '../base/tree-view/tree-view.component';
import { SearchInputComponent } from '../base/search-input/search-input.component';
import { OptionsListComponent } from '../base/options-list/options-list.component';
import { SelectionControlComponent } from '../base/selection-control/selection-control.component';
import { AutoCompleteComponent } from '../base/auto-complete/auto-complete.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgIf, NgClass, NgFor } from '@angular/common';
import { SharedModule } from 'src/app/shared-module';
import { AppStateService } from 'src/app/shared/services/app-state.service';
import { ProfitLevers } from '../../services/entities/filters/profitUplift';

@Component({
  selector: 'app-speculative-modal',
  templateUrl: './speculative-modal.component.html',
  styleUrls: ['./speculative-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    NgIf,
    FormsModule,
    ReactiveFormsModule,
    NgClass,
    FontAwesomeModule,
    NgFor,
    AutoCompleteComponent,
    SharedModule,
    SelectionControlComponent,
    OptionsListComponent,
    SearchInputComponent,
    TreeViewComponent,
    SpinnerComponent,
    SpeculativeEditGridComponent,
  ],
})
export class SpeculativeModalComponent implements OnInit, OnDestroy {
  subscription = new Subscription();

  @ViewChild('speculativeModal', { static: false })
  speculativeModal: NgbModalRef;

  loaded = true;
  currentModal: NgbModalRef;
  isOpen = false;
  config: SpeculativeModalConfig;
  speculative: Speculative;
  currencyDescription: string;
  amountsAlert = true;
  thousandsAlert = true;
  successfullyAlert = true;
  isDuplicate: boolean;
  totals: Array<string>;

  // sgs
  sgSelectAllChangedEvent = new Subject<boolean>();
  sgListConfig: OptionsListConfig;
  selectedSgs: Array<Record<string, any>>;
  startSelectionSgs: Array<OptionItem>;

  // clientGroups
  clientGroups: Array<ClientGroup> = [];
  cgListConfig: OptionsListConfig;
  selectedClientGroup: ClientGroup;
  startSelectionCgs: Array<OptionItem>;

  // markets
  markets: Array<Location> = [];
  marketsTreeViewConfig: TreeViewConfig;
  selectedMarket: Location;
  startSelectionMarkets: TreeViewSelection;
  marketsLoaded: boolean;

  // wmus
  wmu: Array<Wmu> = [];
  wmuTreeViewConfig: TreeViewConfig;
  selectedWmu: Wmu;
  startSelectionWmus: TreeViewSelection;

  // industries
  industries: Array<Industry> = [];
  industriesTreeViewConfig: TreeViewConfig;
  selectedIndustry: Industry;
  startSelectionIndustries: TreeViewSelection;

  // ProfitUplift
  puSelectAllChangedEvent = new Subject<boolean>();
  puListConfig: OptionsListConfig;
  selectedPU: Array<Record<string, any>>;
  profitUpliftViewConfig: TreeViewConfig;
  totalPUpercentageVal = 0;
  startSelectionPU: TreeViewSelection;

  // Search
  searchTextCgs: string;
  searchTextMarkets: string;
  searchTextWmu: string;
  searchTextIndustry: string;
  personSearch: OperatorFunction<string, readonly string[]>;

  // Forms
  headerForm: FormGroup;
  sgForm: FormGroup;
  editGridConfig: SpeculativeEditGridConfig;
  phasingChanged: SpeculativePhasingChanged;
  puForm: FormGroup;

  // Enable / Disable
  hasHeaderFormChanged = false;
  hasSgFormChanged = false;
  hasPUFormChanged = false;
  hasCgChanged = false;
  hasMarketChanged = false;
  hasWmuChanged = false;
  hasIndustryChanged = false;
  isPUSelected = false;

  sgTabEnable: boolean;
  cgTabEnable: boolean;
  marketTabEnable: boolean;
  wmuTabEnable: boolean;
  industryTabEnable: boolean;
  phasingTabEnable: boolean;
  profitLeverTabEnable: boolean;
  saveEnable: boolean;

  isCloud: boolean;
  actionFlag: string;
  isFlipFlopFlagEnabled: boolean;

  faTimes = faTimes as IconProp;
  faExclamationCircle = faExclamationCircle as IconProp;

  constructor(
    private modalService: NgbModal,
    private mrdrService: MrdrService,
    private filtersService: FiltersService,
    private speculativeModalService: SpeculativeModalService,
    private appMessagesService: AppMessagesService,
    private formBuilder: FormBuilder,
    private betaMessage: BetaMessageService,
    private appStateService: AppStateService
  ) {}

  ngOnInit(): void {
    this.personSearch = (text$: Observable<string>) =>
      text$.pipe(
        debounceTime(500),
        distinctUntilChanged(),
        switchMap((x: string) =>
          this.mrdrService.getEnterpriseIds(x).pipe(
            catchError(() => {
              return of([]);
            })
          )
        )
      );

    this.subscription.add(
      this.appStateService.appStateChanged.subscribe((appState) => {
        this.isFlipFlopFlagEnabled = appState.gcpFlipFlopFlagEnabled;
      })
    );

    this.subscription.add(
      this.speculativeModalService.openSpeculativeModalEmitted.subscribe(
        (x: SpeculativeModalConfig) => {
          this.config = x;
          this.currencyDescription =
            this.config.selectedFilters.currency.Description;
          this.getData();
          this.openModal();
        }
      )
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  getData(): void {
    this.loadStarted();

    const extraParams: Record<string, any> = {
      id: this.config.isNew() ? '' : this.config.speculativeId,
      dataSet: this.config.dataSet,
      sapCode: '-1',
      sapCodePlan: this.config.selectedFilters.plan.year.toString(),
    };

    if (this.config.isNew()) {
      this.actionFlag = 'add';
    } else {
      this.actionFlag = 'other';
    }

    const request: RequestPayload = RequestPayload.createRequest(
      this.config.selectedFilters,
      extraParams
    );

    this.betaMessage.isCloudSubject$.subscribe((betaFlag) => {
      this.isCloud =
        (betaFlag && !this.isFlipFlopFlagEnabled) ||
        (!betaFlag && this.isFlipFlopFlagEnabled);
    });

    (this.config.isNew()
      ? this.speculativeModalService.getProjectedData(request, this.isCloud)
      : this.speculativeModalService.getSpeculative(request, this.isCloud)
    )
      .then((x: Speculative) => {
        this.speculative = x;
        this.initForms();
        this.loadFilters();
        this.setHeaderFormData();
        this.setGridData();
      })
      .catch(() => {
        this.appMessagesService.show(MessageTemplates.UnexpectedError, {
          centered: true,
        });
      })
      .finally(() => {
        this.loadCompleted();
      });
  }

  loadStarted(): void {
    this.loaded = false;
  }

  loadCompleted(): void {
    this.loaded = true;
  }

  initForms(): void {
    this.initHeaderForm();
    this.initSgForm();
    this.initPUForm();
  }

  initHeaderForm(): void {
    this.headerForm = this.formBuilder.group({
      id: new FormControl({ value: '', disabled: true }, [
        Validators.minLength(9),
        Validators.maxLength(9),
        Validators.pattern('[0-9]+'),
      ]),
      name: new FormControl('', [
        Validators.required,
        Validators.maxLength(150),
      ]),
      sales: new FormControl('', [this.validateLength.bind(this)]),
      cci: new FormControl('', [this.validateLength.bind(this)]),
      revenue: new FormControl('', [this.validateLength.bind(this)]),
      cciPercentage: new FormControl({ value: '', disabled: true }),
      reasonCode: new FormControl('', [Validators.required]),
      responsible: new FormControl(
        '',
        [Validators.required],
        [this.validatePerson.bind(this)]
      ),
      comment: new FormControl('', []),
      financialCustomerName: new FormControl(
        '',
        [Validators.required],
        [this.validateFinancialCustomer.bind(this)]
      ),
    });

    this.subscription.add(
      this.headerForm.statusChanges.subscribe((x: string) => {
        this.checkControlsAvailability();
      })
    );

    this.subscription.add(
      this.headerForm.valueChanges.subscribe((x: Record<string, any>) => {
        this.checkHeaderFormChanges(x);
      })
    );

    this.subscription.add(
      combineLatest([
        this.headerForm.controls.revenue.valueChanges,
        this.headerForm.controls.cci.valueChanges,
      ]).subscribe(([x, y]: [string, string]) => {
        const numberPipe = new CustomNumberPipe();
        const revenue: number = this.removeControlFormatNumber(
          this.headerForm,
          'revenue',
          ValueType.Numeric
        );
        const cci: number = this.removeControlFormatNumber(
          this.headerForm,
          'cci',
          ValueType.Numeric
        );
        let value: number = revenue !== 0 ? (cci * 100) / Math.abs(revenue) : 0;

        if (isNaN(value) || !isFinite(value)) {
          value = 0;
        }

        this.headerForm
          .get('cciPercentage')
          .setValue(
            numberPipe.transform(value / 100, ValueType.Percentage, '')
          );
      })
    );
  }

  initSgForm(): void {
    this.sgForm = this.formBuilder.group({
      total: new FormControl('0%', [this.validatePercentage]),
    });
  }

  initPUForm(): void {
    this.totals = ['sales', 'revenue', 'cci'];
    this.puForm = this.formBuilder.group({
      totalPU: new FormControl('0%', [this.validatePercentage]),
    });
  }

  setHeaderFormData(): void {
    const numberPipe = new CustomNumberPipe();

    this.headerForm.reset();
    if (this.config.isDuplicate) {
      this.headerForm.get('id').setValue('');
      this.headerForm
        .get('name')
        .setValue(`DUPLICATE - ${this.speculative.name}`);
    } else {
      this.headerForm.get('id').setValue(this.speculative.id);
      this.headerForm.get('name').setValue(this.speculative.name);
    }
    this.headerForm
      .get('sales')
      .setValue(
        numberPipe.transform(this.speculative.sales, ValueType.Numeric, '')
      );
    this.headerForm
      .get('revenue')
      .setValue(
        numberPipe.transform(this.speculative.netRevenue, ValueType.Numeric, '')
      );
    this.headerForm
      .get('cci')
      .setValue(
        numberPipe.transform(
          this.speculative.deliveredCCIDollar,
          ValueType.Numeric,
          ''
        )
      );

    const financialCustomer: FinancialCustomer =
      this.config.customerFilters.financialCustomer.find(
        (x: FinancialCustomer) =>
          x.CustomerNumber === this.speculative.financialCustomerNumber
      );

    this.headerForm
      .get('reasonCode')
      .setValue(this.speculative.reasonCode?.ReasonCodeId);
    this.headerForm
      .get('financialCustomerName')
      .setValue(financialCustomer ? financialCustomer.getFullName() : '');
    this.headerForm.get('responsible').setValue(this.speculative.responsible);
    this.headerForm.get('comment').setValue(this.speculative.comment);

    this.checkControlsAvailability();
  }

  setGridData(): void {
    this.editGridConfig = new SpeculativeEditGridConfig({
      filters: this.config.filters,
      selectedFilters: this.config.selectedFilters,
      customerFilters: this.config.customerFilters,
      speculative: this.speculative,
      isReadOnly: this.config.isReadOnly,
      isAdmin: this.config.isAdmin,
      isDuplicate: this.config.isDuplicate,
    });
  }

  loadFilters(): void {
    this.loadServiceGroups();
    this.loadClientGroups();
    this.loadMarkets();
    this.loadWmus();
    this.loadIndustries();
    this.loadProfitUplift();
  }

  loadServiceGroups(): void {
    this.sgListConfig = new OptionsListConfig({
      items: OptionsListUtils.getItems<ServiceGroupValues>(
        this.config.filters.serviceGroups.ServiceGroups,
        'ServiceGroupCd',
        'ServiceGroupNm'
      ),
      multipleSelect: true,
    });

    this.selectedSgs = this.speculative.serviceGroups?.map(
      (x: Record<string, any>) => {
        this.sgForm.addControl(
          x.id,
          new FormControl(`${x.percentageValue}%`, [this.validatePercentage])
        );

        return {
          id: x.id,
          name: this.config.filters.serviceGroups.ServiceGroups.find(
            (y: ServiceGroupValues) => y.ServiceGroupCd === x.id
          ).ServiceGroupNm,
          percentageValue: x.percentageValue,
        };
      }
    );

    this.startSelectionSgs = this.selectedSgs?.map(
      (x: Record<string, any>) =>
        new OptionItem({
          value: x.id,
          text: x.name,
        })
    );
  }

  loadClientGroups(): void {
    this.clientGroups = this.config.customerFilters.csg.filter(
      (x: ClientGroup) => x.ClientGroupType.includes('CLIENTGROUP')
    );

    this.cgListConfig = new OptionsListConfig({
      items: OptionsListUtils.getItems<ClientGroup>(
        this.clientGroups,
        'ClientGroupCd',
        'ClientGroupDesc'
      ),
      multipleSelect: false,
    });

    this.selectedClientGroup = this.speculative.clientServiceGroup
      ? this.config.customerFilters.csg.find(
          (x: ClientGroup) =>
            x.ClientGroupCd ===
            this.speculative.clientServiceGroup.clientServiceGroupId
        )
      : null;

    this.startSelectionCgs = this.selectedClientGroup
      ? [this.selectedClientGroup].map(
          (x: ClientGroup) =>
            new OptionItem({
              value: x.ClientGroupCd,
              text: x.ClientGroupDesc,
            })
        )
      : [];
  }

  loadMarkets(): void {
    if (this.selectedClientGroup) {
      this.markets = [];
      this.marketsLoaded = false;
      this.selectedMarket = null;

      this.filtersService
        .getLocationsByClientGroup(
          this.selectedClientGroup.ClientGroupCd,
          this.isCloud
        )
        .then((x: Array<Location>) => {
          this.markets = x;
          const beneficiaryOrgId = Number(
            this.speculative.location.beneficiaryOrgId
          );
          this.selectedMarket = this.markets.find(
            (x: Location) => x.OrganizationId === beneficiaryOrgId
          );

          this.marketsTreeViewConfig = new TreeViewConfig({
            radioItemName: 'market',
            parentAttr: 'OrganizationId',
            textAttr: 'OrganizationName',
            valueAttr: 'ParentOrganizationId',
            multipleSelect: false,
            items: TreeViewUtils.getItems<Location>(
              this.markets,
              'OrganizationId',
              'OrganizationName',
              'ParentOrganizationId',
              (x: TreeViewItem, y: Location) => {
                x.hasCheckBox = y.IsBenOrg;
              }
            ),
          });

          this.startSelectionMarkets = this.selectedMarket
            ? new TreeViewSelection({
                selectedItems: [this.selectedMarket].map(
                  (x: Location) =>
                    new TreeViewItem({
                      value: x.OrganizationId,
                      text: x.OrganizationName,
                    })
                ),
              })
            : null;

          this.marketsLoaded = true;
        });
    }
  }

  loadWmus(): void {
    this.wmu = this.config.customerFilters?.wmu;

    this.wmuTreeViewConfig = new TreeViewConfig({
      radioItemName: 'wmu',
      parentAttr: 'PID',
      textAttr: 'UN',
      valueAttr: 'ID',
      multipleSelect: false,
      decoupleParentFromChildren: true,
      decoupleChildrenFromParent: true,
      items: TreeViewUtils.getItems<Wmu>(
        this.wmu,
        'ID',
        'UN',
        'PID',
        (item: TreeViewItem, wmu: Wmu) => {
          item.iconCssName = getTreeViewItemIconCssName(wmu.type);
          item.hasCheckBox = wmu.hc;
          if (wmu.status === 'RT') {
            item.cssList.push('strikethrough');
          }
        }
      ),
    });

    this.selectedWmu = this.speculative.wmu
      ? this.config.customerFilters.wmu.find(
          (x: Wmu) => x.ID === this.speculative.wmu.wmuId
        )
      : null;

    this.startSelectionWmus = this.selectedWmu
      ? new TreeViewSelection({
          selectedItems: [this.selectedWmu].map(
            (x: Wmu) =>
              new TreeViewItem({
                value: x.ID,
                text: x.UN,
              })
          ),
        })
      : null;
  }

  loadIndustries(): void {
    this.industries = this.config.filters?.industry;

    this.industriesTreeViewConfig = new TreeViewConfig({
      radioItemName: 'industry',
      parentAttr: 'ParentEntityCode',
      textAttr: 'EntityDescription',
      valueAttr: 'EntityCode',
      multipleSelect: false,
      items: TreeViewUtils.getItems<Industry>(
        this.industries,
        'EntityCode',
        'EntityDescription',
        'ParentEntityCode',
        (x: TreeViewItem, y: Industry) => {
          x.hasCheckBox = !x.hasChildren();
        }
      ),
    });

    this.selectedIndustry = this.speculative.industryCode
      ? this.config.filters.industry.find(
          (x: Industry) => x.EntityCode === this.speculative.industryCode
        )
      : null;

    this.startSelectionIndustries = this.selectedIndustry
      ? new TreeViewSelection({
          selectedItems: [this.selectedIndustry].map(
            (x: Industry) =>
              new TreeViewItem({
                value: x.EntityCode,
                text: x.EntityDescription,
              })
          ),
        })
      : null;
  }

  loadProfitUplift(): void {
    this.profitUpliftViewConfig = new TreeViewConfig({
      parentAttr: 'ProfitLeverId',
      textAttr: 'ProfitLeverName',
      valueAttr: 'ParentProfitLeverId',
      countByNodes: true,
      selectByNodes: true,
      items: TreeViewUtils.getItems<ProfitLevers>(
        this.config.filters.profitLevers,
        'ProfitLeverId',
        'ProfitLeverName',
        'ParentProfitLeverId'
      ),
    });

    this.profitUpliftViewConfig.decoupleChildrenFromParent = true;
    this.profitUpliftViewConfig.items.forEach((parent) => {
      parent.hasCheckBox = false;
      parent.children.forEach((child) => {
        child.cssList.push('pu-tree-text');
      });
    });

    const currentSelectedPU = this.speculative.profitUplift?.map(
      (x: Record<string, any>) => {
        this.puForm.addControl(
          x.profitLever,
          new FormControl(`${x.profitPercent}%`, [this.validatePercentage])
        );

        this.totals.forEach((total) => {
          this.puForm.addControl(
            x.profitLever + '_' + total,
            new FormControl()
          );
        });

        return {
          id: x.profitLever,
          name: this.config.filters.profitLevers.find(
            (y: ProfitLevers) => y.ProfitLeverId === x.profitLever
          ).ProfitLeverName,
          percentageValue: x.profitPercent,
        };
      }
    );

    this.selectedPU = currentSelectedPU ?? [];

    this.startSelectionPU = new TreeViewSelection({
      selectedItems: currentSelectedPU?.map(
        (x: Record<string, any>) =>
          new TreeViewItem({
            value: x.id,
            text: x.name,
            checked: true,
          })
      ),
    });
  }

  clearData(): void {
    this.phasingChanged = null;
    this.selectedSgs = null;
    this.selectedClientGroup = null;
    this.selectedMarket = null;
    this.selectedWmu = null;
    this.selectedIndustry = null;
    this.headerForm.reset();
  }

  clearChanges(): void {
    this.phasingChanged.phasingMonths = null;
    this.phasingChanged.hasChanged = false;
    this.phasingChanged.hasErrors = false;
    this.phasingChanged.hasVarianceAllocated = false;
    this.headerForm.reset();
  }

  checkHeaderFormChanges(fields: Record<string, any>): void {
    const numberPipe = new CustomNumberPipe();
    const cci: number = this.removeFormatNumber(fields.cci, ValueType.Numeric);
    const revenue: number = this.removeFormatNumber(
      fields.revenue,
      ValueType.Numeric
    );
    const sales: number = this.removeFormatNumber(
      fields.sales,
      ValueType.Numeric
    );
    const financialCustomer: FinancialCustomer =
      this.config.customerFilters.financialCustomer.find(
        (e: FinancialCustomer) => {
          return e.CustomerNumber === this.speculative.financialCustomerNumber;
        }
      );
    this.hasHeaderFormChanged =
      fields.comment !== this.speculative.comment ||
      fields.name !== this.speculative.name ||
      fields.reasonCode !== this.speculative.reasonCode?.ReasonCodeId ||
      fields.responsible !== this.speculative.responsible ||
      fields.financialCustomerName !== financialCustomer?.getFullName() ||
      cci !==
        this.removeFormatNumber(
          numberPipe.transform(
            this.speculative.deliveredCCIDollar,
            ValueType.Numeric,
            ''
          ),
          ValueType.Numeric
        ) ||
      revenue !==
        this.removeFormatNumber(
          numberPipe.transform(
            this.speculative.netRevenue,
            ValueType.Numeric,
            ''
          ),
          ValueType.Numeric
        ) ||
      sales !==
        this.removeFormatNumber(
          numberPipe.transform(this.speculative.sales, ValueType.Numeric, ''),
          ValueType.Numeric
        );
  }

  checkControlsAvailability(): void {
    setTimeout(() => {
      this.sgTabEnable = this.headerForm.valid && this.hasValidMetrics();
      this.cgTabEnable =
        this.sgTabEnable &&
        this.sgForm.valid &&
        this.sgForm.get('total').value === '100%';
      this.marketTabEnable = this.cgTabEnable && !!this.selectedClientGroup;
      this.wmuTabEnable = this.marketTabEnable && !!this.selectedMarket;
      this.industryTabEnable = this.wmuTabEnable;
      this.phasingTabEnable = this.wmuTabEnable;
      this.profitLeverTabEnable =
        this.phasingTabEnable &&
        this.phasingChanged?.isValid() &&
        this.headerForm.controls.reasonCode.value === 'PI';

      if (
        this.speculative.profitUplift !== null &&
        this.speculative.profitUplift !== undefined &&
        this.profitLeverTabEnable
      ) {
        this.selectedPU?.forEach((x: Record<string, any>) => {
          this.speculative.profitUplift.forEach((y: Record<string, any>) => {
            if (
              x.id === y.profitLever &&
              x.percentageValue !== y.profitPercent
            ) {
              this.hasPUFormChanged = true;
            }
          });
        });
      }

      this.saveEnable =
        this.phasingTabEnable &&
        this.phasingChanged?.isValid() &&
        this.hasValidMetrics() &&
        (this.hasHeaderFormChanged ||
          this.hasSgFormChanged ||
          this.hasPUFormChanged ||
          this.hasCgChanged ||
          this.hasMarketChanged ||
          this.hasWmuChanged ||
          this.hasIndustryChanged ||
          this.phasingChanged?.hasChanged) &&
        !this.config.isReadOnly &&
        (!this.profitLeverTabEnable ||
          (!this.isPUSelected &&
            this.profitLeverTabEnable == (this.totalPUpercentageVal == 0)) ||
          (this.isPUSelected &&
            this.profitLeverTabEnable == (this.totalPUpercentageVal == 100)));
    }, 100);
  }

  calculateSgTotal(): void {
    let total = 0;

    this.selectedSgs.forEach((x: Record<string, any>) => {
      x.percentageValue = Number(
        replace(this.sgForm.get(x.id).value.toString(), '%', '')
      );
      total += x.percentageValue;
    });

    if (isNaN(total)) {
      total = 0;
    }

    this.sgForm.get('total').setValue(`${total}%`);
    this.checkControlsAvailability();
  }

  calculatePUTotal(): void {
    this.totalPUpercentageVal = 0;
    const totalsVal = [];

    this.totals.forEach((x) => {
      totalsVal.push(
        this.removeControlFormatNumber(this.headerForm, x, ValueType.Numeric)
      );
    });

    this.selectedPU.forEach((x: Record<string, any>) => {
      x.percentageValue = Number(
        replace(this.puForm.get(x.id).value.toString(), '%', '')
      );

      this.totals.forEach((y, index) => {
        const value = totalsVal[index] * (x.percentageValue / 100);
        this.puForm.get(`${x.id}_${y}`).setValue(value.toFixed(2));
      });

      this.totalPUpercentageVal += x.percentageValue;
    });

    this.puForm.get('totalPU').setValue(`${this.totalPUpercentageVal}%`);
    this.checkControlsAvailability();
  }

  openModal(): void {
    this.thousandsAlert = !(
      this.currencyDescription === CustomCurrencies.FxAdjusted.text ||
      this.currencyDescription === CustomCurrencies.Constant.text
    );

    this.amountsAlert = !this.thousandsAlert;

    if (this.amountsAlert) {
      this.currencyDescription = CustomCurrencies.Global.text;
    }

    if (!this.isOpen) {
      this.currentModal = this.modalService.open(this.speculativeModal, {
        windowClass: 'speculative-modal',
        centered: true,
        size: 'lg',
        backdrop: 'static',
      });

      this.isOpen = true;

      this.subscription.add(
        this.currentModal.dismissed.subscribe(() => {
          this.isOpen = false;
        })
      );
    }
  }

  updateMetricTotal(controlName: string, value: number): void {
    const metrics: Array<string> = ['sales', 'revenue', 'cci', 'cciPercentage'];

    if (
      this.editGridConfig.metricTotalChanged &&
      metrics.includes(controlName)
    ) {
      this.calculatePUTotal();
      this.editGridConfig.metricTotalChanged.next(
        new MetricTotalChanged({
          metricName: controlName,
          value,
        })
      );
    }
  }

  isNegative(metric: string, type: ValueType): boolean {
    const value: number = this.removeFormatNumber(
      this.headerForm.get(metric).value,
      type
    );
    return value < 0;
  }

  getRow(metric: string): Record<string, any> {
    return this.phasingChanged.phasingMonths.rows.find(
      (x: Record<string, any>) =>
        x.metric === metric && !x.name.includes(SubMetrics.Projection)
    );
  }

  getSpeculativeData(): Speculative {
    const clientGroup: Record<string, string> = {
      clientServiceGroupId: this.selectedClientGroup.ClientGroupCd,
    };
    const reasonCode: ReasonCode = this.config.reasonCodes.find(
      (x: ReasonCode) =>
        x.ReasonCodeId === this.headerForm.controls.reasonCode.value
    );
    Object.setPrototypeOf(this.selectedMarket, Location.prototype);
    const location: FullLocation = this.selectedMarket.getFullLocation(
      this.config.filters
    );
    const wmu: Record<string, number> = {
      wmuId: this.selectedWmu?.ID,
    };
    const industryCode: string = this.selectedIndustry?.EntityCode;
    const sales: number = this.removeControlFormatNumber(
      this.headerForm,
      'sales',
      ValueType.Numeric
    );
    const cci: number = this.removeControlFormatNumber(
      this.headerForm,
      'cci',
      ValueType.Numeric
    );
    const revenue: number = this.removeControlFormatNumber(
      this.headerForm,
      'revenue',
      ValueType.Numeric
    );
    const wonCci: number = sales !== 0 ? cci / sales : 0;
    const cciPercentage: number = CalcUtils.calculateCustomPercentage(
      cci,
      revenue
    );

    let phasingMonthly: Array<SpeculativePhasingPeriod> = [];
    const revenueRow: Record<string, any> = this.getRow('revenue');
    const salesRow: Record<string, any> = this.getRow('sales');
    const cciRow: Record<string, any> = this.getRow('cci');
    const cciPercentageRow: Record<string, any> = this.getRow('cciPercentage');
    const months: Array<MmbDate> = SpeculativeEditGridUtils.getMonths(
      this.config.filters
    );

    // Monthly data
    months.forEach((x: MmbDate) => {
      phasingMonthly.push(
        new SpeculativePhasingPeriod({
          timeId: x.Id,
          sales: salesRow[x.Id.toString()],
          revenue: revenueRow[x.Id.toString()],
          cci: cciRow[x.Id.toString()],
          cciPercentage: cciPercentageRow[x.Id.toString()],
        })
      );
    });

    // Beyond data
    const latestMonth: MmbDate = last(months);

    phasingMonthly.push(
      new SpeculativePhasingPeriod({
        timeId: latestMonth.Id + 1,
        sales: salesRow.beyond,
        revenue: revenueRow.beyond,
        cci: cciRow.beyond,
        cciPercentage: cciPercentageRow.beyond,
      })
    );

    // Clean unnecessary data
    // It checks min and max periods with data
    const periodsWithData: Array<SpeculativePhasingPeriod> =
      phasingMonthly?.filter((x: SpeculativePhasingPeriod) =>
        x.hasFinancials()
      );
    const minPeriodWithData: number = minBy(periodsWithData, 'timeId')?.timeId;
    const maxPeriodWithData: number = maxBy(periodsWithData, 'timeId')?.timeId;

    // Filter data from min to max periods with data
    // TimeIDs must be sequential
    phasingMonthly = phasingMonthly.filter(
      (x: SpeculativePhasingPeriod) =>
        x.timeId >= minPeriodWithData && x.timeId <= maxPeriodWithData
    );

    // ProfitUplift map
    const profitUplift: Array<Record<string, any>> =
      this.headerForm.controls.reasonCode.value !== 'PI'
        ? []
        : this.selectedPU.map((x) => {
            return {
              profitLever: x.id,
              profitPercent: x.percentageValue,
            };
          });

    const speculative = new Speculative();
    speculative.masterCustomerName =
      this.config.selectedFilters.customer.MasterCustomerName;
    speculative.masterCustomerNumber =
      this.config.selectedFilters.customer.MasterCustomerNumber;
    speculative.id = this.headerForm.controls.id.value;
    speculative.name = this.headerForm.controls.name.value;
    speculative.currency = this.currencyDescription;
    speculative.reasonCode = reasonCode;
    speculative.status = this.config.isNew()
      ? 'Active'
      : this.speculative.status;
    speculative.responsible = this.headerForm.controls.responsible.value;
    speculative.comment = this.headerForm.controls.comment.value;
    speculative.lastUpdated = new Date();

    speculative.sales = sales;
    speculative.netRevenue = revenue;
    speculative.deliveredCCIDollar = cci;
    speculative.deliveredCCIPercentage = cciPercentage;
    speculative.wonCCIPercentage = wonCci;

    speculative.startDate = minPeriodWithData;
    speculative.endDate = maxPeriodWithData;
    speculative.financialCustomerNumber =
      this.headerForm.controls.financialCustomerName.value.split('-')[0].trim();
    speculative.industryCode = industryCode;
    speculative.location = location;
    speculative.clientServiceGroup = clientGroup;
    speculative.serviceGroups = this.selectedSgs;
    speculative.wmu = wmu;

    speculative.phasingMonthly = phasingMonthly;
    speculative.projectedData = this.speculative.projectedData;

    speculative.profitUplift =
      profitUplift.length > 0
        ? profitUplift.filter((x) => x.profitPercent > 0)
        : [];

    return speculative;
  }

  hasValidMetrics(): boolean {
    const metrics: Array<string> = ['sales', 'revenue', 'cci'];
    let sum = 0;
    let someNotPristine: boolean;

    metrics.forEach((x: string) => {
      const control: AbstractControl = this.headerForm.get(x);
      sum += this.removeFormatNumber(control.value, ValueType.Numeric);
      someNotPristine = someNotPristine || !control.pristine;
    });

    return sum !== 0 || !someNotPristine;
  }

  validateBillion(speculative: Speculative): boolean {
    const billionValue: boolean =
      Math.abs(speculative.deliveredCCIDollar) >= 999999 ||
      Math.abs(speculative.netRevenue) >= 999999 ||
      Math.abs(speculative.sales) >= 999999 ||
      Math.abs(speculative.deliveredCCIDollar + speculative.sales) >= 999999 ||
      Math.abs(speculative.netRevenue + speculative.sales) >= 999999 ||
      Math.abs(
        speculative.deliveredCCIDollar +
          speculative.netRevenue +
          speculative.sales
      ) >= 999999;

    return billionValue;
  }

  saveSpeculative(speculative: Speculative, close?: boolean): void {
    this.speculativeModalService
      .saveSpeculative(speculative, this.config.selectedFilters, this.isCloud)
      .then((x: Record<string, string>) => {
        if (has(x, 'id')) {
          this.speculative = cloneDeep(speculative);
          this.speculative.id = x.id;
          this.config.speculativeId = x.id;
          this.config.isDuplicate = false;

          if (close) {
            this.currentModal.dismiss();
            this.clearData();
            this.speculativeModalService.closeSpeculativeModalEmitted.next([
              x.id,
              this.actionFlag,
            ]);
          } else {
            this.clearChanges();
          }
          this.speculativeModalService.saveSpeculativeSuccessEmitted.next([
            x.requestid,
            this.actionFlag,
          ]);
          this.appMessagesService.show(
            MessageTemplates.SpeculativeSuccessfulSaved,
            { centered: true }
          );
          this.loadFilters();
          this.setHeaderFormData();
          this.setGridData();
        } else {
          this.appMessagesService.show(MessageTemplates.UnexpectedError, {
            centered: true,
          });
        }
      })
      .catch(() => {
        this.appMessagesService.show(MessageTemplates.UnexpectedError, {
          centered: true,
        });
      })
      .finally(() => {
        this.loadCompleted();
      });
  }

  onCloseModal(): void {
    const SpeculativeUnsavedDataToSaveConfirm: AppMessage =
      MessageTemplates.SpeculativeUnsavedDataToSaveConfirm;

    if (this.saveEnable) {
      this.appMessagesService.show(SpeculativeUnsavedDataToSaveConfirm, {
        centered: true,
      });
      SpeculativeUnsavedDataToSaveConfirm.buttons.forEach(
        (x: AppMessageButton) => {
          x.action = (): void => {
            if (x.text === 'Yes') {
              this.appMessagesService.close(
                SpeculativeUnsavedDataToSaveConfirm.id
              );
              this.onSave(true);
              this.currentModal.dismiss();
              this.clearData();
            } else {
              this.appMessagesService.close(
                SpeculativeUnsavedDataToSaveConfirm.id
              );
              this.currentModal.dismiss();
              this.clearData();
            }
          };
        }
      );
    } else {
      this.currentModal.dismiss();
      this.clearData();
    }
  }

  onCgsSearchTextChanged(searchTextCgs: string): void {
    this.searchTextCgs = searchTextCgs;
  }

  onMarketsSearchTextChanged(searchTextMarkets: string): void {
    this.searchTextMarkets = searchTextMarkets;
  }

  onWmuSearchTextChanged(searchTextWmu: string): void {
    this.searchTextWmu = searchTextWmu;
  }

  onIndustrySearchTextChanged(searchTextIndustry: string): void {
    this.searchTextIndustry = searchTextIndustry;
  }

  onSgSelectionChanged(selectAll: boolean): void {
    this.sgSelectAllChangedEvent.next(selectAll);
  }

  onPUSelectionChanged(selectAll: boolean): void {
    this.puSelectAllChangedEvent.next(selectAll);
  }

  onPUItemSelection(currentSelectedPU: TreeViewSelection): void {
    this.hasPUFormChanged = false;
    this.isPUSelected =
      this.speculative?.profitUplift?.length > 0 ? true : false;
    const childsSelectedPU = this.extractingChilds(currentSelectedPU);

    const toDeleteIds: Array<string> = pullAll(
      this.selectedPU.map((x: Record<string, any>) => {
        return x.id;
      }),
      childsSelectedPU.selectedItems.map((x: OptionItem) => x.value)
    );

    toDeleteIds.forEach((x: string) => {
      this.puForm.removeControl(x);
    });

    this.selectedPU = childsSelectedPU.selectedItems.map((x: OptionItem) => {
      const exist: any = this.selectedPU.find(
        (y: Record<string, any>) => y.id === x.value
      );

      if (!exist) {
        this,
          this.puForm.addControl(
            x.value,
            new FormControl('0%', [this.validatePercentage])
          );
        this.totals.forEach((total) => {
          this,
            this.puForm.addControl(
              x.value + '_' + total,
              new FormControl('0.00')
            );
        });
      }
      this.isPUSelected = true;
      return {
        id: x.value,
        name: x.text,
        percentageValue: exist?.percentageValue ?? 0,
      };
    });

    if (this.selectedPU.length === this.speculative?.profitUplift?.length) {
      this.selectedPU.forEach((x: Record<string, any>) => {
        const pu: Record<string, any> = this.speculative?.profitUplift?.find(
          (y: Record<string, any>) => y.profitLever === x.id
        );
        this.hasPUFormChanged =
          this.hasPUFormChanged ||
          isUndefined(pu) ||
          pu.profitPercent !== x.percentageValue;
      });
    } else {
      this.hasPUFormChanged = true;
    }

    this.calculatePUTotal();
  }

  onSgItemSelection(selectedSgs: Array<OptionItem>): void {
    this.hasSgFormChanged = false;

    const toDeleteIds: Array<string> = pullAll(
      this.selectedSgs.map((x: Record<string, any>) => {
        return x.id;
      }),
      selectedSgs.map((x: OptionItem) => x.value)
    );

    toDeleteIds.forEach((x: string) => {
      this.sgForm.removeControl(x);
    });

    const hasOnlyOne: boolean = selectedSgs.length === 1;

    this.selectedSgs = selectedSgs.map((x: OptionItem) => {
      const exist: any = this.selectedSgs.find(
        (y: Record<string, any>) => y.id === x.value
      );

      if (!exist) {
        this,
          this.sgForm.addControl(
            x.value,
            new FormControl(hasOnlyOne ? '100%' : '0%', [
              this.validatePercentage,
            ])
          );
      }

      return {
        id: x.value,
        name: x.text,
        percentageValue: exist?.percentageValue ?? 0,
      };
    });

    this.selectedSgs.forEach((x: Record<string, any>) => {
      const sg: Record<string, any> = this.speculative?.serviceGroups?.find(
        (y: Record<string, any>) => y.id === x.id
      );
      this.hasSgFormChanged =
        this.hasSgFormChanged ||
        isUndefined(sg) ||
        sg.percentageValue !== x.percentageValue;
    });

    this.calculateSgTotal();
  }

  onCgItemSelection(selectedCgs: Array<OptionItem>): void {
    const newValue: OptionItem =
      selectedCgs && selectedCgs.length > 0 ? selectedCgs[0] : null;
    const hasChanged: boolean =
      !!newValue && newValue.value !== this.selectedClientGroup?.ClientGroupCd;

    this.selectedClientGroup = hasChanged
      ? this.config.customerFilters.csg.find(
          (x: ClientGroup) => x.ClientGroupCd === newValue.value
        )
      : this.selectedClientGroup;

    this.hasCgChanged =
      this.speculative?.clientServiceGroup?.clientServiceGroupId !==
      this.selectedClientGroup?.ClientGroupCd;

    this.checkControlsAvailability();

    if (hasChanged) {
      this.loadMarkets();
    }
  }

  onMarketItemSelection(selectedMarkets: TreeViewSelection): void {
    const newValue: OptionItem =
      selectedMarkets &&
      selectedMarkets.selectedItems &&
      selectedMarkets.selectedItems.length > 0
        ? selectedMarkets.selectedItems[0]
        : null;
    const hasChanged: boolean =
      !!newValue && newValue.value !== this.selectedMarket?.OrganizationId;

    this.selectedMarket = hasChanged
      ? this.config.filters.locations.find(
          (x: Location) => x.OrganizationId === newValue.value
        )
      : this.selectedMarket;

    this.hasMarketChanged =
      this.speculative?.location?.beneficiaryOrgId !==
      this.selectedMarket?.OrganizationId?.toString();

    this.checkControlsAvailability();
  }

  onWmuItemSelection(selectedWmus: TreeViewSelection): void {
    const newValue: OptionItem =
      selectedWmus &&
      selectedWmus.selectedItems &&
      selectedWmus.selectedItems.length > 0
        ? selectedWmus.selectedItems[0]
        : null;
    const hasChanged: boolean =
      !!newValue && newValue.value !== this.selectedWmu?.ID;

    this.selectedWmu = hasChanged
      ? this.config.customerFilters.wmu.find(
          (x: Wmu) => x.ID === newValue.value
        )
      : this.selectedWmu;

    this.hasWmuChanged =
      this.speculative?.wmu?.wmuId?.toString() !==
      this.selectedWmu?.ID?.toString();

    this.checkControlsAvailability();
  }

  onIndustryItemSelection(selectedIndustries: TreeViewSelection): void {
    const newValue: OptionItem =
      selectedIndustries &&
      selectedIndustries.selectedItems &&
      selectedIndustries.selectedItems.length > 0
        ? selectedIndustries.selectedItems[0]
        : null;
    const hasChanged: boolean =
      !!newValue && newValue.value !== this.selectedIndustry?.EntityCode;

    this.selectedIndustry = hasChanged
      ? this.config.filters.industry.find(
          (x: Industry) => x.EntityCode === newValue.value
        )
      : this.selectedIndustry;

    this.hasIndustryChanged =
      this.speculative?.industryCode?.toString() !==
      this.selectedIndustry?.EntityCode;

    this.checkControlsAvailability();
  }

  onInputClick(event: any): void {
    (event.target as HTMLInputElement).select();
  }

  onNumberInputFocus(
    event: any,
    form: FormGroup,
    controlName: string,
    type: ValueType
  ): void {
    const control: AbstractControl = form.get(controlName);
    control.setValue(this.removeControlFormatNumber(form, controlName, type));
  }

  onNumberInputBlur(
    event: any,
    form: FormGroup,
    controlName: string,
    type: ValueType
  ): void {
    const control: AbstractControl = form.get(controlName);
    const numberPipe = new CustomNumberPipe();
    const value = Number(
      type === ValueType.Percentage ? control.value / 100 : control.value
    );

    this.updateMetricTotal(controlName, value);
    control.setValue(numberPipe.transform(value, type, ''));
  }

  onPhasingChanged(event: SpeculativePhasingChanged): void {
    this.phasingChanged = event;
    this.checkControlsAvailability();
  }

  onSave(close?: boolean): void {
    this.loadStarted();
    const speculative: Speculative = this.getSpeculativeData();

    if (this.validateBillion(speculative)) {
      const billionAlert: AppMessage = MessageTemplates.SpeculativeBillonAlert;
      this.appMessagesService.show(billionAlert, {
        centered: true,
      });
      billionAlert.buttons.forEach((x: AppMessageButton) => {
        x.action = (): void => {
          if (x.text === 'Continue') {
            this.appMessagesService.close(billionAlert.id);
            this.saveSpeculative(speculative, close);
          } else {
            this.appMessagesService.close(billionAlert.id);
            this.loadCompleted();
          }
        };
      });
    } else {
      this.saveSpeculative(speculative, close);
    }
  }

  onSaveAndClose(): void {
    this.onSave(true);
  }

  private validateLength(control: AbstractControl): ValidatorFn {
    const value: string = replace(control.value, /[(,)-]/gi, '');
    const isValidRegExp = new RegExp(/^\d+$/).test(value);
    if (
      String(value).length <= 9 &&
      String(value).length >= 0 &&
      isValidRegExp
    ) {
      return null;
    }

    return {
      validateLength: {
        valid: false,
      },
    } as any;
  }

  private validatePercentage(control: AbstractControl): ValidatorFn {
    const value: string = replace(control.value.toString(), '%', '');
    const isValidRegExp = new RegExp(/^\d+$/).test(value);

    if (Number(value) <= 100 && Number(value) >= 0 && isValidRegExp) {
      return null;
    }

    return {
      validatePercentage: {
        valid: false,
      },
    } as any;
  }

  private validateFinancialCustomer(
    control: AbstractControl
  ): Observable<ValidationErrors | null> {
    const exists: boolean = this.config.customerFilters.financialCustomer.some(
      (x: FinancialCustomer) => x.getFullName() === control.value
    );

    return (
      exists || control.value === ''
        ? of(null)
        : of({ financialCustomerNotFound: true })
    ).pipe(
      finalize(() => {
        control.root.updateValueAndValidity({ onlySelf: true });
      })
    );
  }

  private validatePerson(
    control: AbstractControl
  ): Observable<ValidationErrors | null> {
    const input: string = control.value ? control.value.toString() : '';
    return this.mrdrService.getEnterpriseIds(input).pipe(
      map((x: Array<string>) => {
        return x.find((y: string) => y === input) || input === ''
          ? null
          : { responsibleNotFound: true };
      }),
      finalize(() => {
        control.root.updateValueAndValidity({ onlySelf: true });
      })
    );
  }

  private removeControlFormatNumber(
    form: FormGroup,
    controlName: string,
    type: ValueType
  ): number {
    const control: AbstractControl = form.get(controlName);
    const value: string = control.value?.toString();

    if (control && value) {
      return this.removeFormatNumber(value, type);
    }

    return 0;
  }

  private removeFormatNumber(value: string, type: ValueType): number {
    let result = 0;

    if (value) {
      let isNegative = false;

      if (
        (startsWith(value, '(') && endsWith(value, ')')) ||
        startsWith(value, '-')
      ) {
        isNegative = true;
      }

      value = replace(value, /[(,)-]/gi, '');

      switch (type) {
        case ValueType.Percentage:
          result = Number(replace(value, '%', ''));
          break;
        default:
          result = Number(value);
          break;
      }

      if (isNegative) {
        result = result * -1;
      }
    }

    return result;
  }

  private extractingChilds(
    treeViewSelected: TreeViewSelection
  ): TreeViewSelection {
    const clonedTree = cloneDeep(treeViewSelected);

    if (
      !treeViewSelected.selectedItems.length ||
      !treeViewSelected.selectedItems.some((item) => !item.hasParent)
    ) {
      return clonedTree;
    }

    clonedTree.selectedItems = treeViewSelected.selectedItems.flatMap((item) =>
      item.children?.length ? item.children : [item]
    );

    return clonedTree;
  }
}
