import { Component, OnInit, ViewChild } from '@angular/core';
import { combineLatest, Subscription } from 'rxjs';
import { NavigationStart, Router } from '@angular/router';
import { cloneDeep } from 'lodash';
import { NgbDropdown, NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';

import { FavoriteModalComponent } from '../favorite-modal/favorite-modal.component';
import { CustomerFilters } from 'src/app/shared/services/entities/filters/customer-filters';
import { Filters } from 'src/app/shared/services/entities/filters/filters';
import { SelectedFilters } from 'src/app/shared/services/entities/filters/selected-filters';
import {
  AppMessage,
  AppMessageButton,
} from 'src/app/shared/services/entities/app-message';
import { MessageTemplates } from 'src/app/shared/constants/messages.constants';
import { AppMessagesService } from 'src/app/shared/services/app-messages.service';
import {
  FavoriteFilters,
  FavoriteResponseItem,
} from 'src/app/shared/services/entities/favorite-response';
import { FavoriteModalConfig } from '../favorite-modal/entities/favorite-modal-config';
import { FavoriteSaveModel } from '../favorite-modal/entities/favorite-save-model';
import { FavoriteMapper } from '../favorite-modal/utils/favorite-modal-utils';
import { ShareFavoriteModalConfig } from './share-favorite-modal/entities/share-favorite-modal-config';
import {
  CustomCurrencies,
  GroupsBy,
  Periods,
} from 'src/app/shared/constants/filters.constants';
import { AppState } from 'src/app/shared/services/entities/app-state/app-state';
import { AppModulePaths } from 'src/app/shared/constants/navbar.constants';

import { FavoriteModalService } from 'src/app/shared/services/modals/favorite-modal.service';
import { FiltersService } from 'src/app/shared/services/filters.service';
import { FavoriteService } from 'src/app/shared/services/favorite.service';
import { AppStateService } from 'src/app/shared/services/app-state.service';
import { FavoriteFilterDefaults } from '../favorite-modal/entities/favorite-filter-defaults';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faEdit,
  faShareAlt,
  faCopy,
  faTrash,
  faStar as faStarPainted,
} from '@fortawesome/free-solid-svg-icons';
import { faStar } from '@fortawesome/free-regular-svg-icons';
import { skipWhile } from 'rxjs/operators';
import { ShareFavoriteModalComponent } from './share-favorite-modal/share-favorite-modal.component';
import { SpinnerComponent } from '../../../../shared/components/base/spinner/spinner.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgIf, NgFor } from '@angular/common';
import { SharedModule } from 'src/app/shared-module';

@Component({
  selector: 'app-favorite-picker',
  templateUrl: './favorite-picker.component.html',
  styleUrls: ['./favorite-picker.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    SharedModule,
    FontAwesomeModule,
    SpinnerComponent,
    NgFor,
    ShareFavoriteModalComponent,
  ],
})
export class FavoritePickerComponent implements OnInit {
  @ViewChild('favoriteModalComponent', { static: false })
  favoriteModal: FavoriteModalComponent;
  @ViewChild('favoriteDropdown', { static: false })
  favoriteDropdown: NgbDropdown;

  subscription = new Subscription();
  loaded: boolean;
  isAnalytics: boolean;
  filters: Filters;
  selectedFilters: SelectedFilters;
  customerFilters: CustomerFilters;
  appState: AppState;
  favorites: Array<FavoriteResponseItem>;
  favoriteFilterDefaults: FavoriteFilterDefaults;
  isfirstChange = true;

  readonly noneFavoriteName = 'None';

  faEdit = faEdit as IconProp;
  faShare = faShareAlt as IconProp;
  faCopy = faCopy as IconProp;
  faTrash = faTrash as IconProp;
  faStarPainted = faStarPainted as IconProp;
  faStar = faStar as IconProp;

  constructor(
    private router: Router,
    private filtersService: FiltersService,
    private favoriteModalService: FavoriteModalService,
    private favoriteService: FavoriteService,
    private appMessagesService: AppMessagesService,
    private appStateService: AppStateService
  ) {}

  ngOnInit(): void {
    this.subscription.add(
      this.router.events.subscribe((x: any) => {
        if (x instanceof NavigationStart) {
          this.isAnalytics = x.url.includes(AppModulePaths.analytics.path);
          this.isfirstChange = true;
        }
      })
    );

    this.subscription.add(
      this.filtersService.globalFiltersChanged.subscribe((x: Filters) => {
        this.filters = x;
      })
    );
    this.subscription.add(
      this.filtersService.selectedFiltersChanged.subscribe(
        (x: SelectedFilters) => {
          const customerChanged: boolean =
            this.selectedFilters?.customer.MasterCustomerNumber !==
            x.customer.MasterCustomerNumber;
          this.selectedFilters = x;
          if (!customerChanged) {
            this.getFavorites();
          }
        }
      )
    );

    this.subscription.add(
      this.appStateService.appStateChanged.subscribe((x: AppState) => {
        this.appState = x;
      })
    );

    this.subscription.add(
      this.filtersService.customerFiltersChanged
        .pipe(skipWhile(() => !this.selectedFilters && !this.filters))
        .subscribe((x: CustomerFilters) => {
          this.customerFilters = x;
          this.getFavorites(true);
        })
    );

    this.subscription.add(
      this.favoriteModalService.setFavoriteModalParamsEmitted.subscribe(
        (x: FavoriteFilterDefaults) => {
          this.favoriteFilterDefaults = x;
        }
      )
    );
    this.subscription.add(
      combineLatest([
        this.filtersService.isCloudFiltersChanged,
        this.filtersService.isCloudGlobalFiltersChanged,
      ]).subscribe(([x, y]: [CustomerFilters, Filters]) => {
        this.customerFilters = x;
        this.filters = y;
      })
    );
  }

  loadStarted(): void {
    this.loaded = false;
  }

  loadCompleted(): void {
    this.loaded = true;
  }

  getFavorites(customerChanged?: boolean): void {
    this.loadStarted();

    this.favoriteService
      .getFavorites(
        this.selectedFilters.customer.MasterCustomerNumber,
        this.filters
      )
      .then((y: Array<FavoriteResponseItem>) => {
        y.unshift(this.getNoneFavorite());
        this.favorites = y;
        this.loadCompleted();
        this.checkForSharedFavorites();

        if (this.isAnalytics && (this.isfirstChange || customerChanged)) {
          this.applyDefault();
          this.isfirstChange = false;
        }
      });
  }

  getNoneFavorite(): FavoriteResponseItem {
    const favoriteFilters = new FavoriteFilters();
    favoriteFilters.setDefaultFilters(this.filters, this.selectedFilters);

    return new FavoriteResponseItem({
      favoriteId: '',
      name: this.noneFavoriteName,
      filters: favoriteFilters,
      metric: '',
      period: Periods.Quarter.id,
      group1: GroupsBy.ClientGroup.id,
    });
  }

  checkForSharedFavorites(): void {
    const sharedFavorites: Array<FavoriteResponseItem> = this.favorites?.filter(
      (x: FavoriteResponseItem) => !!x.sharedBy
    );

    if (sharedFavorites?.length > 0) {
      sharedFavorites
        .reduce((x: Promise<void>, y: FavoriteResponseItem) => {
          return x.then(() => {
            return this.openSharedFavoriteConfirmation(y);
          });
        }, Promise.resolve())
        .then(() => {
          this.getFavorites();
        });
    }
  }

  openSharedFavoriteConfirmation(
    favorite: FavoriteResponseItem
  ): Promise<void> {
    return new Promise((resolve: () => void, reject: () => void): void => {
      const confirmationMessage: AppMessage = cloneDeep(
        MessageTemplates.FavoriteSharedConfirmation
      );
      confirmationMessage.body = confirmationMessage.body.map((x: string) => {
        x = x.replace('[from]', favorite.sharedBy);
        x = x.replace(
          '[favoriteName]',
          favorite.name.split('shared by')[0].trim()
        );
        return x;
      });

      confirmationMessage.buttons.forEach((x: AppMessageButton) => {
        x.action = (): void => {
          const model: FavoriteSaveModel =
            FavoriteMapper.getSaveModel(favorite);
          model.sharedBy = null;
          (x.text === 'Confirm'
            ? this.favoriteService.updateFavorite(model)
            : this.favoriteService.deleteFavorite(
                model.favoriteId,
                this.selectedFilters.customer.MasterCustomerNumber
              )
          ).then((x: boolean) => {
            this.appMessagesService.close(confirmationMessage.id);
            resolve();
          });
        };
      });

      this.appMessagesService.show(confirmationMessage, { centered: true });
    });
  }

  applyDefault(): void {
    if (this.favorites.some((x: FavoriteResponseItem) => x.default)) {
      const defaultFavorite: FavoriteResponseItem = this.favorites.find(
        (x: FavoriteResponseItem) => x.default
      );
      this.onSelect(defaultFavorite);
    }
  }

  onOpenFavoriteModal(): void {
    this.favoriteModalService.openFavoriteModalEmitted.next(
      new FavoriteModalConfig({
        name: '',
        favoriteId: '',
        filters: this.filters,
        customerFilters: this.customerFilters,
        selectedFilters: this.selectedFilters,
        favoriteFilters: new FavoriteFilters(),
        appState: this.appState,
        metrics: this.favoriteFilterDefaults.metric,
        period: this.favoriteFilterDefaults.period,
        group1: this.favoriteFilterDefaults.group1,
        group2: this.favoriteFilterDefaults.group2,
        group3: this.favoriteFilterDefaults.group3,
        group4: this.favoriteFilterDefaults.group4,
        target1: this.favoriteFilterDefaults.target1,
        target2: this.favoriteFilterDefaults.target2,
      })
    );
  }

  onDefaultChanged(favorite: FavoriteResponseItem): void {
    this.favoriteDropdown?.close();
    this.favoriteService
      .markDefault(favorite)
      .then((x: boolean) => {
        if (x) {
          this.onSelect(favorite);
        }
      })
      .catch(() => {
        this.appMessagesService.show(MessageTemplates.UnexpectedError, {
          centered: true,
        });
      });
  }

  onSelect(favorite: FavoriteResponseItem): void {
    this.favoriteDropdown?.close();
    if (favorite.filters) {
      if (!favorite.filters.Currency) {
        favorite.filters.Currency = {
          Id: CustomCurrencies.Global.id,
          Description: CustomCurrencies.Global.text,
        };
      }
      Object.setPrototypeOf(favorite.filters, FavoriteFilters.prototype);
      this.filtersService.updateSelectedFilters(
        favorite.filters.toSelectedFilters(
          this.filters,
          this.selectedFilters,
          this.customerFilters,
          favorite
        )
      );
      this.filtersService.applyFilters();
      this.favoriteModalService.applyFavoriteEmitted.next(
        favorite.favoriteId?.length === 0 ? null : favorite
      );
    }
  }

  onEdit(favorite: FavoriteResponseItem, isDuplicate?: boolean): void {
    this.favoriteDropdown?.close();
    this.favoriteModalService.openFavoriteModalEmitted.next(
      new FavoriteModalConfig({
        name: favorite.name,
        favoriteId: isDuplicate ? '' : favorite.favoriteId,
        filters: this.filters,
        customerFilters: this.customerFilters,
        selectedFilters: this.selectedFilters,
        favoriteFilters: favorite.filters,
        appState: this.appState,
        metrics: favorite.metric,
        period: favorite.period,
        group1: favorite.group1,
        group2: favorite.group2,
        group3: favorite.group3,
        group4: favorite.group4,
        target1: favorite.target1,
        target2: favorite.target2,
      })
    );
  }

  onDelete(favorite: FavoriteResponseItem): void {
    this.favoriteDropdown?.close();
    const deleteConfirmMessage: AppMessage =
      MessageTemplates.FavoriteDeleteConfirm;

    deleteConfirmMessage.buttons.forEach((x: AppMessageButton) => {
      x.action = (): void => {
        if (x.text === 'Yes') {
          this.favoriteService
            .deleteFavorite(
              favorite.favoriteId,
              this.selectedFilters.customer.MasterCustomerNumber
            )
            .then((x: boolean) => {
              this.appMessagesService.close(deleteConfirmMessage.id);
              this.filtersService.applyFilters();
            });
        } else {
          this.appMessagesService.close(deleteConfirmMessage.id);
        }
      };
    });

    this.appMessagesService.show(deleteConfirmMessage, {
      centered: true,
    });
  }

  onShare(favorite: FavoriteResponseItem): void {
    this.favoriteDropdown?.close();
    this.favoriteModalService.openShareFavoriteModalEmitted.next(
      new ShareFavoriteModalConfig({
        favorite,
      })
    );
  }
}
