<div>
  <div ngbAccordion #acc="ngbAccordion" id="accordion" [closeOthers]="true" class="accordion">
    <div ngbAccordionItem
      [disabled]="isFilterBlocked(filterTypes.WMU) && isFilterBlocked(filterTypes.FinancialCustomer)"
      [destroyOnHide]="false">
      <div ngbAccordionHeader (click)="changeSelection('WMU')">
        <button ngbAccordionButton class="title-text">
          <span class="arrow">
            <fa-icon [icon]="icon === 'WMU' ? faAngleDown : faAngleRight">
            </fa-icon>
          </span>
          WMU / FC ({{(filtersManager.wmu || filtersManager.fc)?.count
          ?? 0}})</button>
      </div>
      <div ngbAccordionCollapse>
        <div ngbAccordionBody class="accordion-body">
          <ng-template>
            <app-wmu-fc [wmu]="customerFilters?.wmu" [financialCustomer]="customerFilters?.financialCustomer"
              [selectedWmus]="selectedFilters?.wmus" [selectedFinancialCustomers]="selectedFilters?.financialCustomers"
              (itemSelectionChangeEvent)="onItemSelection($event)"></app-wmu-fc>
          </ng-template>
        </div>
      </div>
    </div>
    <div ngbAccordionItem [disabled]="isFilterBlocked(filterTypes.Market)" [destroyOnHide]="false">
      <div ngbAccordionHeader (click)="changeSelection('Market')">
        <button ngbAccordionButton class="title-text">
          <span class="arrow">
            <fa-icon [icon]="icon === 'Market' ? faAngleDown : faAngleRight">
            </fa-icon>
          </span>
          Market ({{filtersManager.market?.count
          ?? 0}})</button>
      </div>
      <div ngbAccordionCollapse>
        <div ngbAccordionBody class="accordion-body">
          <ng-template>
            <app-market [markets]="filters?.locations" [selectedMarkets]="selectedFilters?.markets"
              (itemSelectionChangeEvent)="onItemSelection($event)">
            </app-market>
          </ng-template>
        </div>
      </div>
    </div>
    <div ngbAccordionItem [disabled]="isFilterBlocked(filterTypes.ClientGroup)" [destroyOnHide]="false">
      <div ngbAccordionHeader (click)="changeSelection('CG')">
        <button ngbAccordionButton class="title-text">
          <span class="arrow">
            <fa-icon [icon]="icon === 'CG' ? faAngleDown : faAngleRight">
            </fa-icon>
          </span>
          CG ({{filtersManager.cg?.count
          ?? 0}})</button>
      </div>
      <div ngbAccordionCollapse>
        <div ngbAccordionBody class="accordion-body">
          <ng-template>
            <app-client-group [clientGroups]="customerFilters?.csg"
              [selectedClientGroups]="selectedFilters?.clientGroups"
              (itemSelectionChangeEvent)="onItemSelection($event)">
            </app-client-group>
          </ng-template>
        </div>
      </div>
    </div>
    <div ngbAccordionItem [disabled]="isFilterBlocked(filterTypes.ServiceGroup)" [destroyOnHide]="false">
      <div ngbAccordionHeader (click)="changeSelection('SG')">
        <button ngbAccordionButton class="title-text">
          <span class="arrow">
            <fa-icon [icon]="icon === 'SG' ? faAngleDown : faAngleRight">
            </fa-icon>
          </span>
          Service Group ({{filtersManager.sg?.count
          ?? 0}})</button>
      </div>
      <div ngbAccordionCollapse>
        <div ngbAccordionBody class="accordion-body">
          <ng-template>
            <app-service-group [groups]="filters?.serviceGroups?.Groups"
              [serviceGroups]="filters?.serviceGroups?.ServiceGroups"
              [selectedServiceGroups]="selectedFilters?.serviceGroups"
              (itemSelectionChangeEvent)="onItemSelection($event)">
            </app-service-group>
          </ng-template>
        </div>
      </div>
    </div>
    <div ngbAccordionItem [disabled]="isFilterBlocked(filterTypes.Industry)" [destroyOnHide]="false">
      <div ngbAccordionHeader (click)="changeSelection('Industry')">
        <button ngbAccordionButton class="title-text">
          <span class="arrow">
            <fa-icon [icon]="icon === 'Industry' ? faAngleDown : faAngleRight">
            </fa-icon>
          </span>
          Industry ({{filtersManager.industry?.count
          ?? 0}})</button>
      </div>
      <div ngbAccordionCollapse>
        <div ngbAccordionBody class="accordion-body">
          <ng-template>
            <app-industry [industries]="filters?.industry" [selectedIndustries]="selectedFilters?.industries"
              (itemSelectionChangeEvent)="onItemSelection($event)">
            </app-industry>
          </ng-template>
        </div>
      </div>
    </div>
    <div ngbAccordionItem [disabled]="isFilterBlocked(filterTypes.Function)" [destroyOnHide]="false">
      <div ngbAccordionHeader (click)="changeSelection('Function')">
        <button ngbAccordionButton class="title-text">
          <span class="arrow">
            <fa-icon [icon]="icon === 'Function' ? faAngleDown : faAngleRight">
            </fa-icon>
          </span>
          Functions ({{filtersManager.functions?.count
          ?? 0}})</button>
      </div>
      <div ngbAccordionCollapse>
        <div ngbAccordionBody class="accordion-body">
          <ng-template>
            <app-functions [functions]="filters?.functions" [selectedFunctions]="selectedFilters?.functions"
              (itemSelectionChangeEvent)="onItemSelection($event)">
            </app-functions>
          </ng-template>
        </div>
      </div>
    </div>
    <div ngbAccordionItem [disabled]="isFilterBlocked(filterTypes.GrowthPriority)" [destroyOnHide]="false">
      <div ngbAccordionHeader (click)="changeSelection('GP')">
        <button ngbAccordionButton class="title-text">
          <span class="arrow">
            <fa-icon [icon]="icon === 'GP' ? faAngleDown : faAngleRight">
            </fa-icon>
          </span>
          Growth Engines ({{filtersManager.gp?.count
          ?? 0}})</button>
      </div>
      <div ngbAccordionCollapse>
        <div ngbAccordionBody class="accordion-body">
          <ng-template>
            <app-growth-priorities [growthPriorities]="filters?.sgi"
              [selectedGrowthPriorities]="selectedFilters?.growthPriorities"
              (itemSelectionChangeEvent)="onItemSelection($event)"></app-growth-priorities>
          </ng-template>
        </div>
      </div>
    </div>
    <div ngbAccordionItem [disabled]="isFilterBlocked(filterTypes.Alliance)" [destroyOnHide]="false">
      <div ngbAccordionHeader (click)="changeSelection('Alliance')">
        <button ngbAccordionButton class="title-text">
          <span class="arrow">
            <fa-icon [icon]="icon === 'Alliance' ? faAngleDown : faAngleRight">
            </fa-icon>
          </span>
          Alliances ({{filtersManager.alliances?.count
          ?? 0}})</button>
      </div>
      <div ngbAccordionCollapse>
        <div ngbAccordionBody class="accordion-body">
          <ng-template>
            <app-alliances [alliances]="filters?.alliances" [selectedAlliances]="selectedFilters?.alliances"
              (itemSelectionChangeEvent)="onItemSelection($event)">
            </app-alliances>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
</div>