import { Component, OnDestroy, OnInit, ElementRef } from '@angular/core';
import { Subscription } from 'rxjs';

import { StorageUtils } from './core/utils/storage.utils';
import { environment } from 'src/environments/environment';
import { StorageKeys, StorageType } from './core/constants/storage.constants';

import { RebarAuthService } from './core/rebarauth/rebar.auth.service';
import { FiltersService } from './shared/services/filters.service';
import { SharedService } from './shared/services/shared.service';
import { AppConfigService } from './core/services/app-config.service';
import { FooterComponent } from './footer/footer.component';
import { SpinnerComponent } from './shared/components/base/spinner/spinner.component';
import { FavoriteModalComponent } from './modules/analytics/components/favorite-modal/favorite-modal.component';
import { BoardModalComponent } from './shared/components/board-modal/board-modal.component';
import { SpeculativeModalComponent } from './shared/components/speculative-modal/speculative-modal.component';
import { OpportunityPhasingModalComponent } from './shared/components/opportunity-phasing-modal/opportunity-phasing-modal.component';
import { TrendModalComponent } from './shared/components/tiles/trend-modal/trend-modal.component';
import { TrainingModeIndicatorComponent } from './shared/components/training-mode-indicator/training-mode-indicator.component';
import { ImpersonationIndicatorComponent } from './shared/components/impersonation-indicator/impersonation-indicator.component';
import { ChatComponent } from './shared/components/notification-framework/chat/chat.component';
import { ToastComponent } from './shared/components/notification-framework/toast/toast.component';
import { AlertComponent } from './shared/components/notification-framework/alert/alert.component';
import { ModalComponent } from './shared/components/notification-framework/modal/modal.component';
import { UniversalHeaderComponent } from './shared/components/notification-framework/universal-header/universal-header.component';
import { AppToastsComponent } from './shared/components/app-toasts/app-toasts.component';
import { RouterOutlet } from '@angular/router';
import { NgIf } from '@angular/common';
import { ActualsIndicatorComponent } from './shared/components/actuals-indicator/actuals-indicator.component';
import { ReadonlyModeIndicatorComponent } from './shared/components/readonly-mode-indicator/readonly-mode-indicator.component';
import { AdminIndicatorComponent } from './shared/components/admin-indicator/admin-indicator.component';
import { FavoritePickerComponent } from './modules/analytics/components/favorite-picker/favorite-picker.component';
import { MsaFilterComponent } from './shared/components/msa-filter/msa-filter.component';
import { BoardPickerComponent } from './shared/components/board-picker/board-picker.component';
import { FiltersPickerComponent } from './shared/components/filters-picker/filters-picker.component';
import { CustomerPickerComponent } from './shared/components/customer-picker/customer-picker.component';
import { NavbarComponent } from './shared/components/navbar/navbar.component';
import { ProfitUpliftSwitchComponent } from './modules/speculative/components/profitUplift-switch-filter/profitUplift-switch-filter.component';
import { FiltersPickerProfitupliftComponent } from './modules/speculative/components/filter-picker-profitUplift/filters-picker-profituplift.component';
import { ProfitupliftModalComponent } from './shared/components/tiles/profituplift-modal/profituplift-modal.component';
//eslint-disable-next-line @typescript-eslint/no-explicit-any
declare let IM_CLD_CentRum: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [
    NavbarComponent,
    CustomerPickerComponent,
    FiltersPickerComponent,
    BoardPickerComponent,
    MsaFilterComponent,
    ProfitUpliftSwitchComponent,
    FiltersPickerProfitupliftComponent,
    FavoritePickerComponent,
    AdminIndicatorComponent,
    ReadonlyModeIndicatorComponent,
    ActualsIndicatorComponent,
    NgIf,
    RouterOutlet,
    AppToastsComponent,
    UniversalHeaderComponent,
    ModalComponent,
    AlertComponent,
    ToastComponent,
    ChatComponent,
    ImpersonationIndicatorComponent,
    TrainingModeIndicatorComponent,
    TrendModalComponent,
    ProfitupliftModalComponent,
    OpportunityPhasingModalComponent,
    SpeculativeModalComponent,
    BoardModalComponent,
    FavoriteModalComponent,
    SpinnerComponent,
    FooterComponent,
  ],
})
export class AppComponent implements OnInit, OnDestroy {
  subscription = new Subscription();
  title = 'cbp-web';
  loadingCompleted = false;

  constructor(
    private authService: RebarAuthService,
    private filtersService: FiltersService,
    private sharedService: SharedService,
    private appConfigService: AppConfigService,
    private elementRef: ElementRef
  ) {}

  ngOnInit(): void {
    StorageUtils.set(
      StorageType.LocalStorage,
      StorageKeys.ENVIRONMENT_URL,
      `${environment.baseServiceUrl}gonefishing`
    );

    this.addSubscriptions();

    // this.authService.authObserver$.subscribe((authStatus: boolean) => {
    //   console.log(`user is logged in ${authStatus}`);
    // });
  }

  addSubscriptions(): void {
    if (this.authService.isUserAuthenticated()) {
      this.sharedService.loadStarted();

      this.sharedService.saveLoginForMetrics();

      this.sharedService.checkImpersonation().then(() => {
        this.filtersService.init(() => {
          this.sharedService.loadCompleted();
        });
      });

      this.subscription.add(
        this.sharedService.hasLoadingCompleted.subscribe((x: boolean) => {
          this.loadingCompleted = x;
        })
      );
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  initializeDatadog(): void {
    const userTokenClaims = JSON.stringify(this.authService.getClaims());
    const parsedClaims = JSON.parse(userTokenClaims);
    const payload = {
      businessOrg: parsedClaims.businessOrg,
      countryDescription: parsedClaims.countryDescription,
      countryCode: parsedClaims.countryCode,
      jobTitle: parsedClaims.jobTitle,
      jobFamily: parsedClaims.jobFamily,
      location: parsedClaims.location,
      locationCode: parsedClaims.locationCode,
      jobFamilyDescription: parsedClaims.jobFamilyDescription,
    };
    sessionStorage.setItem('ddrumsdk.tokenclaims', JSON.stringify(payload));
    const script = document.createElement('script');
    const scriptUrl = (
      this.appConfigService.config['datadog'] as Record<string, string>
    )['datadogScriptUrl'];
    script.setAttribute('src', scriptUrl);
    this.elementRef.nativeElement.appendChild(script);
    script.onload = () => {
      IM_CLD_CentRum(this).log({
        applicationId: (
          this.appConfigService.config['datadog'] as Record<string, string>
        )['appid'],
        clientToken: (
          this.appConfigService.config['datadog'] as Record<string, string>
        )['clienttoken'],
        service: (
          this.appConfigService.config['datadog'] as Record<string, string>
        )['service'],
        env: (
          this.appConfigService.config['datadog'] as Record<string, string>
        )['environment'],
        sampleRate: 100,
        trackInteractions: true,
        allowedTracingOrigins: (
          this.appConfigService.config['datadog'] as Record<string, string>
        )['allowedtracingoriginsdns'],
        useSecureSessionCookie: true,
      });
    };
  }
}
