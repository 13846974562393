<div *ngIf="isSpeculative && isCloud" class="ddl-control" [ngbTooltip]="puTooltipContent" container="body"
  placement="right" [ngClass]="{'disabled' : !isTargetCurrProj}">
  <div class="ddl-input">
    <app-switch rightText="" leftText="" [checked]="isPUdtls" (switchChangeEvent)="onSwitch($event)" [disabled]="!isTargetCurrProj">
    </app-switch>
    <div class="span-content">
      <span>PROFIT UPLIFT DETAILS</span>
    </div>
  </div>
</div>
<ng-template #puTooltipContent>
  Profit Uplift Details only visible in the Speculative details table.
</ng-template>