import { assign } from 'lodash';
import { AppState } from 'src/app/shared/services/entities/app-state/app-state';

import { FavoriteFilters } from 'src/app/shared/services/entities/favorite-response';
import { CustomerFilters } from 'src/app/shared/services/entities/filters/customer-filters';
import { Filters } from 'src/app/shared/services/entities/filters/filters';
import { SelectedFilters } from 'src/app/shared/services/entities/filters/selected-filters';

class FavoriteModalConfigSchema {
  favoriteId: string;
  name: string;
  filters: Filters;
  selectedFilters: SelectedFilters;
  customerFilters: CustomerFilters;
  favoriteFilters: FavoriteFilters;
  appState: AppState;
  default?: boolean;
  metrics?: string;
  period?: string;
  group1: string;
  group2?: string;
  group3?: string;
  group4?: string;
  target1?: string;
  target2?: string;
}

export class FavoriteModalConfig extends FavoriteModalConfigSchema {
  constructor(filterAttributes?: FavoriteModalConfigSchema) {
    super();
    assign(this, filterAttributes);
  }

  isNew(): boolean {
    return !this.favoriteId || this.favoriteId === '';
  }
}
