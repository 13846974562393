import { sortBy } from 'lodash';
import { CalcUtils } from 'src/app/core/utils/calc.utils';
import {
  GridToolbarConfig,
  GridToolbarDropdown,
} from 'src/app/shared/components/base/grid/components/grid-toolbar/entities/grid-toolbar-config';
import { Periods } from 'src/app/shared/constants/filters.constants';
import {
  MetricsOptions,
  ViewOptions,
} from 'src/app/shared/constants/grid.constants';
import { MmbDate } from 'src/app/shared/services/entities/filters/date';
import { Filters } from 'src/app/shared/services/entities/filters/filters';
import {
  SpeculativePhasingPeriod,
  SpeculativeResponseItem,
} from 'src/app/shared/services/entities/grids/speculative-response';
import { ComponentNames } from '../constants/speculative-grid.constants';
import { SelectedFilters } from 'src/app/shared/services/entities/filters/selected-filters';
import { ProfitLevers } from 'src/app/shared/services/entities/filters/profitUplift';

export class SpeculativeGridUtils {
  static mapData(
    source: Array<SpeculativeResponseItem>,
    filters: Filters,
    toolbarConfig: GridToolbarConfig,
    isCustom: boolean
  ): Array<Record<string, any>> {
    const periodsDropdown: GridToolbarDropdown = toolbarConfig.findControl(
      ComponentNames.periods
    );
    const metricsDropdown: GridToolbarDropdown = toolbarConfig.findControl(
      ComponentNames.metrics
    );
    const targetsDropdown: GridToolbarDropdown = toolbarConfig.findControl(
      ComponentNames.targets
    );

    if (metricsDropdown.selected.value === MetricsOptions.Summary) {
      return source;
    }

    return source.map((x: SpeculativeResponseItem) => {
      let row: Record<string, any> = {
        id: x.id,
        name: x.name,
        status: x.status,
        ProfitLever: x.ProfitLever,
        ProfitLeverPer: x.ProfitLeverPer,
        Children: x.Children,
        onHoldByElapsedTime: x.onHoldByElapsedTime,
        reasonCode: x.reasonCode,
        total: new SpeculativePhasingPeriod({
          revenue: x.netRevenue,
          sales: x.sales,
          cci: x.deliveredCCIDollar,
          cciPercentage: x.deliveredCCIPercentage,
          currentRevenue: x.currentNetRevenue,
          currentSales: x.currentSales,
          currentCci: x.currentDeliveredCCIDollar,
          currentCciPercentage: x.currentDeliveredCCIPercentage,
          targetRevenue: x.targetNetRevenue,
          targetSales: x.targetSales,
          targetCci: x.targetDeliveredCCIDollar,
          targetCciPercentage: x.targetDeliveredCCIPercentage,
          timeId: null,
        }),
        fullLife: new SpeculativePhasingPeriod({
          revenue: x.fullLifeRevenue,
          sales: x.fullLifeSales,
          cci: x.fullLifeCCI,
          cciPercentage: x.fullLifeCCIPercentage,
          currentRevenue: x.currentFullLifeRevenue,
          currentSales: x.currentFullLifeSales,
          currentCci: x.currentFullLifeCCI,
          currentCciPercentage: x.currentFullLifeCCIPercentage,
          targetRevenue: x.targetFullLifeRevenue,
          targetSales: x.targetFullLifeSales,
          targetCci: x.targetFullLifeCCI,
          targetCciPercentage: x.targetFullLifeCCIPercentage,
          timeId: null,
        }),
      };

      if (periodsDropdown.selected.value === Periods.Quarter.id) {
        x.phasingQuaterly = this.getQuarterlyData(
          x,
          filters.dates,
          targetsDropdown.selected.value
        );
        row = this.mapQuarterlyData(row, x, isCustom);
      } else {
        row = this.mapMonthlyData(row, x, filters.dates);
      }

      return row;
    });
  }

  static getProfitLevers = (
    selectedFilters: SelectedFilters,
    filters: Filters
  ): string => {
    return (
      this.getChildsProfitLever(selectedFilters, filters)
        .map((x: ProfitLevers) => x.ProfitLeverId)
        .join(',') || ''
    );
  };

  static getProfitLeversExport = (
    selectedFilters: SelectedFilters,
    filters: Filters
  ): Array<Record<string, string>> => {
    return this.getChildsProfitLever(selectedFilters, filters).map(
      (x: ProfitLevers) => ({
        id: x.ProfitLeverId,
        value: x.ProfitLeverName,
      })
    );
  };

  private static getChildsProfitLever = (
    selectedFilters: SelectedFilters,
    filters: Filters
  ): ProfitLevers[] => {
    const pfParents = filters?.profitLevers.filter(
      (x) => x.ParentProfitLeverId === ''
    );
    selectedFilters.profitLevers = selectedFilters.profitLevers || [];
    const profitFiltersSelected = selectedFilters.profitLevers.flatMap((y) => {
      const isParent = pfParents?.some(
        (parent) => parent.ProfitLeverId === y.ProfitLeverId
      );
      return isParent
        ? filters.profitLevers.filter(
            (z) => z.ParentProfitLeverId === y.ProfitLeverId
          )
        : [y];
    });

    return profitFiltersSelected;
  };

  private static mapMonthlyData(
    row: Record<string, any>,
    source: SpeculativeResponseItem,
    dates: Array<MmbDate>
  ): Record<string, any> {
    source.phasingMonthly.forEach((x: SpeculativePhasingPeriod) => {
      const period: MmbDate = dates.find((y: MmbDate) => y.Id === x.timeId);
      row = {
        ...row,
        [period.Id]: x,
      };
    });

    return row;
  }

  private static mapQuarterlyData(
    row: Record<string, any>,
    source: SpeculativeResponseItem,
    isCustom: boolean
  ): Record<string, any> {
    source.phasingQuaterly.forEach((x: SpeculativePhasingPeriod) => {
      const quarter: string = isCustom
        ? `q${x.timeId}'${x.fiscalYear.toString().slice(-2)}`
        : `q${x.timeId}`;
      row = {
        ...row,
        [quarter]: x,
      };
    });

    return row;
  }

  private static getQuarterlyData(
    source: SpeculativeResponseItem,
    dates: Array<MmbDate>,
    target: string
  ): Array<SpeculativePhasingPeriod> {
    const quarters: Array<SpeculativePhasingPeriod> = [];

    source.phasingMonthly.forEach((x: SpeculativePhasingPeriod) => {
      const quarterDate: MmbDate = dates.find(
        (y: MmbDate) => x.timeId === y.Id
      );
      const quarterIndex: number = quarters.findIndex(
        (y: SpeculativePhasingPeriod) =>
          y.timeId === quarterDate.FiscalQuarterNbr
      );

      if (quarterIndex !== -1) {
        if (quarters[quarterIndex].fiscalYear === quarterDate.FiscalYearNbr) {
          quarters[quarterIndex].currentRevenue = CalcUtils.sumValues(
            quarters[quarterIndex].currentRevenue,
            x.currentRevenue
          );
          quarters[quarterIndex].currentCci = CalcUtils.sumValues(
            quarters[quarterIndex].currentCci,
            x.currentCci
          );
          quarters[quarterIndex].currentSales = CalcUtils.sumValues(
            quarters[quarterIndex].currentSales,
            x.currentSales
          );
          quarters[quarterIndex].currentCciPercentage =
            this.calculatePercentageValues(
              quarters[quarterIndex].currentCciPercentage as Record<
                string,
                number
              >,
              x.currentCciPercentage as Record<string, number>
            );
          quarters[quarterIndex].targetRevenue = CalcUtils.sumValues(
            quarters[quarterIndex].targetRevenue,
            x.targetRevenue
          );
          quarters[quarterIndex].targetCci = CalcUtils.sumValues(
            quarters[quarterIndex].targetCci,
            x.targetCci
          );
          quarters[quarterIndex].targetSales = CalcUtils.sumValues(
            quarters[quarterIndex].targetSales,
            x.targetSales
          );
          quarters[quarterIndex].targetCciPercentage =
            this.calculatePercentageValues(
              quarters[quarterIndex].targetCciPercentage as Record<
                string,
                number
              >,
              x.targetCciPercentage as Record<string, number>
            );
          quarters[quarterIndex].revenue = CalcUtils.sumValues(
            quarters[quarterIndex].revenue,
            x.revenue
          );
          quarters[quarterIndex].cci = CalcUtils.sumValues(
            quarters[quarterIndex].cci,
            x.cci
          );
          quarters[quarterIndex].sales = CalcUtils.sumValues(
            quarters[quarterIndex].sales,
            x.sales
          );

          if (target === ViewOptions.CompareProjection) {
            quarters[quarterIndex].cciPercentage = this.substractPercentage(
              quarters[quarterIndex].currentCciPercentage as Record<
                string,
                number
              >,
              quarters[quarterIndex].targetCciPercentage as Record<
                string,
                number
              >
            );
          } else {
            quarters[quarterIndex].cciPercentage =
              this.calculatePercentageValues(
                quarters[quarterIndex].cciPercentage as Record<string, number>,
                x.cciPercentage as Record<string, number>
              );
          }
        }
      } else {
        quarters.push(
          new SpeculativePhasingPeriod({
            timeId: quarterDate.FiscalQuarterNbr,
            revenue: x.revenue,
            sales: x.sales,
            cci: x.cci,
            cciPercentage: x.cciPercentage,
            currentRevenue: x.currentRevenue,
            currentSales: x.currentSales,
            currentCci: x.currentCci,
            currentCciPercentage: x.currentCciPercentage,
            targetRevenue: x.targetRevenue,
            targetSales: x.targetSales,
            targetCci: x.targetCci,
            targetCciPercentage: x.targetCciPercentage,
            fiscalYear: quarterDate.FiscalYearNbr,
          })
        );
      }
    });

    return sortBy(quarters, 'timeId');
  }

  private static calculatePercentageValues(
    total: Record<string, number>,
    value: Record<string, number>
  ): Record<string, number> {
    if (total && value) {
      return {
        cci: CalcUtils.sumValues(total.cci, value.cci),
        revenue: CalcUtils.sumValues(total.revenue, value.revenue),
        cciPercent: CalcUtils.calculateCustomPercentage(
          total.cci,
          total.revenue
        ),
      };
    }

    return {
      cci: null,
      revenue: null,
      cciPercent: null,
    };
  }

  private static substractPercentage(
    current: Record<string, number>,
    target: Record<string, number>
  ): Record<string, number> {
    return {
      cci: current.cci - target.cci,
      revenue: current.revenue - target.revenue,
      cciPercent: current.cciPercent - target.cciPercent,
    };
  }
}
