import { assign } from 'lodash';

import { TimeframeItem } from 'src/app/shared/components/timeframe/entities/timeframe';
import {
  CurrencyRates,
  CustomCurrencies,
} from 'src/app/shared/constants/filters.constants';
import { ClientGroup } from './client-group';
import { Currency } from './currency';
import { Customer } from './customer';
import { FinancialCustomer } from './financial-customer';
import { Industry } from './industry';
import { Location } from './location';
import { Plan } from './plan';
import { PlanningWindow } from './planning-window';
import { Alliance, Function, GrowthPriority } from './product-hierarchy';
import { Projection } from './projection';
import { ServiceGroupValues } from './service-group';
import { Wmu } from './wmu';
import { TextValuePair } from '../common/key-value';
import { ProfitLevers } from './profitUplift';

class SelectedFiltersSchema {
  customer?: Customer;
  portfolioId?: string;
  serviceGroups?: Array<ServiceGroupValues>;
  markets?: Array<Location>;
  industries?: Array<Industry>;
  alliances?: Array<Alliance>;
  growthPriorities?: Array<GrowthPriority>;
  functions?: Array<Function>;
  clientGroups?: Array<ClientGroup>;
  currency?: Currency;
  timeframe?: TimeframeItem;
  defaultTimeframe?: TimeframeItem;
  wmus?: Array<Wmu>;
  financialCustomers?: Array<FinancialCustomer>;
  // metric?: string;
  // forecast?: Forecast;
  projection?: Projection;
  planView?: string;
  // stage?: string;
  // appliedBoardName?: string;
  plan?: Plan;
  planningWindow?: PlanningWindow;
  // target?: string;
  isMSACvf: boolean;
  isPUdtls?: boolean = false;
  fullyWeighted?: boolean = false;
  target?: TextValuePair;
  profitLevers: Array<ProfitLevers>;
}

export class SelectedFilters extends SelectedFiltersSchema {
  constructor(filterAttributes?: SelectedFiltersSchema) {
    super();
    assign(this, filterAttributes);
  }

  clear(): void {
    this.portfolioId = null;
    this.serviceGroups = [];
    this.markets = [];
    this.industries = [];
    this.alliances = [];
    this.growthPriorities = [];
    this.functions = [];
    this.clientGroups = [];
    this.wmus = [];
    this.financialCustomers = [];
  }

  getCount(): number {
    return (
      (this.serviceGroups ? this.serviceGroups.length : 0) +
      (this.markets ? this.markets.length : 0) +
      (this.industries ? this.industries.length : 0) +
      (this.alliances ? this.alliances.length : 0) +
      (this.growthPriorities ? this.growthPriorities.length : 0) +
      (this.functions ? this.functions.length : 0) +
      (this.clientGroups ? this.clientGroups.length : 0) +
      (this.wmus ? this.wmus.length : 0) +
      (this.financialCustomers ? this.financialCustomers.length : 0)
    );
  }

  hasAttributesSelected(): boolean {
    return (
      this.growthPriorities?.length > 0 ||
      this.functions?.length > 0 ||
      this.alliances?.length > 0
    );
  }

  getTargetCurrency(): string {
    switch (this.currency.Id) {
      case CustomCurrencies.Constant.id:
        return 'USD';
      case CustomCurrencies.Global.id:
        return '';
      default:
        return this.currency.Id;
    }
  }

  getCurrencyType(): string {
    switch (this.currency.Id) {
      case CustomCurrencies.Constant.id:
        return CurrencyRates.PlanRate;
      case CustomCurrencies.Global.id:
      case CustomCurrencies.FxAdjusted.id:
        return '';
      default:
        return CurrencyRates.Erate;
    }
  }

  setPrototypes(): void {
    Object.setPrototypeOf(this.timeframe, TimeframeItem.prototype);
    Object.setPrototypeOf(this.defaultTimeframe, TimeframeItem.prototype);
    Object.setPrototypeOf(this.projection, Projection.prototype);
    Object.setPrototypeOf(this.plan, Plan.prototype);
    Object.setPrototypeOf(this.currency, Currency.prototype);
    Object.setPrototypeOf(this.customer, Customer.prototype);
  }
}
