import { find, flatMap, split } from 'lodash';
import {
  TimeframeGroup,
  TimeframeItem,
  TimeframeSubGroup,
} from '../components/timeframe/entities/timeframe';
import { TimeframeUtils } from '../components/timeframe/utils/timeframe.utils';
import { ActiveDate } from '../services/entities/filters/active-date';
import { ClientGroup } from '../services/entities/filters/client-group';
import { Currency } from '../services/entities/filters/currency';

import { Customer } from '../services/entities/filters/customer';
import { FinancialCustomer } from '../services/entities/filters/financial-customer';
import { Industry } from '../services/entities/filters/industry';
import { Location } from '../services/entities/filters/location';
import { ProductHierarchy } from '../services/entities/filters/product-hierarchy';
import { ServiceGroupValues } from '../services/entities/filters/service-group';
import { Timeframe } from '../services/entities/filters/timeframe';
import { Wmu } from '../services/entities/filters/wmu';

export class MmbFiltersUtils {
  static getMMBCurrency(
    currencies: Array<Currency>,
    parsedFilters: any
  ): Currency {
    let result: Currency;

    if (parsedFilters.CUR) {
      const currency: Currency = find(currencies, { Id: parsedFilters.CUR });
      result = currency ? currency : currencies[0];
    } else {
      result = currencies[0];
    }

    return result;
  }

  static getMMBCustomer(customers: Array<Customer>, param: any): Customer {
    let customer: Customer;

    if (param) {
      customer = find(customers, { MasterCustomerNumber: param });
    }

    return customer ?? customers[0];
  }

  static getMMBClientServiceGroups(
    cgs: Array<ClientGroup>,
    parsedFilters: any
  ): Array<ClientGroup> {
    const result: Array<ClientGroup> = [];

    if (parsedFilters.CSG) {
      parsedFilters.CSG.forEach((item: any) => {
        const csg: ClientGroup = find(cgs, { ClientGroupCd: item });
        if (csg) {
          result.push(csg);
        }
      });
    }

    return result;
  }

  static getMMBFinancialCustomer(
    financialCustomers: Array<FinancialCustomer>,
    parsedFilters: any
  ): Array<FinancialCustomer> {
    const result: Array<FinancialCustomer> = [];

    if (parsedFilters.FC) {
      split(parsedFilters.FC, ',').forEach((item: any) => {
        const fc: FinancialCustomer = find(financialCustomers, {
          CustomerNumber: item,
        });
        if (fc) {
          result.push(fc);
        }
      });
    }
    return result;
  }

  static getMMBWmus(wmus: Array<Wmu>, parsedFilters: any): Array<Wmu> {
    const result: Array<Wmu> = [];

    if (parsedFilters.WMU) {
      parsedFilters.WMU.forEach((item: any) => {
        const wmu: Wmu = find(wmus, { ID: parseInt(item, 10) });
        if (wmu) {
          result.push(wmu);
        }
      });
    }

    return result;
  }

  static getMMBServiceGroup(
    serviceGroups: Array<ServiceGroupValues>,
    parsedFilters: any
  ): Array<ServiceGroupValues> {
    const result: Array<ServiceGroupValues> = [];

    if (parsedFilters.SG) {
      parsedFilters.SG.forEach((item: any) => {
        const sg: ServiceGroupValues = find(serviceGroups, {
          ServiceGroupCd: item,
        });
        if (sg) {
          result.push(sg);
        }
      });
    }

    return result;
  }

  static getMMBLocation(
    locationsList: Array<Location>,
    parsedFilters: any
  ): Array<Location> {
    const result: Array<Location> = [];

    if (parsedFilters.GA) {
      parsedFilters.GA.forEach((item: any) => {
        const loc: Location = find(locationsList, {
          OrganizationId: parseInt(item, 10),
        });
        if (loc) {
          result.push(loc);
        }
      });
    }

    return result;
  }

  static getMMBIndustry(
    industries: Array<Industry>,
    parsedFilters: any
  ): Array<Industry> {
    const result: Array<Industry> = [];

    if (parsedFilters.IN) {
      parsedFilters.IN.forEach((item: any) => {
        const ind: Industry = find(industries, { EntityCode: item });
        if (ind) {
          result.push(ind);
        }
      });
    }

    return result;
  }

  static getMMBSgi(
    attributes: Array<ProductHierarchy>,
    parsedFilters: any
  ): Array<ProductHierarchy> {
    const result: Array<ProductHierarchy> = [];

    const attrs: Array<any> = [].concat(parsedFilters.SGI);

    if (attrs) {
      attrs.forEach((code: any) => {
        const attributeItem: ProductHierarchy = attributes.find(
          (item: ProductHierarchy) => item.ProductCode === code
        );
        if (attributeItem) {
          result.push(attributeItem);
        }
      });
    }

    return result;
  }

  static getMMBFunctions(
    attributes: Array<ProductHierarchy>,
    parsedFilters: any
  ): Array<ProductHierarchy> {
    const result: Array<ProductHierarchy> = [];

    const attrs: Array<any> = [].concat(parsedFilters.Functions);

    if (attrs) {
      attrs.forEach((code: any) => {
        const attributeItem: ProductHierarchy = attributes.find(
          (item: ProductHierarchy) => item.ProductCode === code
        );
        if (attributeItem) {
          result.push(attributeItem);
        }
      });
    }

    return result;
  }

  static getMMBAlliances(
    attributes: Array<ProductHierarchy>,
    parsedFilters: any
  ): Array<ProductHierarchy> {
    const result: Array<ProductHierarchy> = [];

    const attrs: Array<any> = [].concat(parsedFilters.Alliances);

    if (attrs) {
      attrs.forEach((code: any) => {
        const attributeItem: ProductHierarchy = attributes.find(
          (item: ProductHierarchy) => item.ProductCode === code
        );
        if (attributeItem) {
          result.push(attributeItem);
        }
      });
    }

    return result;
  }

  static getMMBTimeframe(
    timaframes: Array<Timeframe>,
    defaultTimeframe: TimeframeItem,
    appDate: ActiveDate,
    parsedFilters: any
  ): TimeframeItem {
    let timeframe: TimeframeItem;
    const groups: Array<TimeframeGroup> = TimeframeUtils.getTimeframes(
      timaframes,
      appDate
    );

    if (parsedFilters.TimeF) {
      timeframe = flatMap(
        groups.map((x: TimeframeGroup) =>
          flatMap(x.subGroups.map((y: TimeframeSubGroup) => y.items))
        )
      ).find((x: TimeframeItem) => x.code === parsedFilters.TimeF.tPCode);

      timeframe =
        timeframe ??
        flatMap(
          groups.map((x: TimeframeGroup) =>
            flatMap(x.subGroups.map((y: TimeframeSubGroup) => y.items))
          )
        ).find(
          (x: TimeframeItem) =>
            x.start === Number(parsedFilters.TimeF.startId) &&
            x.end === Number(parsedFilters.TimeF.endId)
        );
    }

    return timeframe ?? defaultTimeframe;
  }
}
