import { assign } from 'lodash';

import { MetricType } from 'src/app/shared/constants/metrics.constants';
import { Filters } from 'src/app/shared/services/entities/filters/filters';
import { SelectedFilters } from 'src/app/shared/services/entities/filters/selected-filters';

class ProfitUpliftModalConfigSchema {
  tileParentId: string;
  type: MetricType;
  response: any;
  filters: Filters;
  selectedFilters: SelectedFilters;
  tileTotal: string;
}

export class ProfitUpliftModalConfig extends ProfitUpliftModalConfigSchema {
  constructor(filterAttributes?: ProfitUpliftModalConfigSchema) {
    super();
    assign(this, filterAttributes);
  }
}
