import { assign } from 'lodash';

class OpportunityResponseItemSchema {
  Id: number;
  Name: string;
  OpportunityLink: string;
  Director: string;
  Stage: string;
  UpdatedDate: Date;
  StatusSinceDate: Date;
  StartDate: Date;
  EndDate: Date;
  TotalRevenueAmount: number;
  TotalCciAmount: number;
  TotalCciPercentage: Record<string, number>;
  ProjectedRevenueAmount: number;
  ProjectedCciAmount: number;
  ProjectedCciPercentage: number;
  TargetTotalRevenueAmount: number;
  TargetTotalCciAmount: number;
  TargetTotalCciPercentage: number;
  TargetRevenueAmount: number;
  TargetCciAmount: number;
  TargetCciPercentage: number;
  CurrentTotalRevenueAmount: number;
  CurrentTotalCciAmount: number;
  CurrentTotalCciPercentage: number;
  CurrentRevenueAmount: number;
  CurrentCciAmount: number;
  CurrentCciPercentage: number;
  WinProbability: number;
  ProjectionPct: number;
  IsCompleteFinancials: boolean;
  NetRevenue: number;
  Cci: number;
  CciPercent: Record<string, number>;
  isEdited?: boolean;
  isInvalid?: boolean;
  MsaIndicator?: boolean;
  isDataUpdatedOnGcp: boolean;
  excludeFromTotal?: boolean;
}

export class OpportunityResponseItem extends OpportunityResponseItemSchema {
  constructor(filterAttributes?: OpportunityResponseItemSchema) {
    super();
    assign(this, filterAttributes);
  }

  isInactive(): boolean {
    return (
      this.IsCompleteFinancials === false ||
      ((Math.round(this.TotalRevenueAmount) === 0 ||
        !this.TotalRevenueAmount) &&
        (Math.round(this.TotalCciAmount) === 0 || !this.TotalCciAmount) &&
        (Math.round(this.TotalCciPercentage.cciPercent) === 0 ||
          !this.TotalCciPercentage.cciPercent) &&
        (Math.round(this.ProjectedCciAmount) === 0 ||
          !this.ProjectedCciAmount) &&
        (Math.round(this.ProjectedCciPercentage) === 0 ||
          !this.ProjectedCciPercentage) &&
        (Math.round(this.ProjectedRevenueAmount) === 0 ||
          !this.ProjectedRevenueAmount) &&
        (Math.round(this.WinProbability) === 0 || !this.WinProbability) &&
        (Math.round(this.ProjectionPct) === 0 || !this.ProjectionPct))
    );
  }

  isUnqualfied() {
    return this.Stage === '0A';
  }

  isQualified() {
    return this.Stage !== '0A';
  }

  hasDifferences(): boolean {
    return this.ProjectionPct !== this.WinProbability;
  }

  reset(): void {
    this.ProjectionPct = this.WinProbability;
    this.isEdited = true;
  }
}
