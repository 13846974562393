<table *ngIf="profitUpliftTable" class="profitUplift-table">
    <thead>
        <tr class="header">
            <th class="first">
            </th>
            <th *ngFor="let p of profitUpliftTable.columns" class="painted">
                {{p}}
            </th>
        </tr>
    </thead>
    <tbody>
        <ng-container *ngFor="let s of profitUpliftTable.profitLevers">
            <tr class="item-detail">
                <td class="title">
                    {{s.title}}
                </td>
                <td class="value" [ngClass]="{'negative' : s.value < 0}">
                    {{s.value| customNumber : s.valueType : '-'}}
                </td>
            </tr>
        </ng-container>
    </tbody>
</table>