import { NgIf } from '@angular/common';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import {
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SharedModule } from 'src/app/shared-module';
import { DropDownComponent } from '../../base/drop-down/drop-down.component';
import { SpinnerComponent } from '../../base/spinner/spinner.component';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { TextValuePair } from 'src/app/shared/services/entities/common/key-value';
import { cloneDeep } from 'lodash';
import { TrendUtils } from '../utils/trend.utils';
import { v4 as uuidv4 } from 'uuid';
import { ProfitUpliftModalService } from 'src/app/shared/services/tiles/profituplift-modal.service';
import { ProfitUpliftModalConfig } from './entities/profituplift-modal-config';
import { ProfitUpliftModalTypeChanged } from './entities/profituplift-modal-events';
import { ProfitupliftTableComponent } from '../profituplift-tile/components/profituplift-table/profituplift-table.component';
import { ProfitUpliftTableConfig } from '../profituplift-tile/entities/profituplift-table.config';
import { PLTileDropdownConstants } from '../profituplift-tile/constants/profituplift-tile.constants';

@Component({
  selector: 'app-profituplift-modal',
  standalone: true,
  templateUrl: './profituplift-modal.component.html',
  styleUrls: ['./profituplift-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
  imports: [
    DropDownComponent,
    NgIf,
    SharedModule,
    FontAwesomeModule,
    SpinnerComponent,
    FontAwesomeModule,
    ProfitupliftTableComponent,
  ],
})
export class ProfitupliftModalComponent implements OnInit, OnDestroy {
  subscription = new Subscription();
  @ViewChild('profitupliftModal', { static: false })
  profitupliftModal: NgbModalRef;

  loaded = true;
  config: ProfitUpliftModalConfig;
  profitUpliftTableConfig: ProfitUpliftTableConfig;

  currentModal: NgbModalRef;
  isOpen = false;

  types: Array<TextValuePair> = [];
  selectedType: TextValuePair;

  faExclamationCircle = faExclamationCircle as IconProp;
  faTimes = faTimes as IconProp;

  constructor(
    private modalService: NgbModal,
    private ProfitUpliftModalService: ProfitUpliftModalService
  ) {}

  ngOnInit(): void {
    this.subscription.add(
      this.ProfitUpliftModalService.openTrendModalEmitted.subscribe(
        (x: ProfitUpliftModalConfig) => {
          this.config = cloneDeep(x);
          this.getTypeList();
          this.renderTable();
        }
      )
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  getTypeList(): void {
    this.types = [];

    const keys: Array<string> = Object.keys(PLTileDropdownConstants);

    keys.forEach((x: string) => {
      const textValuePair = new TextValuePair({
        text: (PLTileDropdownConstants as any)[x].title,
        value: (PLTileDropdownConstants as any)[x].type,
      });

      if (textValuePair.value === this.config.type) {
        this.selectedType = textValuePair;
      }

      this.types.push(textValuePair);
    });
  }

  renderTable(): void {
    this.profitUpliftTableConfig = new ProfitUpliftTableConfig({
      id: uuidv4(),
      definition: TrendUtils.getDefinition(this.config.type),
      response: this.config.response,
      type: this.config.type,
    });

    this.openModal();
  }

  openModal(): void {
    if (!this.isOpen) {
      this.currentModal = this.modalService.open(this.profitupliftModal, {
        windowClass: 'profituplift-modal',
        centered: true,
        size: 'lg',
      });

      this.isOpen = true;

      this.subscription.add(
        this.currentModal.dismissed.subscribe(() => {
          this.isOpen = false;
        })
      );
    }
  }

  loadStarted(): void {
    this.loaded = false;
  }

  loadCompleted(): void {
    this.loaded = true;
  }

  onCloseModal(): void {
    this.currentModal.dismiss();
  }

  onTypeChanged(type: TextValuePair): void {
    this.loadStarted();
    this.selectedType = type;
    this.ProfitUpliftModalService.typeChanged.emit(
      new ProfitUpliftModalTypeChanged({
        tileParentId: this.config.tileParentId,
        type: this.selectedType.value,
        callback: () => {
          this.loadCompleted();
        },
      })
    );
  }
}
