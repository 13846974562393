import { Inject, Injectable, OnDestroy } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of, Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { AppState } from '../services/entities/app-state/app-state';
import { StorageUtils } from 'src/app/core/utils/storage.utils';
import {
  StorageKeys,
  StorageType,
} from 'src/app/core/constants/storage.constants';

import { AppStateService } from '../services/app-state.service';
import { RebarAuthService } from 'src/app/core/rebarauth/rebar.auth.service';
import { SharedService } from '../services/shared.service';
import { AppModulePaths } from '../constants/navbar.constants';

@Injectable({
  providedIn: 'root',
})
export class MaintenanceModeGuard implements OnDestroy {
  subscription = new Subscription();

  constructor(
    @Inject('Window') private window: Window,
    private appStateService: AppStateService,
    private authService: RebarAuthService,
    private sharedService: SharedService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    if (route.queryParams.overrideCode) {
      StorageUtils.set(
        StorageType.SessionStorage,
        StorageKeys.OVERRIDE_CODE,
        route.queryParams.overrideCode
      );
    }

    const overrideCode: string = StorageUtils.get(
      StorageType.SessionStorage,
      StorageKeys.OVERRIDE_CODE
    );

    if (this.authService.isUserAuthenticated()) {
      return this.appStateService.getAppState(overrideCode).pipe(
        switchMap((x: AppState) => {
          StorageUtils.set(
            StorageType.SessionStorage,
            StorageKeys.OVERRIDE_VALID,
            x.overrideValid
          );
          if (x.maintenanceModeEnabled && x.overrideValid) {
            return of(true);
          } else if (x.maintenanceModeEnabled) {
            this.window.location.href = './assets/gonefishing.html';
            return of(false);
          } else if (route.data?.tabName === AppModulePaths.profituplift.id) {
            //TODO: Remove this 'else if' section when ProfitTab is fully launched.
            const hasAccess = this.accessToProfitUpliftTab(x);
            if (!hasAccess) {
              this.window.location.href = '';
            }
            return of(hasAccess);
          }
          return of(true);
        })
      );
    } else {
      return of(false);
    }
  }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.canActivate(next, state);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  //TODO: Remove this function when ProfitTab is fully launched.
  private accessToProfitUpliftTab(appState: AppState): boolean {
    const profitAccessUserList = appState.betaAccessUserList;
    return profitAccessUserList.includes(this.sharedService.getEnterpriseId());
  }
}
