import { Component } from '@angular/core';
import { ProfitUpTileMode } from 'src/app/shared/components/tiles/profituplift-tile/constants/profituplift-tile.constants';
import { ProfitupliftTileComponent } from 'src/app/shared/components/tiles/profituplift-tile/profituplift-tile.component';

@Component({
  selector: 'app-profituplift',
  templateUrl: './profituplift.component.html',
  styleUrl: './profituplift.component.scss',
  standalone: true,
  imports: [ProfitupliftTileComponent],
})
export class ProfitupliftComponent {
  summaryMode: string = ProfitUpTileMode.summary;
  expDetlMode: string = ProfitUpTileMode.expandedDetailed;
}
