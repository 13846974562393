import '@angular/compiler';
import {
  enableProdMode,
  APP_INITIALIZER,
  ErrorHandler,
  importProvidersFrom,
} from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import packageJson from '../package.json';

import { environment } from './environments/environment';
import { AppConfigService } from './app/core/services/app-config.service';

import { LicenseManager } from 'ag-grid-enterprise';
import { AppComponent } from './app/app.component';
import { AgGridModule } from 'ag-grid-angular';
import {
  BaseChartDirective,
  provideCharts,
  withDefaultRegisterables,
} from 'ng2-charts';
import { ClipboardModule } from 'ngx-clipboard';
import { NgxSpinnerModule } from 'ngx-spinner';
import { VirtualScrollerModule } from '@iharbeck/ngx-virtual-scroller';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MsalModule } from '@azure/msal-angular';
import { RebarAuthModule } from './app/core/rebarauth/rebar.auth.module';
import { provideAnimations } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  withInterceptorsFromDi,
  provideHttpClient,
} from '@angular/common/http';
import { AppRoutingModule } from './app/app-routing.module';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { BetaMessageService } from './app/shared/services/beta-message.service';
import { CleanUpNotificatorService } from './app/shared/services/clean-up-notificator.service';
import { MrdrService } from './app/shared/services/mrdr.service';
import { OpportunityPhasingModalService } from './app/shared/services/modals/opportunity-phasing-modal.service';
import { SpeculativeGridService } from './app/shared/services/grids/speculative-grid.service';
import { PhasingGridService } from './app/shared/services/grids/phasing-grid.service';
import { ActualSalesGridService } from './app/shared/services/grids/actualsales-grid.service';
import { WinsGridService } from './app/shared/services/grids/wins-grid.service';
import { OpportunitiesGridService } from './app/shared/services/grids/opportunities-grid.service';
import { BacklogGridService } from './app/shared/services/grids/backlog-grid.service';
import { TrendModalService } from './app/shared/services/tiles/trend-modal.service';
import { TrendTileService } from './app/shared/services/tiles/trend-tile.service';
import { TargetTileService } from './app/shared/services/tiles/target-tile.service';
import { GilcTileService } from './app/shared/services/tiles/gilc-tile.service';
import { ProjectionTileService } from './app/shared/services/tiles/projection-tile.service';
import { FileExporterService } from './app/shared/services/file-exporter.service';
import { AppStateService } from './app/shared/services/app-state.service';
import { SecurityService } from './app/shared/services/security.service';
import { SharedService } from './app/shared/services/shared.service';
import { AppMessagesService } from './app/shared/services/app-messages.service';
import { AppToastsService } from './app/shared/services/app-toasts.service';
import { FilterDefaultsService } from './app/shared/services/filter-defaults.service';
import { FiltersService } from './app/shared/services/filters.service';
import { TabGuard } from './app/shared/guards/tab.guard';
import { CustomAuthGuard } from './app/core/guards/custom-auth.guard';
import { MaintenanceModeGuard } from './app/shared/guards/maintenance-mode.guard';
import { ErrorHandlerService } from './app/core/services/error-handler.service';
import 'hammerjs';
import 'chartjs-plugin-zoom';
import 'ag-grid-enterprise';
import { ModuleRegistry } from '@ag-grid-community/core';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
ModuleRegistry.registerModules([SetFilterModule, RowGroupingModule]);

// eslint-disable-next-line max-len
LicenseManager.setLicenseKey(
  'Using_this_{AG_Grid}_Enterprise_key_{AG-065919}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Proquire_LLC}_is_granted_a_{Multiple_Applications}_Developer_License_for_{6}_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_need_to_be_licensed_in_addition_to_the_ones_working_with_{AG_Grid}_Enterprise___This_key_has_been_granted_a_Deployment_License_Add-on_for_{2}_Production_Environments___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{3_September_2025}____[v3]_[01]_MTc1Njg1NDAwMDAwMA==fce8c3303c168f6b9609e0c49794af5c'
);

if (environment.production) {
  enableProdMode();
}

function bootstrapApp(): void {
  bootstrapApplication(AppComponent, {
    providers: [
      importProvidersFrom(
        BrowserModule,
        AppRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        RebarAuthModule.forRoot(),
        MsalModule,
        NgbModule,
        FontAwesomeModule,
        VirtualScrollerModule,
        NgxSpinnerModule,
        ClipboardModule,
        BaseChartDirective,
        AgGridModule
      ),
      {
        provide: APP_INITIALIZER,
        useFactory: (config: AppConfigService) => () => config.load(),
        deps: [AppConfigService],
        multi: true,
      },
      { provide: 'Window', useValue: window },
      { provide: ErrorHandler, useClass: ErrorHandlerService },
      MaintenanceModeGuard,
      CustomAuthGuard,
      TabGuard,
      FiltersService,
      FilterDefaultsService,
      AppToastsService,
      AppMessagesService,
      SharedService,
      SecurityService,
      AppStateService,
      FileExporterService,
      ProjectionTileService,
      GilcTileService,
      TargetTileService,
      TrendTileService,
      TrendModalService,
      BacklogGridService,
      OpportunitiesGridService,
      WinsGridService,
      ActualSalesGridService,
      PhasingGridService,
      SpeculativeGridService,
      OpportunityPhasingModalService,
      MrdrService,
      CleanUpNotificatorService,
      BetaMessageService,
      provideHttpClient(withInterceptorsFromDi()),
      provideAnimations(),
      provideCharts(withDefaultRegisterables()),
    ],
  }).catch((err) => console.log(err));
}

function validateAppVersion(): void {
  window.document.title = `CBP [${packageJson.version}]`;

  try {
    if (!(window !== window.parent && !window.opener)) {
      const cacheKey = '__version__cache';
      const emptyVersion = '0.0.0';
      const version: Array<number> = (new RegExp(/(\d+\.)(\d+\.)(\d)/).exec(
        window.document.title
      ) || [emptyVersion])[0]
        .split('.')
        .map((x: string) => {
          return Number(x) || 0;
        });

      if (version[0] !== 0) {
        const storedVersion: Array<number> = (
          window.localStorage.getItem(cacheKey) || emptyVersion
        )
          .split('.')
          .map((x: string) => {
            return Number(x) || 0;
          });

        if (
          storedVersion[0] !== version[0] ||
          (storedVersion[0] === version[0] && storedVersion[1] !== version[1])
        ) {
          window.localStorage.clear();
          window.sessionStorage.clear();
          document.cookie.split(';').forEach((x: string) => {
            document.cookie =
              (x.indexOf('=') > -1 ? x.substring(0, x.indexOf('=')) : x) +
              '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
          });
          window.localStorage.setItem(cacheKey, version.join('.'));
          window.location.href = '/';
        }
      }
    }
  } catch (error) {
    console.log(error);
  }
}

document.addEventListener('DOMContentLoaded', () => {
  // We bootstraping app from browser, we need fetch config.json.
  fetch(AppConfigService.configPath)
    .then((response) => {
      return response.json();
    })
    .then((jsonData) => {
      sessionStorage[AppConfigService.configPath] = JSON.stringify(jsonData);
      validateAppVersion();
      bootstrapApp();
    })
    .catch((err) => {
      console.warn('caught when bootstrapping app');
      console.error(err);
    });
});
