<ul ngbNav #nav="ngbNav" [(activeId)]="selectedFilter" class="nav-tabs">
    <li [ngbNavItem]="1" (click)="onTabChanged()" class="nav-tab">
        <a ngbNavLink>Work Management Unit</a>
        <ng-template ngbNavContent>
            <p class="message">WMU selections will overwrite prior Financial Customer selections</p>
            <app-search-input placeholder="Search for WMU" (searchEvent)="onSearchTextChanged($event)">
            </app-search-input>
            <app-selection-control (selectionChangeEvent)="onWmuSelectionChanged($event)">
            </app-selection-control>
            <app-tree-view [config]="wmuTreeviewConfig" [searchText]="searchText"
                (itemSelectionChangeEvent)="onWmuItemSelection($event)"
                [onSelectAllChangedObs]="wmuSelectAllChangedEvent.asObservable()" [startSelection]="startSelectionWmus">
            </app-tree-view>
        </ng-template>
    </li>
    <li [ngbNavItem]="2" (click)="onTabChanged()" class="nav-tab">
        <a ngbNavLink>Financial Customer</a>
        <ng-template ngbNavContent>
            <p class="message">Financial Customer selections will overwrite prior WMU selections</p>
            <app-search-input placeholder="Search for Financial Customer" (searchEvent)="onSearchTextChanged($event)">
            </app-search-input>
            <app-selection-control (selectionChangeEvent)="onFcSelectionChanged($event)"></app-selection-control>
            <app-options-list [config]="fcOptionsListConfig" [searchText]="searchText"
                [startSelection]="startSelectionFinancialCustomers"
                [onSelectAllChangedObs]="fcSelectAllChangedEvent.asObservable()"
                (itemSelectionChangeEvent)="onFcItemSelection($event)">
            </app-options-list>
        </ng-template>
    </li>
</ul>
<div [ngbNavOutlet]="nav" class="wmu-fc-container mt-2"></div>